// pages/BlogListPage.tsx
import React from 'react';
import { blogPosts } from './Blog/blog-posts';
import { Helmet } from 'react-helmet';
import { BlogCard } from './Blog/BlogCard';

export const BlogListPage: React.FC = () => {
  const featuredPosts = blogPosts.filter(post => post.metadata.featured);
  const regularPosts = blogPosts.filter(post => !post.metadata.featured);

  return (
    <>
      <Helmet>
        <title>EV Charging Tips & Insights | EVAlarm Blog</title>
        <meta
          name="description"
          content="Get the latest tips, updates, and insights for Tesla owners and EV enthusiasts."
        />
      </Helmet>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">
            EVAlarm Blog
          </h1>
          <p className="text-xl text-gray-600">
            Tips, updates, and insights for Tesla owners
          </p>
        </div>

        {featuredPosts.length > 0 && (
          <div className="mb-16">
            <h2 className="text-2xl font-semibold mb-6">Featured Posts</h2>
            <div className="grid grid-cols-1 gap-8">
              {featuredPosts.map(({ metadata }) => (
                <BlogCard key={metadata.id} post={metadata} featured />
              ))}
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {regularPosts.map(({ metadata }) => (
            <BlogCard key={metadata.id} post={metadata} />
          ))}
        </div>
      </div>
    </>
  );
};

import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import AuthLayout from '../layout/AuthLayout';
import SignInForm from '../common/SignInForm';
import Button from '../common/Button';

const SignInPage: React.FC = () => {
    return (
        <AuthLayout
            title="Welcome Back to EVAlarm"
            subtitle="Charge Smarter, Save Bigger"
        >
            <p className="text-center text-gray-600 mb-8">
                Your intelligent Tesla companion for efficient charging and cost savings
            </p>
            <SignInForm />
            <div className="mt-6">
                <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                        <div className="w-full border-t border-gray-300"></div>
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">Or</span>
                    </div>
                </div>
                <div className="mt-6">
                    <Link to="/">
                        <Button variant="outline" fullWidth>
                            <ArrowLeft className="w-5 h-5 mr-2" />
                            Back to home
                        </Button>
                    </Link>
                </div>
            </div>
            <p className="mt-8 text-center text-sm text-gray-500">
                Don't have an account?{' '}
                <Link to="/signup" className="font-semibold text-teal-600 hover:text-teal-500">
                    Sign up
                </Link>
            </p>
        </AuthLayout>
    );
};

export default SignInPage;

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Bell, Battery, AlertCircle, Clock } from 'lucide-react';
import DashboardCard from '../../common/DashboardCard';
import { RootState } from "../../../store";
import { setAlertTimeStart, NotificationState, Vehicle } from '../../../store/vehicleDataSlice';

const ActiveAlerts: React.FC = () => {
    const dispatch = useDispatch();
    const vehicles = useSelector((state: RootState) => state.vehicleData.vehicles);

    const [timeToAlerts, setTimeToAlerts] = useState<{ [key: string]: number | null }>({});
    const [timeToFull, setTimeToFull] = useState<{ [key: string]: number | null }>({});

    useEffect(() => {
        const updateTimes = () => {
            const now = Math.floor(Date.now() / 1000); // Current time in seconds
            const newTimeToAlerts: { [key: string]: number | null } = {};
            const newTimeToFull: { [key: string]: number | null } = {};

            vehicles.forEach(vehicle => {
                if (vehicle.chargingStatus === 'charging' && vehicle.activeChargingSession) {
                    const estimatedCompletion = vehicle.activeChargingSession.estimated_complete_on;
                    console.log(vehicle.activeChargingSession)
                    console.log(`estimated completion is ${estimatedCompletion}`)
                    const timeToCompletion = Math.max(0, estimatedCompletion - now);
                    newTimeToFull[vehicle.id] = Math.ceil(timeToCompletion / 60);
                    newTimeToAlerts[vehicle.id] = Math.max(0, Math.floor(timeToCompletion / 60) - vehicle.alertTime);
                } else {
                    newTimeToFull[vehicle.id] = null;
                    newTimeToAlerts[vehicle.id] = null;
                }
            });

            setTimeToAlerts(newTimeToAlerts);
            setTimeToFull(newTimeToFull);
        };

        updateTimes(); // Initial update
        const timer = setInterval(updateTimes, 60000); // Update every minute

        return () => clearInterval(timer);
    }, [vehicles]);

    const handleUpdateAlertTime = (vehicleId: string, newTime: number) => {
        dispatch(setAlertTimeStart({ vehicleId, alertTime: newTime }));
    };

    const renderChargingStatus = (vehicle: Vehicle) => (
        <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
                <span className="text-lg font-semibold">{vehicle.name} - Charging Progress</span>
                <Battery className="text-teal-600" size={24} />
            </div>
            <div className="w-full bg-gray-200 rounded-full h-4 dark:bg-gray-700">
                <div
                    className="bg-teal-600 h-4 rounded-full transition-all duration-500 ease-out"
                    style={{ width: `${vehicle.batteryPercentage}%` }}
                ></div>
            </div>
            <div className="flex justify-between mt-1 text-sm text-gray-600">
                <span>{vehicle.batteryPercentage}%</span>
                <span>
                    {timeToFull[vehicle.id] !== null && timeToFull[vehicle.id] !== undefined
                        ? `${timeToFull[vehicle.id]} min to full`
                        : 'Time to full unavailable'}
                </span>
            </div>
        </div>
    );

    const renderAlertInfo = (vehicle: Vehicle) => (
        <div className="bg-blue-100 text-blue-700 p-4 rounded-lg mb-4">
            <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                    <Bell className="mr-2" size={18} />
                    <span className="font-semibold">Active Alert for {vehicle.name}</span>
                </div>
                <span className="text-sm">
                    {timeToAlerts[vehicle.id] !== null && timeToAlerts[vehicle.id] !== undefined
                        ? `Alert in ${timeToAlerts[vehicle.id]} min`
                        : 'Alert time unavailable'}
                </span>
            </div>
            <p className="text-sm mb-2">
                You'll be notified {vehicle.alertTime} minutes before charging completes.
            </p>
            <div className="flex justify-between items-center">
                <span className="text-sm">Adjust alert time:</span>
                <div className="space-x-2">
                    {[5, 10, 15, 20].map((time) => (
                        <button
                            key={time}
                            onClick={() => handleUpdateAlertTime(vehicle.id, time)}
                            className={`px-2 py-1 rounded ${
                                vehicle.alertTime === time ? 'bg-blue-500 text-white' : 'bg-blue-200 text-blue-700'
                            } text-sm transition duration-300`}
                        >
                            {time}m
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );

    const renderNotificationStatus = (vehicle: Vehicle) => {
        if (!vehicle.activeChargingSession) return null;

        const getStatusText = () => {
            switch (vehicle.activeChargingSession?.notification_state) {
                case NotificationState.Unnotified:
                    return "Alert pending";
                case NotificationState.new:
                    return "New alert";
                case NotificationState.snooze:
                    return "Alert snoozed";
                case NotificationState.acknowledged:
                    return "Alert acknowledged";
                case NotificationState.dismissed:
                    return "Alert dismissed";
                case NotificationState.expired:
                    return "Alert expired";
                default:
                    return "Unknown status";
            }
        };

        return (
            <div className="mt-4 p-3 bg-gray-100 rounded-lg">
                <div className="flex items-center justify-between">
                    <div className="flex items-center">
                        <Clock className="mr-2" size={18} />
                        <span className="font-semibold">Notification Status for {vehicle.name}</span>
                    </div>
                    <span className="text-sm">{getStatusText()}</span>
                </div>
            </div>
        );
    };

    const activeVehicles = vehicles.filter(v => v.chargingStatus === 'charging' && v.activeChargingSession);

    return (
        <DashboardCard title="Active Charging Sessions & Alerts">
            {activeVehicles.length > 0 ? (
                activeVehicles.map(vehicle => (
                    <div key={vehicle.id} className="mb-8 last:mb-0">
                        {renderChargingStatus(vehicle)}
                        {renderAlertInfo(vehicle)}
                        {renderNotificationStatus(vehicle)}
                    </div>
                ))
            ) : (
                <div className="text-center py-4">
                    <AlertCircle className="mx-auto mb-2 text-yellow-500" size={32} />
                    <p className="text-lg font-semibold mb-2">No Active Charging Sessions</p>
                    <p className="text-gray-600">
                        EVAlarm will automatically activate when you start charging any of your vehicles.
                    </p>
                </div>
            )}
        </DashboardCard>
    );
};

export default ActiveAlerts;
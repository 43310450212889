import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { CheckCircle, Copy } from 'lucide-react';
import {RootState} from "../../../store";

const TicketConfirmation: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    const copyTicketId = () => {
        navigator.clipboard.writeText(id || '');
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    useEffect(() => {
        if (!id) {
            navigate('/help/ticket/new');
        }
    }, [id, navigate]);

    if (!id) return null;

    return (
        <div className="text-center">
            <CheckCircle className="mx-auto h-12 w-12 text-green-500" />
            <h2 className="mt-4 text-2xl font-bold text-gray-900">Ticket Submitted Successfully</h2>
            <div className="mt-2 text-gray-600">
                <p>Your ticket has been received. We'll respond as soon as possible.</p>
                <div className="mt-4">
                    <span className="font-semibold">Ticket ID: </span>
                    <span className="font-mono bg-gray-100 px-2 py-1 rounded">{id}</span>
                    <button
                        onClick={copyTicketId}
                        className="ml-2 text-teal-600 hover:text-teal-800 focus:outline-none"
                        title="Copy Ticket ID"
                    >
                        <Copy className="inline-block h-5 w-5" />
                    </button>
                    {copied && <span className="ml-2 text-green-600">Copied!</span>}
                </div>
                <p className="mt-4">
                    Please keep this Ticket ID for your reference.
                    {!isAuthenticated && " As you're submitting as a guest, you'll need this ID to follow up on your ticket."}
                </p>
            </div>
            <div className="mt-6 space-y-4">
                <Link
                    to="/help/ticket"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                    View All Tickets
                </Link>
                <Link
                    to="/help/ticket/new"
                    className="inline-flex items-center px-4 py-2 border border-teal-600 text-sm font-medium rounded-md shadow-sm text-teal-600 bg-white hover:bg-teal-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                    Submit Another Ticket
                </Link>
            </div>
            {!isAuthenticated && (
                <div className="mt-4">
                    <Link
                        to="/signin"
                        className="text-teal-600 hover:text-teal-800"
                    >
                        Sign In to Track Your Tickets
                    </Link>
                </div>
            )}
        </div>
    );
};

export default TicketConfirmation;
import React from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

const FormButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: 'primary' | 'secondary' }> = ({ children, variant = 'primary', ...props }) => (
    <button
        {...props}
        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50 ${
            variant === 'primary'
                ? 'text-white bg-teal-600 hover:bg-teal-700'
                : 'text-teal-700 bg-teal-100 hover:bg-teal-200'
        }`}
    >
        {children}
    </button>
);

interface PaymentStepProps {
    clientSecret: string;
    paymentIntentId: string;
    currency: string;
    amountTotal: number;
    onPaymentSuccess: () => void;
    onPaymentError: (error: string) => void;
    onBack: () => void; // New prop for handling back navigation
}

export const PaymentStep: React.FC<PaymentStepProps> = ({
                                                            clientSecret,
                                                            paymentIntentId,
                                                            currency,
                                                            amountTotal,
                                                            onPaymentSuccess,
                                                            onPaymentError,
                                                            onBack
                                                        }) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            return;
        }

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)!,
            }
        });

        if (result.error) {
            onPaymentError(result.error.message!);
        } else {
            onPaymentSuccess();
        }
    };

    const formatAmount = (amount: number, currency: string) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency.toUpperCase(),
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount / 100);
    };

    return (
        <div className="space-y-6">
            <div className="mb-4">
                <h2 className="text-xl font-semibold mb-2">Payment Details</h2>
                <p className="text-gray-600">
                    Total amount: {formatAmount(amountTotal, currency)}
                </p>
                <p className="text-gray-600">Payment ID: {paymentIntentId}</p>
            </div>
            <form onSubmit={handleSubmit}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
                <div className="mt-6 flex space-x-4">
                    <FormButton type="button" onClick={onBack} variant="secondary">
                        Back
                    </FormButton>
                    <FormButton type="submit" disabled={!stripe}>
                        Pay
                    </FormButton>
                </div>
            </form>
        </div>
    );
};

import React from 'react';

const InlineLoadingIndicator: React.FC = () => {
    return (
        <div className="flex items-center justify-center space-x-2 my-2">
            <div className="w-2 h-2 bg-teal-600 rounded-full animate-bounce"></div>
            <div className="w-2 h-2 bg-teal-600 rounded-full animate-bounce" style={{ animationDelay: '0.2s' }}></div>
            <div className="w-2 h-2 bg-teal-600 rounded-full animate-bounce" style={{ animationDelay: '0.4s' }}></div>
        </div>
    );
};

export default InlineLoadingIndicator;
import { combineEpics, ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import {
    toggleNotificationsStart,
    toggleNotificationsSuccess,
    toggleNotificationsFailure,
    unlinkDeviceStart,
    unlinkDeviceSuccess,
    unlinkDeviceFailure,
    sendTestMessageStart,
    sendTestMessageSuccess,
    sendTestMessageFailure,
    fetchDevicesStart,
    fetchDevicesSuccess,
    fetchDevicesFailure
} from '../devicesSlice';
import {AppEpic, getAuthHeaders} from "./index";

interface Device {
    id: number;
    user_id: number;
    device_name: string;
    platform: string;
    client_id: string;
    push_notification_token: string;
    device_identifier: string;
    notification_enabled: boolean;
    created_at: string;
    updated_at: string;
}

export const fetchDevicesEpic: AppEpic = (action$) =>
    action$.pipe(
        ofType(fetchDevicesStart.type),
        mergeMap(() =>
            from(fetch('/api/devices', {
                method: 'GET',
                headers: getAuthHeaders(),
            })).pipe(
                mergeMap(response =>
                    response.ok
                        ? from(response.json())
                        : Promise.reject(new Error(`HTTP error! status: ${response.status}`))
                ),
                map((devices: Device[]) => fetchDevicesSuccess(devices)),
                catchError((error: Error) => of(fetchDevicesFailure(error.message)))
            )
        )
    );

export const toggleNotificationsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        ofType(toggleNotificationsStart.type),
        mergeMap((action) => {
            const { payload } = action as ReturnType<typeof toggleNotificationsStart>;
            const device = state$.value.devices.list.find(d => d.device_identifier === payload.device_identifier);
            if (!device) {
                return of(toggleNotificationsFailure('Device not found'));
            }
            return from(fetch(`/api/devices/${device.device_identifier}?notification_enabled=${payload.notification_enabled}`, {
                method: 'PUT',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json',
                },
            })).pipe(
                mergeMap(response =>
                    response.ok
                        ? from(response.json())
                        : Promise.reject(new Error(`HTTP error! status: ${response.status}`))
                ),
                map((updatedDevice: Device) => toggleNotificationsSuccess(updatedDevice)),
                catchError((error: Error) => of(toggleNotificationsFailure(error.message)))
            );
        })
    );

export const unlinkDeviceEpic: AppEpic = (action$) =>
    action$.pipe(
        ofType(unlinkDeviceStart.type),
        mergeMap((action) => {
            const { payload } = action as ReturnType<typeof unlinkDeviceStart>;
            return from(fetch(`/api/devices/${payload}`, {
                method: 'DELETE',
                headers: getAuthHeaders(),
            })).pipe(
                mergeMap(response =>
                    response.ok
                        ? from(response.json())
                        : Promise.reject(new Error(`HTTP error! status: ${response.status}`))
                ),
                map((device: Device) => unlinkDeviceSuccess(device)),
                catchError((error: Error) => of(unlinkDeviceFailure(error.message)))
            );
        })
    );

export const sendTestMessageEpic: AppEpic = (action$) =>
    action$.pipe(
        ofType(sendTestMessageStart.type),
        mergeMap((action) => {
            const { payload } = action as ReturnType<typeof sendTestMessageStart>;
            return from(fetch(`/api/devices/${payload}/send_test_notification`, {
                method: 'POST',
                headers: getAuthHeaders(),
            })).pipe(
                map(() => sendTestMessageSuccess()),
                catchError((error: Error) => of(sendTestMessageFailure(error.message)))
            );
        })
    );

// Combine all device-related epics
const deviceEpics = combineEpics(
    fetchDevicesEpic,
    toggleNotificationsEpic,
    unlinkDeviceEpic,
    sendTestMessageEpic
);

export default deviceEpics;
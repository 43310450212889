import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Header from '../common/Header';
import VehicleSelector from './Dashboard/VehicleSelector';
import BatteryStatus from '../common/BatteryStatus';
import QuickAlertSetting from './Dashboard/QuickAlertSetting';
import ActiveAlerts from './Dashboard/ActiveAlerts';
import VehicleSummary from './Dashboard/VehicleSummary';
import Footer from '../common/Footer';
import { RootState } from '../../store';
import { selectVehicle, Vehicle } from '../../store/vehicleDataSlice';
import { SubscriptionStatus } from '../../store/subscriptionSlice';
import { User } from "../../store/userSlice";
import { TeslaErrorType } from '../../store/teslaAccountsSlice';

const EVAlarmDashboard: React.FC = () => {
    const dispatch = useDispatch();
    const { vehicles, selectedVehicleId } = useSelector((state: RootState) => state.vehicleData);
    const { subscriptionStatus } = useSelector((state: RootState) => (state.user.currentUser as NonNullable<User>));
    const teslaAccounts = useSelector((state: RootState) => state.teslaAccounts.accounts);
    console.log("tesla accounts is")
    console.log(teslaAccounts)

    // ensure selected vehicle is always the first one if there is only one vehicle
    let selectedVehicle: Vehicle | null;
    if (vehicles.length === 1) {
        selectedVehicle = vehicles[0];
    } else {
        selectedVehicle = vehicles.find(v => Number(v.id) === selectedVehicleId) || null;
    }

    const handleSelectVehicle = (id: string) => {
        dispatch(selectVehicle(id));
    };

    const isSubscriptionActive = subscriptionStatus === SubscriptionStatus.Active || subscriptionStatus === SubscriptionStatus.Trialing;

    // Check for Tesla account errors
    const hasAuthError = teslaAccounts.some(account =>
        account.errors.some(error => error.type === TeslaErrorType.auth)
    );
    const hasScopeError = teslaAccounts.some(account =>
        account.errors.some(error => error.type === TeslaErrorType.scope)
    );

    return (
        <div className="flex flex-col min-h-screen bg-gray-100 font-sans text-gray-800">
            <Header />

            <main className="container mx-auto px-6 py-8">
                <div className="flex justify-between items-center mb-8">
                    <h2 className="text-3xl font-bold text-gray-800">
                        {selectedVehicle ? selectedVehicle.name : "EVAlarm.app"}
                    </h2>
                    <Link to="/help" className="text-teal-600 hover:text-teal-700 transition duration-300">
                        Need Help?
                    </Link>
                </div>

                {hasAuthError && (
                    <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
                        <p className="font-bold">Tesla Account Authentication Error</p>
                        <p>There's an issue with your Tesla account authentication. Please <Link to="/settings/tesla" className="underline">re-authenticate your Tesla account</Link> to ensure continued access to your vehicle data.</p>
                    </div>
                )}

                {hasScopeError && (
                    <div className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4" role="alert">
                        <p className="font-bold">Tesla Account Permission Error</p>
                        <p>EVAlarm.app doesn't have all the necessary permissions for your Tesla account. Please <Link to="/settings/tesla" className="underline">review and update your Tesla account permissions</Link> to ensure full functionality.</p>
                    </div>
                )}

                {subscriptionStatus && !isSubscriptionActive && (
                    <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-8" role="alert">
                        <p className="font-bold">Subscription Inactive</p>
                        <p>Your subscription is not active. Some features are limited and your vehicle data is no longer updating. <Link to="/settings/plans" className="underline">Renew your subscription</Link> to regain full access and real-time updates.</p>
                    </div>
                )}

                {vehicles.length === 0 ? (
                    <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-8" role="alert">
                        <p className="font-bold">No Vehicles Found</p>
                        <p>You haven't linked any vehicles to your account yet. To get started, <Link to="/settings/tesla" className="underline">connect your Tesla account</Link>. Your vehicle data will appear automatically once linked.</p>
                    </div>
                ) : (
                    <>
                        {vehicles.length > 1 && (
                            <VehicleSelector />
                        )}

                        {selectedVehicle ? (
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                                <BatteryStatus
                                    batteryPercentage={selectedVehicle.batteryPercentage}
                                    range={selectedVehicle.range}
                                    chargingStatus={selectedVehicle.chargingStatus}
                                    estimatedTimeToFull={selectedVehicle.estimatedTimeToFull}
                                />
                                <QuickAlertSetting vehicleId={selectedVehicle.id} />
                            </div>
                        ) : (
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
                                {vehicles.map(vehicle => (
                                    <VehicleSummary
                                        key={vehicle.id}
                                        vehicle={vehicle}
                                        onSelect={() => handleSelectVehicle(vehicle.id)}
                                    />
                                ))}
                            </div>
                        )}

                        <ActiveAlerts />
                    </>
                )}
            </main>

            <Footer />
        </div>
    );
};

export default EVAlarmDashboard;
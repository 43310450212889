import { Link } from 'react-router-dom';
import { ArticleCategory } from "../knowledge_base_types";

export const privacyArticles = [
  {
    id: "data-protection",
    title: 'How We Protect Your Data',
    content: (
      <>
        <h2>How We Protect Your Data</h2>
        <p>At EVAlarm.app, we believe in maximum privacy with minimal data collection. Here's our approach:</p>
        <ul>
          <li><strong>Minimal Data Collection:</strong> We collect only the bare minimum of data necessary to provide you with the best possible service.</li>
          <li><strong>No Third-Party Analytics:</strong> We do not work with any third-party analytics providers, ensuring your data stays within our secure ecosystem.</li>
          <li><strong>Payment Privacy:</strong> Our payment partners do not share your payment details with us, maintaining a strict separation between financial and user data.</li>
          <li><strong>Anonymity First:</strong> We don't collect or store your name, and we prefer to keep it that way. Your privacy is our priority.</li>
          <li><strong>Legal Compliance:</strong> We fully comply with major data privacy legislations, including GDPR, CCPA, and various other data privacy laws.</li>
          <li><strong>Right to be Forgotten</strong>: We honor all requests for account deletion in accordance with applicable privacy laws.</li>
        </ul>
        <p>For more details on how we handle your data, please review our full <Link to="/privacy">Privacy Policy</Link>.</p>
      </>
    ),
    category: ArticleCategory.PrivacyAndData,
  },
]

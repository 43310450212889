import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    HelpCircle,
    LogIn,
    Download,
    ChevronRight,
    Twitter,
    Github,
    Instagram,
    ExternalLink,
    Menu,
    X,
    BookOpen
} from 'lucide-react';
import LogoIcon from "../common/LogoIcon";
import { motion, AnimatePresence } from 'framer-motion';

interface NavLink {
    to: string;
    icon: React.ElementType;
    label: string;
}

interface SocialLink {
    icon: React.ElementType;
    href: string;
    ariaLabel: string;
}

interface FooterSection {
    title: string;
    links: Array<{
        label: string;
        to: string;
    }>;
}

interface EVAlarmLayoutProps {
    children: React.ReactNode;
}

const navigationLinks: NavLink[] = [
    { to: '/blog', icon: BookOpen, label: 'Blog' },
    { to: '/download', icon: Download, label: 'Download' },
    { to: '/help', icon: HelpCircle, label: 'Help' },
    { to: '/signin', icon: LogIn, label: 'Sign In' }
];

const socialLinks: SocialLink[] = [
    // { icon: Twitter, href: '#', ariaLabel: 'Twitter' },
    { icon: Github, href: 'https://github.com/Utilitarian-Infrastructure', ariaLabel: 'Github' },
];

const footerSections: FooterSection[] = [
    {
        title: 'Product',
        links: [
            { label: 'Download', to: '/download' },
            { label: 'Blog', to: '/blog' },
            { label: 'Pricing', to: '/plans' },
            { label: 'Help Center', to: '/help' }
        ]
    },
    {
        title: 'Legal',
        links: [
            { label: 'Privacy Policy', to: '/privacy' },
            { label: 'Terms of Service', to: '/tos' },
            { label: 'Contact Us', to: '/help/ticket' }
        ]
    }
];

const MobileMenu = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => (
    <AnimatePresence>
        {isOpen && (
            <motion.div
                initial={{ opacity: 0, x: '100%' }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: '100%' }}
                transition={{ type: 'spring', damping: 25 }}
                className="fixed inset-y-0 right-0 w-full sm:w-80 bg-white shadow-2xl z-50"
            >
                <div className="p-6">
                    <div className="flex justify-between items-center mb-8">
                        <Link to="/" className="flex items-center space-x-2">
                            <LogoIcon size={24} />
                            <span className="text-xl font-bold text-teal-600">EVAlarm</span>
                        </Link>
                        <button onClick={onClose} className="p-2 hover:bg-gray-100 rounded-full">
                            <X className="w-6 h-6 text-gray-600" />
                        </button>
                    </div>
                    <nav className="space-y-6">
                        {navigationLinks.map(({ to, icon: Icon, label }) => (
                            <Link
                                key={to}
                                to={to}
                                className={`flex items-center justify-between w-full p-3 rounded-lg ${label === 'Sign In'
                                    ? 'bg-teal-600 text-white hover:bg-teal-700'
                                    : 'hover:bg-teal-50 text-gray-700 hover:text-teal-600'
                                    }`}
                                onClick={onClose}
                            >
                                <span className="flex items-center">
                                    <Icon className="w-5 h-5 mr-3" />
                                    {label}
                                </span>
                                <ChevronRight className="w-5 h-5" />
                            </Link>
                        ))}
                    </nav>
                </div>
            </motion.div>
        )}
    </AnimatePresence>
);

const EVAlarmLayout: React.FC<EVAlarmLayoutProps> = ({ children }) => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="min-h-screen bg-gradient-to-br from-teal-50 via-white to-cyan-50 font-sans text-gray-800">
            <div className="h-1 bg-gradient-to-r from-teal-500 via-cyan-500 to-teal-500" />

            <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white/80 backdrop-blur-lg shadow-sm' : 'bg-transparent'
                }`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center h-16 md:h-20">
                        <div className="flex items-center space-x-8">
                            <Link to="/" className="flex items-center space-x-2 group">
                                <motion.div
                                    whileHover={{ rotate: 360 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <LogoIcon size={32} />
                                </motion.div>
                                <span className="text-2xl font-extrabold bg-gradient-to-r from-teal-600 to-cyan-600 bg-clip-text text-transparent">
                                    EVAlarm
                                </span>
                            </Link>
                            <span className="text-sm text-gray-600 italic hidden lg:inline-block">
                                Say Goodbye to Overstays
                            </span>
                        </div>

                        <nav className="hidden md:flex items-center space-x-8">
                            {navigationLinks.map(({ to, icon: Icon, label }) => (
                                <Link
                                    key={to}
                                    to={to}
                                    className={`${label === 'Sign In'
                                        ? 'bg-gradient-to-r from-teal-600 to-teal-500 text-white px-6 py-2 rounded-full text-sm font-medium hover:from-teal-500 hover:to-teal-400'
                                        : 'text-gray-600 hover:text-teal-600'
                                        } transition-colors duration-300 flex items-center group`}
                                >
                                    <Icon className={`w-4 h-4 mr-2 ${label === 'Sign In' ? 'transition-transform duration-300 group-hover:translate-x-1' : ''
                                        }`} />
                                    {label}
                                </Link>
                            ))}
                        </nav>

                        <button
                            onClick={() => setIsMobileMenuOpen(true)}
                            className="md:hidden p-2 hover:bg-gray-100 rounded-full"
                        >
                            <Menu className="w-6 h-6 text-gray-600" />
                        </button>
                    </div>
                </div>
            </header>

            <MobileMenu
                isOpen={isMobileMenuOpen}
                onClose={() => setIsMobileMenuOpen(false)}
            />

            <main className="pt-16 md:pt-20">
                {children}
            </main>

            <footer className="bg-gray-900 text-white pt-20 pb-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-16">
                        <div className="md:col-span-2">
                            <Link to="/" className="flex items-center space-x-2 mb-6">
                                <LogoIcon size={32} />
                                <span className="text-2xl font-bold text-white">EVAlarm</span>
                            </Link>
                            <p className="text-gray-400 mb-6 max-w-md">
                                Supporting Tesla owners in efficient charging.
                                Designed for all Tesla models, with more EV brands coming soon!
                            </p>
                            <div className="flex space-x-4">
                                {socialLinks.map(({ icon: Icon, href, ariaLabel }) => (
                                    <a
                                        key={ariaLabel}
                                        href={href}
                                        aria-label={ariaLabel}
                                        className="w-10 h-10 rounded-full bg-gray-800 flex items-center justify-center hover:bg-gray-700 transition-colors duration-300"
                                    >
                                        <Icon className="w-5 h-5 text-gray-400" />
                                    </a>
                                ))}
                            </div>
                        </div>

                        {footerSections.map(({ title, links }) => (
                            <div key={title}>
                                <h3 className="text-lg font-semibold mb-6">{title}</h3>
                                <ul className="space-y-4">
                                    {links.map(({ label, to }) => (
                                        <li key={label}>
                                            <Link
                                                to={to}
                                                className="text-gray-400 hover:text-white transition-colors duration-300 flex items-center group"
                                            >
                                                {label}
                                                <ExternalLink className="w-4 h-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity" />
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>

                    <div className="border-t border-gray-800 pt-8">
                        <div className="flex flex-col md:flex-row justify-between items-center">
                            <p className="text-gray-400 text-sm mb-4 md:mb-0">
                                &copy; {new Date().getFullYear()} EVAlarm. All rights reserved.
                            </p>
                            <div className="flex space-x-6">
                                <a href="/privacy" className="text-gray-400 hover:text-white text-sm transition-colors duration-300">
                                    Privacy
                                </a>
                                <a href="/tos" className="text-gray-400 hover:text-white text-sm transition-colors duration-300">
                                    Terms
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default EVAlarmLayout;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Vehicle {
    id: string;
    name: string;
    batteryPercentage: number;
    range: number;
    chargingStatus: 'charging' | 'not charging';
    estimatedTimeToFull: number | null;
    alertTime: number;
    activeChargingSession: ActiveChargingSession | null;
}

export interface VehicleData {
    id: string;
    name: string;
    percentage: number;
    current_state: string;
    estimated_range: number;
    estimated_time_to_completion: number | null;
    setting_alert_min: number;
    active_charging_session: ActiveChargingSession | null;
}

export enum NotificationState {
    Unnotified = 'Unnotified',
    new = 'new',
    snooze = 'snooze',
    acknowledged = 'acknowledged',
    dismissed = 'dismissed',
    expired = 'expired'
}

export interface ActiveChargingSession {
    started_on: number;
    estimated_complete_on: number
    notification_state: NotificationState;
}

interface VehicleState {
    vehicles: Vehicle[];
    selectedVehicleId: number | null;
    error: string | null;
}

const initialState: VehicleState = {
    vehicles: [],
    selectedVehicleId: null,
    error: null,
};

const vehicleDataSlice = createSlice({
    name: 'vehicleData',
    initialState,
    reducers: {
        setVehicles: (state, action: PayloadAction<VehicleData[]>) => {
            state.vehicles = action.payload.map(vehicle => ({
                id: vehicle.id,
                name: vehicle.name,
                batteryPercentage: vehicle.percentage,
                range: vehicle.estimated_range,
                chargingStatus: vehicle.current_state === 'Charging' ? 'charging' : 'not charging',
                estimatedTimeToFull: vehicle.estimated_time_to_completion,
                alertTime: vehicle.setting_alert_min,
                activeChargingSession: vehicle.active_charging_session,
            }));
            if (state.vehicles.length === 1 && !state.selectedVehicleId) {
                state.selectedVehicleId = Number(state.vehicles[0].id);
            }
        },
        selectVehicle: (state, action: PayloadAction<string>) => {
            state.selectedVehicleId = Number(action.payload);
        },
        updateVehicle: (state, action: PayloadAction<VehicleData>) => {
            const index = state.vehicles.findIndex(v => v.id === action.payload.id);
            if (index !== -1) {
                state.vehicles[index] = {
                    id: action.payload.id,
                    name: action.payload.name,
                    batteryPercentage: action.payload.percentage,
                    range: action.payload.estimated_range,
                    chargingStatus: action.payload.current_state === 'Charging' ? 'charging' : 'not charging',
                    estimatedTimeToFull: action.payload.estimated_time_to_completion,
                    alertTime: action.payload.setting_alert_min,
                    activeChargingSession: action.payload.active_charging_session,
                };
            }
        },
        setAlertTimeStart: (state, action: PayloadAction<{ vehicleId: string; alertTime: number }>) => {
            const vehicle = state.vehicles.find(v => v.id === action.payload.vehicleId);
            if (vehicle) {
                vehicle.alertTime = action.payload.alertTime;
            }
        },
        setAlertTimeSuccess: (state) => {
            state.error = null;
        },
        setAlertTimeFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        }
    },
});

export const { setVehicles, selectVehicle, updateVehicle, setAlertTimeStart, setAlertTimeSuccess, setAlertTimeFailure } = vehicleDataSlice.actions;

export default vehicleDataSlice.reducer;
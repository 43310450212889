import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

// Import components
import Login from './pages/SignInPage';
import Dashboard from './pages/EVAlarmDashboard';
import PublicPage from './pages/EVAlarmLanding';
import LoadingScreen from './pages/LoadingScreen';
import Settings from "./pages/Settings";
import SignUpForm from "./pages/SignUpForm";
import PlanComparison from "./pages/PlanComparison";
import ProtectedRoute from './ProtectedRoute';
import { RootState } from "../store";
import { initializeApp } from "../store/userSlice";

// Import Settings subcomponents
import AccountSettings from './settings/AccountSettings';
import LinkedAccounts from './settings/LinkedAccounts';
import PlansAndPayments from './settings/PlansAndPayments';
import DeviceManagement from './settings/DeviceManagement';
import StripeWrapper from "./common/StripeWrapper";
import { setActiveTab, SettingTab } from "../store/settingSlice";
import TicketingPage from "./pages/TicketingPage";
import HelpLayout from "./layout/HelpLayout";
import ArticlePage from "./pages/ArticlePage";
import KnowledgeBasePage from "./pages/KnowledgeBasePage";
import ClientDownload from "./pages/ClientDownload";
import TermsOfService from "./pages/TermsOfServicePage";
import PrivacyPolicy from "./pages/PrivacyPage";
import { BlogListPage } from './pages/BlogListPage';
import { BlogPostPage } from './pages/BlogPostPage';
import EVAlarmLayout from './layout/EVAlarmLayout';

interface AppRoutesProps {
    message: string;
    // Add other props as needed
}


const SignUpWrapper: React.FC = () => {
    const isRegistrationEnabled = import.meta.env.VITE_REGISTRATION_ENABLED === 'true';

    useEffect(() => {
        if (!isRegistrationEnabled) {
            window.location.href = '/presign-up';
        }
    }, [isRegistrationEnabled]);

    if (!isRegistrationEnabled) {
        return null; // Render nothing while redirecting
    }

    return <SignUpForm />;
};

/**
 * AppRoutes Component
 *
 * This component defines the routing structure for the entire application.
 * It handles authentication state, app initialization, and renders appropriate
 * components based on the current route and user's authentication status.
 *
 * @param {AppRoutesProps} props - Component props
 * @returns {React.ReactElement} The rendered routes
 */

const AppRoutes: React.FC<AppRoutesProps> = ({ message }) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const loading = useSelector((state: RootState) => state.user.loading);

    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        const initialize = async () => {
            await dispatch(initializeApp());
            setIsInitialized(true);
        };
        initialize();
    }, [dispatch]);

    useEffect(() => {
        const path = location.pathname;
        if (path.startsWith('/settings')) {
            if (path.includes('/account')) {
                dispatch(setActiveTab(SettingTab.Account));
            } else if (path.includes('/devices')) {
                dispatch(setActiveTab(SettingTab.Device));
            } else if (path.includes('/tesla')) {
                dispatch(setActiveTab(SettingTab.TeslaAccount));
            } else if (path.includes('/plans')) {
                dispatch(setActiveTab(SettingTab.Plan));
            }
        }
    }, [location.pathname, dispatch]);

    if (loading || !isInitialized) {
        return <LoadingScreen />;
    }

    return (
        <Routes>
            <Route path="/download" element={<ClientDownload />} />

            <Route path="/signin" element={
                isAuthenticated ? <Navigate to="/dashboard" replace state={{ from: location }} /> : <Login />
            } />

            <Route path="/dashboard" element={
                <ProtectedRoute element={<Dashboard />} redirectPath="/signin" />
            } />

            <Route path="/settings" element={<ProtectedRoute element={<Settings />} redirectPath="/signin" />}>
                <Route index element={<Navigate to="account" replace />} />
                <Route path="account" element={<AccountSettings />} />
                <Route path="devices" element={<DeviceManagement />} />
                <Route path="tesla" element={<LinkedAccounts />} />
                <Route path="plans" element={<StripeWrapper><PlansAndPayments /></StripeWrapper>} />
            </Route>

            <Route path="/" element={
                isAuthenticated ? <Navigate to="/dashboard" replace state={{ from: location }} /> : <PublicPage />
            } />

            <Route path="/blog" element={
                <EVAlarmLayout>
                    <BlogListPage />
                </EVAlarmLayout>
            } />
            <Route path="/blog/:slug" element={
                <EVAlarmLayout>
                    <BlogPostPage />
                </EVAlarmLayout>
            } />

            <Route path="/plans" element={<PlanComparison />} />

            <Route path="/signup" element={
                isAuthenticated ? (
                    <Navigate to="/dashboard" replace state={{ from: location }} />
                ) : (
                    <SignUpWrapper />
                )
            } />

            {/* Help section routes */}
            <Route path="/help" element={
                <HelpLayout title="EVAlarm Help Center" subtitle="How can we assist you today?">
                    <p>Welcome to our Help Center. Choose a category, browse our knowledge base, or search for help.</p>
                </HelpLayout>
            } />

            <Route path="/help/kb" element={<KnowledgeBasePage />} />

            <Route path="/help/article/:id" element={<ArticlePage />} />

            <Route path="/help/ticket/*" element={<TicketingPage />} />

            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />

            <Route path="*" element={
                isAuthenticated ? <Navigate to="/dashboard" replace state={{ from: location }} /> : <Navigate to="/" replace state={{ from: location }} />
            } />
        </Routes>
    );
};

export default AppRoutes;

import React from 'react';
import { Link } from 'react-router-dom';
import HelpLayout from '../layout/HelpLayout';
import {ArticleCategory} from "../knowledge_base/knowledge_base_types";
import {knowledgeBase} from "../knowledge_base/knowledge_base_data";

const KnowledgeBasePage: React.FC = () => {
    const categories = Object.values(ArticleCategory);
    const articles = knowledgeBase.getAllArticles();

    return (
        <HelpLayout title="Knowledge Base" subtitle="Find answers to common questions">
            <div className="space-y-8">
                {categories.map((category) => {
                    const categoryArticles = articles.filter((article) => article.category === category);
                    if (categoryArticles.length === 0) return null;

                    return (
                        <div key={category} className="bg-gray-50 p-6 rounded-lg">
                            <h2 className="text-2xl font-bold mb-4">{category}</h2>
                            <ul className="space-y-2">
                                {categoryArticles.map((article) => (
                                    <li key={article.id}>
                                        <Link
                                            to={`/help/article/${article.id}`}
                                            className="text-teal-600 hover:text-teal-800"
                                        >
                                            {article.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </HelpLayout>
    );
};

export default KnowledgeBasePage;
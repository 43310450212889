import { useSelector, useDispatch } from 'react-redux';
import { Shield, Info, ArrowRight, Clock, Users, Check } from 'lucide-react';
import { RootState } from '../../store';
import { setBillingCycle } from '../../store/plansSlice';
import EVAlarmLayout from "../layout/EVAlarmLayout";
import { Link } from 'react-router-dom';

const LifetimeOffer = () => (
    <div className="relative overflow-hidden rounded-3xl border-2 border-teal-200 bg-gradient-to-br from-teal-50 to-white p-8">
        <div className="absolute inset-0 bg-gradient-to-r from-teal-500/5 to-cyan-500/5 animate-gradient-x"></div>

        <div className="absolute -right-12 top-8 rotate-45 bg-teal-600 px-14 py-1.5 text-sm text-white shadow-lg">
            Launch Special
        </div>

        <h3 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            Lifetime Premium Access
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800">
                Best Value
            </span>
        </h3>

        <div className="flex items-baseline mb-2">
            <span className="text-5xl font-bold text-gray-900">$299</span>
            <span className="ml-2 text-gray-600">one-time</span>
        </div>

        <div className="mb-6">
            <span className="inline-block text-sm text-gray-500">
                <span className="line-through">$749.95</span>
                {" "}value (5-year premium plan)
            </span>
        </div>

        <div className="space-y-4 mb-8">
            {[
                "All Premium features forever",
                "Exclusive Founder's Badge",
                "Priority Support",
                "Early access to new features",
                "No price increases ever"
            ].map((feature, index) => (
                <div key={index} className="flex items-center">
                    <span className="inline-flex items-center justify-center w-5 h-5 bg-teal-100 rounded-full mr-3">
                        <Check className="w-3 h-3 text-teal-600" />
                    </span>
                    <span className="text-gray-700">{feature}</span>
                </div>
            ))}
        </div>

        <div className="bg-white rounded-xl p-4 mb-8 border border-teal-100 shadow-sm">
            <div className="flex items-center text-gray-600 mb-2">
                <Shield className="w-5 h-5 mr-2 text-teal-600" />
                <span className="font-medium">60-Day Money-Back Guarantee</span>
            </div>
            <p className="text-sm text-gray-600">
                Try EVAlarm risk-free. Not happy? Get a full refund, no questions asked.
            </p>
        </div>

        <Link
            to="/signup"
            className="w-full bg-teal-600 text-white rounded-full py-4 px-8 font-medium hover:bg-teal-700 transition-all duration-300 flex items-center justify-center group shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
        >
            Get Lifetime Access
            <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
        </Link>

        <div className="mt-4 text-center">
            <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <span className="flex items-center">
                    <Clock className="w-4 h-4 mr-1" />
                    Limited time
                </span>
                <span>•</span>
                <span className="flex items-center">
                    <Users className="w-4 h-4 mr-1" />
                    Only 200 seats
                </span>
            </div>
        </div>
    </div>
);

const BillingToggle = ({ cycle, onChange }: {
    cycle: 'monthly' | 'yearly',
    onChange: (cycle: 'monthly' | 'yearly') => void
}) => {
    const yearlyDiscount = 20; // Example discount percentage

    return (
        <div className="flex flex-col items-center space-y-4">
            <div className="flex items-center gap-3">
                <span className={`text-sm font-medium ${cycle === 'monthly' ? 'text-gray-900' : 'text-gray-500'}`}>
                    Monthly
                </span>

                <button
                    onClick={() => onChange(cycle === 'monthly' ? 'yearly' : 'monthly')}
                    className="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                    style={{ backgroundColor: cycle === 'yearly' ? '#0D9488' : '#E5E7EB' }}
                >
                    <span
                        className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${cycle === 'yearly' ? 'translate-x-5' : 'translate-x-0'
                            }`}
                    />
                </button>

                <span className={`text-sm font-medium ${cycle === 'yearly' ? 'text-gray-900' : 'text-gray-500'}`}>
                    Yearly
                </span>
            </div>

            {cycle === 'yearly' && (
                <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                    Save {yearlyDiscount}%
                </span>
            )}
        </div>
    );
};


const PlanComparison = () => {
    const dispatch = useDispatch();
    const plans = useSelector((state: RootState) => state.plans.plans);
    const billingCycle = useSelector((state: RootState) => state.plans.billingCycle);

    const handleBillingCycleChange = (cycle: 'monthly' | 'yearly') => {
        dispatch(setBillingCycle(cycle));
    };

    return (
        <EVAlarmLayout>
            <section className="relative overflow-hidden py-20 md:py-32">
                <div className="absolute inset-0 bg-gradient-to-br from-teal-50/50 via-cyan-50/30 to-white" />
                <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-to-br from-teal-400/20 to-cyan-400/20 blur-3xl transform rotate-12 animate-pulse" />

                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
                    <div className="text-center mb-16">
                        <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
                            Choose Your <span className="text-teal-600">Protection Plan</span>
                        </h1>
                        <p className="text-xl text-gray-700 max-w-2xl mx-auto">
                            Start saving on overstay fees today with our special launch offer
                        </p>
                    </div>

                    <div className="space-y-20">
                        {/* Lifetime offer */}
                        <div className="max-w-2xl mx-auto">
                            <LifetimeOffer />
                        </div>

                        {/* Regular plans section */}
                        <div>
                            <div className="text-center mb-12">
                                <h2 className="text-2xl md:text-3xl font-bold text-gray-900 mb-6">
                                    Subscription Plans
                                </h2>

                                {/* Billing toggle for regular plans */}
                                <BillingToggle
                                    cycle={billingCycle}
                                    onChange={handleBillingCycleChange}
                                />
                            </div>

                            {/* Regular plans grid */}
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                {plans.map((plan) => (
                                    <div key={plan.id} className={`relative overflow-hidden rounded-3xl border-2 ${plan.name === "Premium"
                                        ? 'border-teal-200 bg-gradient-to-br from-teal-50 to-white'
                                        : 'border-gray-200 bg-white'
                                        } p-8`}>
                                        {plan.name === "Premium" && (
                                            <div className="absolute top-4 right-4">
                                                <span className="inline-flex items-center rounded-full bg-teal-100 px-3 py-1 text-sm font-medium text-teal-800">
                                                    Most Popular
                                                </span>
                                            </div>
                                        )}

                                        <h3 className="text-2xl font-bold text-gray-900 mb-4">
                                            {plan.name}
                                        </h3>

                                        <div className="flex items-baseline mb-6">
                                            <span className="text-5xl font-bold text-gray-900">
                                                ${billingCycle === 'monthly' ? plan.monthly_price : plan.yearly_price}
                                            </span>
                                            <span className="ml-2 text-gray-600">
                                                /{billingCycle === 'monthly' ? 'mo' : 'year'}
                                            </span>
                                        </div>

                                        <div className="space-y-4 mb-8">
                                            {plan.features.map((feature, index) => (
                                                <div key={index} className="flex items-center">
                                                    <span className="inline-flex items-center justify-center w-5 h-5 bg-teal-100 rounded-full mr-3">
                                                        <Check className="w-3 h-3 text-teal-600" />
                                                    </span>
                                                    <span className="text-gray-700">{feature}</span>
                                                </div>
                                            ))}
                                        </div>

                                        <Link
                                            to="/signup"
                                            className={`w-full rounded-full py-4 px-8 font-medium transition duration-300 flex items-center justify-center group ${plan.name === "Premium"
                                                ? 'bg-teal-600 text-white hover:bg-teal-700'
                                                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                                                }`}
                                        >
                                            Get Started
                                            <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="mt-16 text-center space-y-6">
                        <div className="flex items-center justify-center space-x-4 text-gray-600">
                            <Shield className="w-5 h-5 text-teal-600" />
                            <span>60-day money-back guarantee</span>
                        </div>

                        <p className="text-sm text-gray-500 flex items-center justify-center">
                            <Info className="h-4 w-4 mr-2" />
                            Money-back guarantee applies only to plans purchased through our web app.
                        </p>
                    </div>
                </div>
            </section>
        </EVAlarmLayout>
    );
};

export default PlanComparison;

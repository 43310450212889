import { Link } from 'react-router-dom';
import { ArticleCategory } from "../knowledge_base_types";

export const contactArticles = [
  {
    id: "contact-support",
    title: 'Ways to Reach Our Support Team',
    content: (
      <>
        <h2>Ways to Reach Our Support Team</h2>
        <p>Need help? We're here to assist you. Here are the ways you can get in touch with our support team:</p>
        <ol>
          <li>
            <strong>Create a New Ticket:</strong> For detailed inquiries or issues, you can create a new support ticket directly through our platform. This helps us track and resolve your concern efficiently.
          </li>
          <li>
            <strong>Email:</strong> Send your questions or concerns to <a href="mailto:new-ticket@inbound.evalarm.app">new-ticket@inbound.evalarm.app</a>. Each email automatically creates a new support ticket in our system.
          </li>
          <li>
            <strong>Knowledge Base:</strong> Before reaching out, you might find quick answers in our extensive <Link to="/help/kb">FAQ and troubleshooting guides</Link>. It's a great resource for common questions and issues.
          </li>
        </ol>
      </>
    ),
    category: ArticleCategory.Contact,
  },
]

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import HelpLayout from '../layout/HelpLayout';
import {knowledgeBase} from "../knowledge_base/knowledge_base_data";

const ArticlePage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const article = knowledgeBase.findArticleById(id!);

    if (!article) {
        return (
            <HelpLayout title="Article Not Found" subtitle="The requested article could not be found.">
                <p>Sorry, we couldn't find the article you're looking for. It may have been moved or deleted.</p>
                <Link to="/help/kb" className="text-teal-600 hover:text-teal-800">
                    Return to Knowledge Base
                </Link>
            </HelpLayout>
        );
    }

    return (
        <HelpLayout title={article.title} subtitle={`Category: ${article.category}`}>
            <div className="prose max-w-none">
                {article.content}
            </div>
            <div className="mt-8">
                <Link to="/help/kb" className="text-teal-600 hover:text-teal-800">
                    Back to Knowledge Base
                </Link>
            </div>
        </HelpLayout>
    );
};

export default ArticlePage;
import React from 'react';

// Define the categories
export enum ArticleCategory {
    AccountAndSignUp = 'Account & Sign Up',
    App = 'App',
    BillingAndPlans = 'Billing & Plans',
    PrivacyAndData = 'Privacy & Data',
    Contact = 'Contact',
}

// Define the structure of an article
export interface Article {
    id: string;
    title: string;
    content: React.ReactNode;
    category: ArticleCategory;
    createdAt: Date;
    updatedAt: Date;
}

// Create a class to manage the knowledge base
export class KnowledgeBase {
    private articles: Article[] = [];

    constructor(initialArticles: Omit<Article, 'createdAt' | 'updatedAt'>[]) {
        initialArticles.forEach(article => this.addArticle(article));
    }

    // Add a new article
    addArticle(articleData: Omit<Article, 'createdAt' | 'updatedAt'>): Article {
        const newArticle: Article = {
            ...articleData,
            createdAt: new Date(),
            updatedAt: new Date(),
        };
        this.articles.push(newArticle);
        return newArticle;
    }

    // Get all articles
    getAllArticles(): Article[] {
        return this.articles;
    }

    // Get articles by category
    getArticlesByCategory(category: ArticleCategory): Article[] {
        return this.articles.filter(article => article.category === category);
    }

    // Find article by ID
    findArticleById(id: string): Article | undefined {
        return this.articles.find(article => article.id === id);
    }

    // Search articles
    searchArticles(query: string): Article[] {
        const lowercaseQuery = query.toLowerCase();
        return this.articles.filter(article =>
            article.title.toLowerCase().includes(lowercaseQuery) ||
            (typeof article.content === 'string' && article.content.toLowerCase().includes(lowercaseQuery))
        );
    }

    // Update an article
    updateArticle(id: string, updateData: Partial<Omit<Article, 'id' | 'createdAt'>>): Article | undefined {
        const articleIndex = this.articles.findIndex(article => article.id === id);
        if (articleIndex !== -1) {
            this.articles[articleIndex] = {
                ...this.articles[articleIndex],
                ...updateData,
                updatedAt: new Date(),
            };
            return this.articles[articleIndex];
        }
        return undefined;
    }

    // Delete an article
    deleteArticle(id: string): boolean {
        const initialLength = this.articles.length;
        this.articles = this.articles.filter(article => article.id !== id);
        return this.articles.length < initialLength;
    }
}
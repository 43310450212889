import React from 'react';
import { AppleIcon, CheckCircle, Lock } from 'lucide-react';
import { Plan } from "../../../store/plansSlice";
import { PaymentProcessor, UpdateableBillingFrequency } from "../../../store/subscriptionSlice";

interface ToggleSwitchProps {
    checked: boolean;
    onChange: () => void;
    disabled: boolean;
}

interface PlanCardProps {
    plan: Plan;
    selectedPlanId: string;
    currentPlan: string | null;
    billingCycle: 'monthly' | 'yearly';
    onSelect: (planId: string) => void;
    disabled: boolean;
}

interface PlanSelectionProps {
    plans: Plan[];
    billingCycle: UpdateableBillingFrequency;
    selectedPlanId: string;
    currentPlan: string | null;
    onPlanChange: (planId: string) => void;
    onBillingCycleChange: () => void;
    paymentProcessor: PaymentProcessor;
    autoRenewal: boolean;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({ checked, onChange, disabled }) => (
    <label className={`flex items-center ${disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`}>
        <div className="relative">
            <input
                type="checkbox"
                className="sr-only"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
            <div className={`absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${checked ? 'transform translate-x-full bg-teal-500' : 'bg-gray-200'}`}></div>
        </div>
    </label>
);

const PlanCard: React.FC<PlanCardProps> = ({ plan, selectedPlanId, currentPlan, billingCycle, onSelect, disabled }) => (
    <div
        className={`bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 ${selectedPlanId === plan.id ? 'ring-4 ring-teal-500' : 'hover:shadow-xl'
            } ${currentPlan === plan.name ? 'border-4 border-teal-500' : ''} ${disabled ? 'opacity-75' : ''}`}
    >
        <div className="p-8">
            <h4 className="text-2xl font-bold text-gray-800 mb-4">{plan.name}</h4>
            <p className="text-gray-600 mb-6">{plan.description}</p>
            <p className="text-4xl font-bold text-teal-600 mb-6">
                ${billingCycle === 'monthly' ? plan.monthly_price : plan.yearly_price}
                <span className="text-sm font-normal text-gray-600">/{billingCycle}</span>
            </p>
            <ul className="text-sm text-gray-600 mb-8">
                {plan.features.map((feature, index) => (
                    <li key={index} className="flex items-center mb-3">
                        <CheckCircle className="w-5 h-5 text-teal-500 mr-3 flex-shrink-0" />
                        <span>{feature}</span>
                    </li>
                ))}
            </ul>
        </div>
        <div className="px-8 pb-8">
            <button
                onClick={() => !disabled && onSelect(plan.id)}
                className={`w-full py-3 px-6 rounded-full text-lg font-semibold transition-colors duration-300 ${disabled
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    : selectedPlanId === plan.id
                        ? 'bg-teal-600 text-white'
                        : 'bg-gray-200 text-gray-800 hover:bg-teal-500 hover:text-white'
                    }`}
                disabled={disabled}
            >
                {disabled ? (
                    <span className="flex items-center justify-center">
                        <Lock className="w-5 h-5 mr-2" />
                        {currentPlan === plan.name ? 'Current Plan' : 'Selection Disabled'}
                    </span>
                ) : selectedPlanId === plan.id ? (
                    'Selected'
                ) : (
                    'Select Plan'
                )}
            </button>
        </div>
    </div>
);

export const PlanSelection: React.FC<PlanSelectionProps> = ({
    plans,
    billingCycle,
    selectedPlanId,
    currentPlan,
    onPlanChange,
    onBillingCycleChange,
    paymentProcessor,
    autoRenewal
}) => {
    const isAppleIAP = paymentProcessor === PaymentProcessor.AppleIAP;
    const isSelectionDisabled = isAppleIAP || !autoRenewal;

    return (
        <div className="mb-12">
            <div className="flex justify-between items-center mb-6">
                <h3 className="text-2xl font-bold text-gray-800">Available Plans</h3>
                <div className="flex items-center space-x-4">
                    <span className={`text-sm ${billingCycle === 'monthly' ? 'font-bold' : ''}`}>Monthly</span>
                    <ToggleSwitch
                        checked={billingCycle === 'yearly'}
                        onChange={onBillingCycleChange}
                        disabled={isSelectionDisabled}
                    />
                    <span className={`text-sm ${billingCycle === 'yearly' ? 'font-bold' : ''}`}>Yearly</span>
                </div>
            </div>
            {isAppleIAP && (
                <div className="bg-gray-50 border border-gray-200 rounded-lg p-4 mb-6">
                    <p className="text-gray-800 text-center flex items-center justify-center">
                        <AppleIcon className="w-5 h-5 mr-2" />
                        Your subscription is managed through Apple. To make changes, please use the App Store on your iOS device.
                    </p>
                </div>
            )}
            {!autoRenewal && !isAppleIAP && (
                <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4 mb-6">
                    <p className="text-yellow-800 text-center">
                        Auto-renewal is disabled. To change plans, please reactivate your subscription.
                    </p>
                </div>
            )}
            <div className="grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {plans.map((plan) => (
                    <PlanCard
                        key={plan.id}
                        plan={plan}
                        selectedPlanId={selectedPlanId}
                        currentPlan={currentPlan}
                        billingCycle={billingCycle}
                        onSelect={onPlanChange}
                        disabled={isSelectionDisabled}
                    />
                ))}
            </div>
        </div>
    );
};

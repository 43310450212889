import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LogoIcon from '../common/LogoIcon';
import { Search, FileText, LifeBuoy, ChevronRight } from 'lucide-react';
import { knowledgeBase } from "../knowledge_base/knowledge_base_data";
import { Article } from "../knowledge_base/knowledge_base_types";
import { RootState } from '../../store';
import { signOutRequest } from '../../store/userSlice';

interface HelpLayoutProps {
    children: React.ReactNode;
    title: string;
    subtitle?: string;
}

const HelpLayout: React.FC<HelpLayoutProps> = ({ children, title, subtitle }) => {
    const [searchResults, setSearchResults] = useState<Article[]>([]);
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const currentUser = useSelector((state: RootState) => state.user.currentUser);
    const dispatch = useDispatch();

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const query = event.target.value;
        if (query.trim()) {
            const results = knowledgeBase.searchArticles(query);
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    };

    const handleSignOut = () => {
        dispatch(signOutRequest());
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col relative overflow-hidden">
            {/* Background elements */}
            <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-teal-400 to-cyan-400"></div>
            <div className="absolute top-10 left-10 w-64 h-64 bg-teal-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
            <div className="absolute top-10 right-10 w-64 h-64 bg-cyan-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
            <div className="absolute bottom-10 left-20 w-64 h-64 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>

            {/* Header */}
            <header className="bg-white shadow-sm z-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4 flex justify-between items-center">
                    <div className="flex items-center">
                        <Link to="/" className="flex items-center">
                            <LogoIcon size={40} />
                            <span className="ml-2 text-2xl font-bold text-gray-900">EVAlarm Help</span>
                        </Link>
                    </div>
                    <nav>
                        {isAuthenticated ? (
                            <div className="flex items-center space-x-4">
                                <span className="text-gray-600">Hello, {currentUser?.email}</span>
                                <button
                                    onClick={handleSignOut}
                                    className="text-teal-600 hover:text-teal-800 font-medium"
                                >
                                    Sign out
                                </button>
                            </div>
                        ) : (
                            <Link to="/login" className="text-teal-600 hover:text-teal-800 font-medium">
                                Log in
                            </Link>
                        )}
                    </nav>
                </div>
            </header>

            {/* Main content */}
            <main className="flex-grow container mx-auto px-4 sm:px-6 lg:px-8 py-12">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-4xl font-extrabold text-gray-900 text-center mb-6">{title}</h1>
                    {subtitle && (
                        <p className="text-xl text-gray-600 text-center mb-12">{subtitle}</p>
                    )}

                    {/* Search bar */}
                    <div className="mb-12">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search for help..."
                                onChange={handleSearch}
                                className="w-full py-3 px-4 pr-12 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-transparent"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                <Search className="h-5 w-5 text-gray-400" />
                            </div>
                        </div>
                        {searchResults.length > 0 && (
                            <div className="mt-4 bg-white shadow-md rounded-md">
                                {searchResults.map(article => (
                                    <Link
                                        to={`/help/article/${article.id}`}
                                        key={article.id}
                                        className="block p-4 border-b last:border-b-0 hover:bg-gray-50"
                                    >
                                        <h3 className="text-lg font-semibold text-gray-900">{article.title}</h3>
                                        <p className="text-sm text-gray-600">{article.category}</p>
                                    </Link>
                                ))}
                            </div>
                        )}
                    </div>

                    {/* Quick links */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
                        <Link to="/help/kb" className="flex items-center p-6 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow">
                            <FileText className="h-8 w-8 text-teal-600 mr-4" />
                            <div>
                                <h2 className="text-xl font-semibold text-gray-900">Knowledge Base</h2>
                                <p className="text-gray-600">Browse our articles and guides</p>
                            </div>
                            <ChevronRight className="h-6 w-6 text-gray-400 ml-auto" />
                        </Link>
                        <Link to="/help/ticket" className="flex items-center p-6 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow">
                            <LifeBuoy className="h-8 w-8 text-teal-600 mr-4" />
                            <div>
                                <h2 className="text-xl font-semibold text-gray-900">Create a Ticket</h2>
                                <p className="text-gray-600">Get personalized support</p>
                            </div>
                            <ChevronRight className="h-6 w-6 text-gray-400 ml-auto" />
                        </Link>
                    </div>

                    {/* Main content area */}
                    <div className="bg-white shadow-xl rounded-xl p-8">
                        {children}
                    </div>
                </div>
            </main>

            {/* Footer */}
            <footer className="bg-gray-800 text-white py-8 z-10">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between items-center">
                        <p>&copy; 2024 EVAlarm. All rights reserved.</p>
                        <nav className="flex space-x-4">
                            <a href="/download" className="hover:text-teal-300">Download</a>
                            <a href="/tos" className="hover:text-teal-300">Terms</a>
                            <a href="/privacy" className="hover:text-teal-300">Privacy</a>
                            <a href="/help/ticket" className="hover:text-teal-300">Contact</a>
                        </nav>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default HelpLayout;
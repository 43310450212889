import {AppEpic, getAuthHeaders, parseHttpError} from "./index";
import {combineEpics, ofType} from "redux-observable";
import {
    updateAccountSettingsFailure,
    updateAccountSettingsSuccess, User
} from "../userSlice";
import {catchError, mergeMap, switchMap, withLatestFrom} from "rxjs/operators";
import {from, of} from "rxjs";
import {VehicleData} from "../vehicleDataSlice";
import {TeslaAccount} from "../teslaAccountsSlice";
import {
    AccountSettingStep,
    resetAccountSettingsForm,
    setAccountSettingStep,
    triggerAccountSettingsUpdate
} from "../settingSlice";

const updateAccountSettingsEpic: AppEpic = (action$, state$) => action$.pipe(
    ofType(triggerAccountSettingsUpdate.type),
    withLatestFrom(state$),
    switchMap(([_, state]) => {
        const formData = state.settings.accountSettingsForm;
        const updateParams = new URLSearchParams({
            otp: formData.otpCode,
            email: formData.newEmail,
            use_password_auth: formData.usePasswordAuth.toString(),
        });

        if (formData.password) {
            updateParams.append('password', formData.password);
        }

        const url = `/api/users/nuid?${updateParams.toString()}`;

        return from(fetch(url, {
            method: 'PUT',
            headers: getAuthHeaders(),
        })).pipe(
            parseHttpError<{ user: User; summary_vehicles: VehicleData[]; summary_tesla_accounts: TeslaAccount[] }>(),
            mergeMap((response) => [
                updateAccountSettingsSuccess({
                    email: response.user.email,
                    use_password_auth: response.user.usePasswordAuth,
                }),
                resetAccountSettingsForm(),
                setAccountSettingStep(AccountSettingStep.StepOne)
            ]),
            catchError((error) => of(updateAccountSettingsFailure(error.message)))
        );
    })
);


const settingsEpic = combineEpics(
    updateAccountSettingsEpic,
)

export default settingsEpic;
import { ofType } from 'redux-observable';
import {catchError, map, mergeMap} from 'rxjs/operators';
import { of } from 'rxjs';
import { setAlertTimeStart, setAlertTimeSuccess, setAlertTimeFailure } from '../vehicleDataSlice';
import {AppEpic} from "./index";
import {updateVehicleAlertTime} from "../../components/services/api";

// Define the type for the SetAlertTimeStartAction
interface SetAlertTimeStartAction {
    type: typeof setAlertTimeStart.type;
    payload: { vehicleId: string; alertTime: number };
}

// The epic that listens for the setAlertTimeStart action
export const setAlertTimeEpic: AppEpic = (action$) =>
    action$.pipe(
        ofType(setAlertTimeStart.type),
        mergeMap((action: SetAlertTimeStartAction) => {
            const { vehicleId, alertTime } = action.payload;

            return updateVehicleAlertTime(vehicleId, alertTime).pipe(
                map(() => setAlertTimeSuccess()),
                catchError((error: Error) => of(setAlertTimeFailure(error.message)))
            );
        })
    );
import axios, { InternalAxiosRequestConfig } from 'axios';
import {from, Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {VehicleData} from "../../store/vehicleDataSlice";
import {getCSRFToken, getTokens} from "../../store/epics";

interface TokenData {
    access_token: string;
    token_type: string;
    expires_in: number;
    refresh_token: string;
    created_at: number;
}

export interface Device {
    id: number;
    user_id: number;
    device_identifier: string;
    platform: string;
    client_id: string;
    push_notification_token: string | null;
    device_name: string;
    notification_enabled: boolean;
    created_at: string;
    updated_at: string;
}

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

// Function to set tokens in localStorage
const setTokens = (tokens: TokenData) => {
    localStorage.setItem('tokens', JSON.stringify(tokens));
};

// Function to clear tokens from localStorage
const clearTokens = () => {
    localStorage.removeItem('tokens');
};

// Function to check if the access token is expired
const isTokenExpired = (tokens: TokenData): boolean => {
    const now = Math.floor(Date.now() / 1000);
    return now >= tokens.created_at + tokens.expires_in;
};

// Request interceptor for API calls
api.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
        const tokens = getTokens();
        if (tokens) {
            if (isTokenExpired(tokens)) {
                // Token is expired, attempt to refresh
                try {
                    const response = await axios.post('/api/oauth/token', {
                        grant_type: 'refresh_token',
                        refresh_token: tokens.refresh_token,
                    });
                    const newTokens: TokenData = response.data;
                    setTokens(newTokens);
                    config.headers.set('Authorization', `Bearer ${newTokens.access_token}`);
                } catch (error) {
                    // Refresh token is invalid or expired
                    clearTokens();
                    // Redirect to login or handle as needed
                }
            } else {
                config.headers.set('Authorization', `Bearer ${tokens.access_token}`);
            }
        }

        // Add CSRF token to headers
        const csrfToken = getCSRFToken();
        if (csrfToken) {
            config.headers.set('X-CSRF-Token', csrfToken);
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);



export const getCurrentUser = async () => {
    try {
        const response = await api.get('/api/users');
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const updateVehicleAlertTime = (vehicleId: string, alertTime: number): Observable<VehicleData> => {
    const url = `/api/vehicles/${vehicleId}`;
    const updateData = { setting_alert_min: alertTime };

    return from(api.put<VehicleData>(url, updateData)).pipe(
        map(response => response.data),
        catchError(error => throwError(() => new Error(error.response?.data?.error || 'Failed to update vehicle alert time')))
    );
};


// You can add more API calls here as needed

export default api;
import React from 'react';
import { Battery, Zap, Clock } from 'lucide-react';
import DashboardCard from './DashboardCard';

interface BatteryStatusProps {
    batteryPercentage: number | null;
    range: number | null;
    chargingStatus: 'charging' | 'not charging' | 'unknown';
    estimatedTimeToFull: number | null;
}

const BatteryStatus: React.FC<BatteryStatusProps> = ({
    batteryPercentage,
    range,
    chargingStatus,
    estimatedTimeToFull,
}) => {
    const getBatteryColor = (percentage: number | null) => {
        if (percentage === null) return 'text-gray-400';
        if (percentage <= 20) return 'text-red-500';
        if (percentage <= 50) return 'text-yellow-500';
        return 'text-green-500';
    };

    const getChargingStatusText = (status: string) => {
        switch (status) {
            case 'charging':
                return 'Charging in progress';
            case 'not charging':
                return 'Not charging';
            default:
                return 'Charging status unknown';
        }
    };

    const formatEstimatedTime = (minutes: number | null) => {
        if (minutes === null) return 'Unknown';
        if (minutes < 60) return `${minutes} minutes`;
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours} hour${hours > 1 ? 's' : ''}${remainingMinutes > 0 ? ` ${remainingMinutes} minutes` : ''}`;
    };

    const roundedPercentage = batteryPercentage !== null ? Math.round(batteryPercentage) : null;

    return (
        <DashboardCard title="Current Charging Status">
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                    <Battery className={`${getBatteryColor(roundedPercentage)} mr-2`} size={32} />
                    <span className={`text-4xl font-bold ${getBatteryColor(roundedPercentage)}`}>
                        {roundedPercentage !== null ? `${roundedPercentage}%` : 'Unknown'}
                    </span>
                </div>
                <span className="text-xl text-gray-600">
                    {range !== null ? `${range} miles` : 'Range unknown'}
                </span>
            </div>
            <div className="flex items-center text-gray-700 mb-4">
                <Zap className={`mr-2 ${chargingStatus === 'charging' ? 'text-green-500' : 'text-gray-400'}`} size={24} />
                <span className="text-lg">{getChargingStatusText(chargingStatus)}</span>
            </div>
            <div className="flex items-center text-gray-700">
                <Clock className="mr-2 text-blue-500" size={24} />
                <span className="text-lg">
                    {chargingStatus === 'charging'
                        ? `Estimated time to full: ${formatEstimatedTime(estimatedTimeToFull)}`
                        : 'Not currently charging'}
                </span>
            </div>
        </DashboardCard>
    );
};

export default BatteryStatus;

import React from 'react';
import { CheckCircle } from 'lucide-react';

interface SuccessAlertProps {
    message: string;
}

export const SuccessAlert: React.FC<SuccessAlertProps> = ({ message }) => {
    if (!message) return null;

    return (
        <div className="fixed bottom-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
            <div className="flex">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span className="block sm:inline">{message}</span>
            </div>
        </div>
    );
};
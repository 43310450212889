import React, { useState } from 'react';
import { ChevronDown, HelpCircle, ExternalLink, Lock, Zap, Clock, Car, Settings, BellRing, AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const FAQItem: React.FC<{ question: string; children: React.ReactNode }> = ({ question, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="bg-white/80 backdrop-blur-sm rounded-xl border border-gray-200 hover:border-teal-200 transition-all duration-300">
            <button
                className="flex items-start w-full text-left p-6 transition duration-300 hover:bg-teal-50/50 group"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
            >
                <div className="flex-1 pr-4">
                    <h3 className="text-lg font-semibold text-gray-900 group-hover:text-teal-600 transition-colors">
                        {question}
                    </h3>
                </div>
                <motion.div
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.2 }}
                    className="flex-shrink-0 w-8 h-8 rounded-full bg-teal-100 flex items-center justify-center group-hover:bg-teal-200 transition-colors mt-1"
                >
                    <ChevronDown className="w-5 h-5 text-teal-600" />
                </motion.div>
            </button>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                    >
                        <div className="px-6 pb-6">
                            <div className="text-gray-600 leading-relaxed">
                                {children}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export const FAQSection: React.FC = () => {
    return (
        <section className="py-20 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-teal-50/50 to-cyan-50/50" />

            <div className="max-w-3xl mx-auto px-4 relative">
                <div className="text-center mb-12">
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                        Frequently Asked Questions
                    </h2>
                    <p className="text-xl text-gray-600">
                        Find quick answers about EVAlarm
                    </p>
                </div>

                <div className="space-y-4">
                    <FAQItem question="How is EVAlarm different from Tesla's built-in notifications?">
                        While Tesla's app sends a single notification when charging completes, EVAlarm persistently reminds you until you physically disconnect the cable. Our notifications are impossible to miss - they'll keep alerting you at customizable intervals until you confirm disconnection. This persistent approach ensures you never accidentally overstay and incur fees.
                    </FAQItem>

                    <FAQItem question="Why choose EVAlarm over generic charging apps?">
                        EVAlarm is specifically designed for Tesla owners with native iOS integration. Unlike generic charging apps that might send one-time alerts, we provide persistent notifications and real-time monitoring. Plus, we're developing advanced features like adaptive charging rate control to optimize both cost savings and battery health.
                    </FAQItem>

                    <FAQItem question="What upcoming features are planned?">
                        <p className="mb-4">We're developing several exciting features:</p>
                        <div className="space-y-3">
                            <div className="flex items-start">
                                <span className="flex-shrink-0 w-6 h-6 rounded-full bg-teal-100 flex items-center justify-center text-sm font-medium text-teal-700 mr-3">1</span>
                                <span>Smart Charge Rate Control - automatically adjusts charging speed to avoid overstay fees while maintaining optimal battery health</span>
                            </div>
                            <div className="flex items-start">
                                <span className="flex-shrink-0 w-6 h-6 rounded-full bg-teal-100 flex items-center justify-center text-sm font-medium text-teal-700 mr-3">2</span>
                                <span>Battery Health Optimization - learns your charging patterns to suggest optimal charging schedules</span>
                            </div>
                            <div className="flex items-start">
                                <span className="flex-shrink-0 w-6 h-6 rounded-full bg-teal-100 flex items-center justify-center text-sm font-medium text-teal-700 mr-3">3</span>
                                <span>Enhanced Analytics - detailed insights into your charging costs and potential savings</span>
                            </div>
                        </div>
                    </FAQItem>

                    <FAQItem question="How does EVAlarm ensure I never get overstay fees?">
                        <p className="mb-4">We use a three-layer protection system:</p>
                        <div className="space-y-3">
                            <div className="flex items-start">
                                <span className="flex-shrink-0 w-6 h-6 rounded-full bg-teal-100 flex items-center justify-center text-sm font-medium text-teal-700 mr-3">1</span>
                                <span>Early warnings before charging completion</span>
                            </div>
                            <div className="flex items-start">
                                <span className="flex-shrink-0 w-6 h-6 rounded-full bg-teal-100 flex items-center justify-center text-sm font-medium text-teal-700 mr-3">2</span>
                                <span>Persistent notifications that cannot be accidentally dismissed</span>
                            </div>
                            <div className="flex items-start">
                                <span className="flex-shrink-0 w-6 h-6 rounded-full bg-teal-100 flex items-center justify-center text-sm font-medium text-teal-700 mr-3">3</span>
                                <span>Real-time monitoring of charging status</span>
                            </div>
                        </div>
                        <p className="mt-4">You'll keep getting reminded until you physically disconnect - making it virtually impossible to forget your car at a charger.</p>
                    </FAQItem>

                    <FAQItem question="Is EVAlarm secure with my Tesla account?">
                        Absolutely. We use industry-standard encryption and secure authentication through Tesla's official API. We never store your Tesla credentials and use secure tokens for checking charging status. Your privacy and security are our top priorities.
                    </FAQItem>

                    <FAQItem question="What are the current limitations of EVAlarm?">
                        EVAlarm requires your Tesla to be online for real-time monitoring. If your vehicle goes offline during charging, you'll receive a notification about limited monitoring, and you can set manual reminders as a backup. We're actively developing offline detection capabilities through Bluetooth connectivity to address this limitation.
                    </FAQItem>

                    <FAQItem question="Which Tesla models are supported?">
                        EVAlarm works with all current Tesla models including Model S, Model 3, Model X, Model Y, and Cybertruck. As Tesla releases new models, we'll support those too. Our advanced features like charging rate optimization are customized for each specific model's charging characteristics.
                    </FAQItem>
                </div>

                <div className="mt-12 text-center">
                    <div className="inline-flex items-center justify-center p-6 bg-gradient-to-r from-teal-50 to-cyan-50 rounded-2xl">
                        <div className="flex items-center gap-3">
                            <HelpCircle className="w-6 h-6 text-teal-600" />
                            <div className="text-left">
                                <p className="text-sm font-medium text-gray-600 mb-1">
                                    Still have questions?
                                </p>
                                <Link
                                    to="/help"
                                    className="inline-flex items-center text-teal-600 hover:text-teal-700 font-medium group"
                                >
                                    Visit our Help Center
                                    <ExternalLink className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

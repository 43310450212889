import React, { useEffect, useState } from 'react';
import { Smartphone, Bell, BellOff, Send, Unlink, Info } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import {
    fetchDevicesStart,
    toggleNotificationsStart,
    unlinkDeviceStart,
    sendTestMessageStart
} from '../../store/devicesSlice';
import { Device } from "../services/api";

const DeviceManagement: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { list: devices, loading, error } = useSelector((state: RootState) => state.devices);
    const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);

    useEffect(() => {
        dispatch(fetchDevicesStart());
    }, [dispatch]);

    const handleToggleNotifications = (deviceId: string, notificationEnabled: boolean) => {
        dispatch(toggleNotificationsStart({
            device_identifier: deviceId,
            notification_enabled: notificationEnabled
        }));
        setMessage({ type: 'success', text: 'Updating notification settings...' });
    };

    const handleUnlinkDevice = (deviceIdentifier: string) => {
        dispatch(unlinkDeviceStart(deviceIdentifier));
        setMessage({ type: 'success', text: 'Unlinking device...' });
    };

    const handleSendTestMessage = (id: number) => {
        dispatch(sendTestMessageStart(id));
        setMessage({ type: 'success', text: 'Sending test message...' });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-red-600">Error: {error}</div>;
    }

    return (
        <div className="space-y-6">
            <h2 className="text-2xl font-semibold">Device Management</h2>

            {message && (
                <div className={`p-4 rounded-md ${message.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
                    {message.text}
                </div>
            )}

            <div className="bg-blue-100 text-blue-700 p-4 rounded-md flex items-start">
                <Info className="mr-2 mt-1 flex-shrink-0" size={20} />
                <div>
                    <p className="font-semibold">How device linking works:</p>
                    <p>Devices are automatically linked when you sign in on different devices. Once linked, they will appear here for management.</p>
                </div>
            </div>

            {devices.length === 0 ? (
                <p className="text-gray-600 italic">No devices are currently linked to your account.</p>
            ) : (
                <ul className="space-y-4">
                    {devices.map((device: Device) => (
                        <li key={device.id} className="bg-white p-4 rounded-lg shadow">
                            <div className="flex items-center justify-between mb-4">
                                <div className="flex items-center">
                                    <Smartphone className="mr-2" size={20} />
                                    <span className="font-medium">{device.device_name}</span>
                                </div>
                                <button
                                    onClick={() => handleToggleNotifications(device.device_identifier, !device.notification_enabled)}
                                    className={`p-2 rounded-full ${device.notification_enabled ? 'bg-teal-100 text-teal-600' : 'bg-gray-100 text-gray-600'}`}
                                >
                                    {device.notification_enabled ? <Bell size={20} /> : <BellOff size={20} />}
                                </button>
                            </div>
                            <div className="flex space-x-2">
                                <button
                                    onClick={() => handleSendTestMessage(device.id)}
                                    className="bg-teal-600 text-white px-3 py-1 rounded-md hover:bg-teal-700 transition-colors duration-200 flex items-center"
                                >
                                    <Send size={16} className="mr-1" />
                                    Send Test Message
                                </button>
                                <button
                                    onClick={() => handleUnlinkDevice(device.device_identifier)}
                                    className="bg-red-600 text-white px-3 py-1 rounded-md hover:bg-red-700 transition-colors duration-200 flex items-center"
                                >
                                    <Unlink size={16} className="mr-1" />
                                    Unlink Device
                                </button>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default DeviceManagement;
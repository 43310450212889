import { BlogPostProps } from "./BlogCard";
import TeslaChargingAnalysis from "./BlogPost/001-ChargingAnalysisPost";
import OverstayFeeOverviewPost from "./BlogPost/002-OverstayFeeOverviewPost";

export interface Author {
  name: string;
  avatar?: string;
  bio?: string;
}

export interface BlogPostMetadata {
  id: string;
  title: string;
  slug: string;
  description: string;
  author: Author;
  date: string;
  readingTime: number;
  tags: string[];
  coverImage?: string;
  featured?: boolean;
}

interface BlogPostEntry {
  Component: React.FC<BlogPostProps>;
  metadata: BlogPostMetadata;
}

export const blogPosts: BlogPostEntry[] = [
  {
    Component: OverstayFeeOverviewPost,
    metadata: {
      id: '002',
      title: 'Understanding Overstay Fees for EV Charging Stations: What Tesla Drivers Should Know',
      slug: "overstay-fee-overview",
      description: "A comprehensive guide to EV charging overstay fees, comparing Tesla Supercharger and third-party network policies. Learn how to avoid fees, understand grace periods, and maximize charging efficiency.",
      author: {
        name: "Ken Li",
        avatar: undefined,
        bio: "EV Enthusiast & Software Engineer"
      },
      date: "2024-11-08",
      readingTime: 12,
      tags: [
        "overstay-fees",
        "tesla",
        "supercharging",
        "third-party-chargers",
        "ev-charging",
        "charging-costs",
        "electrify-america",
        "evgo",
        "charging-tips",
        "tesla-app",
        "charging-etiquette"
      ],
      featured: true
    }
  },
  {
    Component: TeslaChargingAnalysis,
    metadata: {
      id: '001',
      title: 'Tesla Charging Tips: Maximizing Efficiency and Battery Life',
      slug: "tesla-charging-tips",
      description: "Learn the science behind Tesla's charging system and how to optimize your charging routine for better efficiency and battery longevity.",
      author: {
        name: "Ken Li",
        avatar: undefined,
        bio: "EV Enthusiast & Software Engineer"
      },
      date: "2024-11-08",
      readingTime: 10,
      tags: ["charging-tips", "tesla", "battery-health", "supercharging"],
      featured: false
    }
  },
];

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { User, ChevronDown, Settings, LogOut, Download } from 'lucide-react';
import LogoIcon from './LogoIcon';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { signOutRequest } from "../../store/userSlice";

const Header: React.FC = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogout = () => {
        dispatch(signOutRequest());
        setIsDropdownOpen(false);
    };

    return (
        <header className="bg-white shadow-sm sticky top-0 z-50">
            <div className="container mx-auto px-6 py-4 flex justify-between items-center">
                <div className="flex items-center space-x-4">
                    <Link to="/" className="flex items-center space-x-2">
                        <LogoIcon size={36} />
                        <span className="text-2xl font-bold text-teal-600">EVAlarm</span>
                    </Link>
                    <span className="text-sm text-gray-600 italic hidden sm:inline">Say Goodbye to Overstays</span>
                </div>
                {isAuthenticated && (
                    <div className="flex items-center space-x-4">
                        <Link
                            to="/download"
                            className="flex items-center text-gray-600 hover:text-teal-600 transition-colors duration-200"
                        >
                            <Download size={24} className="mr-1" />
                            <span className="hidden sm:inline">Download Client</span>
                        </Link>
                        <div className="relative" ref={dropdownRef}>
                            <button
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                className="flex items-center text-gray-600 hover:text-teal-600 transition-colors duration-200"
                            >
                                <User size={24} />
                                <ChevronDown size={20} className="ml-1" />
                            </button>
                            {isDropdownOpen && (
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                                    <Link
                                        to="/settings"
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-teal-100 flex items-center"
                                        onClick={() => setIsDropdownOpen(false)}
                                    >
                                        <Settings size={18} className="mr-2" />
                                        Settings
                                    </Link>
                                    <button
                                        onClick={handleLogout}
                                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-teal-100 flex items-center"
                                    >
                                        <LogOut size={18} className="mr-2" />
                                        Logout
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;
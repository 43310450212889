import React from 'react';

interface DashboardCardProps {
    title: string;
    children: React.ReactNode;
    className?: string;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, children, className }) => (
    <div className={`bg-white rounded-2xl shadow-lg p-6 ${className}`}>
        <h3 className="text-lg font-semibold mb-4 text-gray-800">{title}</h3>
        {children}
    </div>
);

export default DashboardCard;
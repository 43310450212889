import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { filter, switchMap, map, catchError } from 'rxjs/operators';
import {
    fetchTicketsRequest,
    fetchTicketsSuccess,
    fetchTicketsFailure,
    fetchTicketRequest,
    fetchTicketSuccess,
    fetchTicketFailure,
    createTicketRequest,
    createTicketSuccess,
    createTicketFailure,
    HelpdeskTicket, createCommentSuccess, createCommentFailure, createCommentRequest,
} from '../helpdeskSlice';

import {AppEpic, getAuthHeaders} from './index'; // Adjust the import path as needed

const fetchTicketsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchTicketsRequest.match),
        switchMap(() =>
            from(fetch('/api/helpdesk_tickets', { headers: getAuthHeaders() })).pipe(
                switchMap((response) => {
                    if (!response.ok) throw new Error('Failed to fetch tickets');
                    return response.json();
                }),
                map((tickets: HelpdeskTicket[]) => fetchTicketsSuccess(tickets)),
                catchError((error) => of(fetchTicketsFailure(error.message)))
            )
        )
    );

const fetchTicketEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchTicketRequest.match),
        switchMap((action) =>
            from(fetch(`/api/helpdesk_tickets/${action.payload}`, { headers: getAuthHeaders() })).pipe(
                switchMap((response) => {
                    if (!response.ok) throw new Error('Failed to fetch ticket');
                    return response.json();
                }),
                map((ticket: HelpdeskTicket) => fetchTicketSuccess(ticket)),
                catchError((error) => of(fetchTicketFailure(error.message)))
            )
        )
    );

const createTicketEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(createTicketRequest.match),

        switchMap((action) =>
            from(fetch('/api/helpdesk_tickets', {
                method: 'POST',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ticket: action.payload }),
            })).pipe(
                switchMap((response) => {
                    if (!response.ok) throw new Error('Failed to create ticket');
                    return response.json();
                }),
                map((ticket: HelpdeskTicket) => createTicketSuccess(ticket)),
                catchError((error) => of(createTicketFailure(error.message)))
            )
        )
    );

const createCommentEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(createCommentRequest.match),
        switchMap((action) =>
            from(fetch(`/api/helpdesk_tickets/${action.payload.ticketId}/comments`, {
                method: 'POST',
                headers: {
                    ...getAuthHeaders(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ body: action.payload.body }),
            })).pipe(
                switchMap((response) => {
                    if (!response.ok) throw new Error('Failed to create comment');
                    return response.json();
                }),
                map((updatedTicket: HelpdeskTicket) => createCommentSuccess(updatedTicket)),
                catchError((error) => of(createCommentFailure(error.message)))
            )
        )
    );

const helpdeskEpic = combineEpics(
    fetchTicketsEpic,
    fetchTicketEpic,
    createTicketEpic,
    createCommentEpic
);

export default helpdeskEpic
import { Link } from 'react-router-dom';
import { Shield, Zap, ArrowRight, Lock, Database, Award, Clock, CheckCircle2 } from 'lucide-react';
import AppPreview from './AppPreview';

const HeroSection = () => (
  <section className="relative overflow-hidden min-h-[90vh] flex items-center">
    <div className="absolute inset-0 bg-gradient-to-br from-teal-50/50 via-cyan-50/30 to-white" />
    <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-to-br from-teal-400/20 to-cyan-400/20 blur-3xl transform rotate-12 animate-pulse" />

    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 md:py-32 relative">
      <div className="flex flex-col md:flex-row items-center justify-between gap-12">
        <div className="md:w-1/2">
          {/* Beta Badge */}
          <div className="bg-amber-100 text-amber-800 px-4 py-1 rounded-full inline-block mb-6 text-sm font-medium">
            🚀 Limited Time Beta Access
          </div>

          {/* Problem-solution statement */}
          <h1 className="text-5xl md:text-6xl font-bold mb-6 leading-tight">
            Never miss a
            <span className="relative inline-block mx-3">
              <span className="relative z-10 bg-gradient-to-r from-teal-600 to-cyan-600 bg-clip-text text-transparent">
                charging
              </span>
              <div className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-teal-600/30 to-cyan-600/30 rounded-full" />
            </span>
            deadline
          </h1>

          {/* Subheading focusing on the problem */}
          <p className="text-xl text-gray-700 mb-8">
            Stop paying $200 overstay fees at workplace chargers and Superchargers. Get alerts before fees kick in.
          </p>

          {/* Security & Privacy Trust Box */}
          <div className="bg-white rounded-xl p-6 shadow-lg mb-8 border border-teal-100">
            <h3 className="text-lg font-semibold text-gray-900 mb-4">Built for Security & Privacy</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-start gap-3">
                <div className="p-2 bg-teal-50 rounded-lg">
                  <Lock className="w-5 h-5 text-teal-600" />
                </div>
                <div>
                  <p className="font-medium text-sm">Official Tesla API</p>
                  <p className="text-xs text-gray-600">Secure authentication</p>
                </div>
              </div>
              <div className="flex items-start gap-3">
                <div className="p-2 bg-teal-50 rounded-lg">
                  <Database className="w-5 h-5 text-teal-600" />
                </div>
                <div>
                  <p className="font-medium text-sm">Data Privacy</p>
                  <p className="text-xs text-gray-600">No location tracking</p>
                </div>
              </div>
            </div>
          </div>

          {/* Risk-Free Guarantee Box */}
          <div className="bg-gradient-to-br from-teal-50 to-cyan-50 rounded-xl p-6 shadow-lg mb-8 border border-teal-100">
            <div className="flex items-center gap-2 mb-4">
              <Shield className="w-6 h-6 text-teal-600" />
              <h3 className="text-lg font-semibold text-gray-900">Risk-Free Guarantee</h3>
            </div>
            <div className="space-y-3">
              <div className="flex items-center gap-2">
                <Clock className="w-5 h-5 text-teal-600" />
                <span className="text-gray-700">60-day full refund period</span>
              </div>
              <div className="flex items-center gap-2">
                <CheckCircle2 className="w-5 h-5 text-teal-600" />
                <span className="text-gray-700">Guaranteed lifetime access</span>
              </div>
              <div className="flex items-center gap-2">
                <Award className="w-5 h-5 text-teal-600" />
                <span className="text-gray-700">Priority email support</span>
              </div>
            </div>
          </div>

          {/* Beta Launch Offer */}
          <div className="bg-white/80 backdrop-blur rounded-xl p-6 mb-8 border-2 border-teal-100">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h3 className="text-xl font-semibold text-gray-900">Beta Launch Offer</h3>
                <p className="text-gray-600">Limited time early-access pricing</p>
              </div>
              <div className="text-right">
                <div className="text-gray-500 line-through">$499</div>
                <div className="text-3xl font-bold text-teal-600">$299</div>
              </div>
            </div>
          </div>

          {/* CTA section */}
          <div className="flex flex-col sm:flex-row gap-4 mb-8">
            <Link
              to="/download"
              className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-teal-600 to-teal-500 text-white rounded-full text-lg font-medium hover:from-teal-500 hover:to-teal-400 transition-all duration-300 shadow-lg hover:shadow-xl group"
            >
              Download App
              <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
            </Link>
            <Link
              to="/signup"
              className="inline-flex items-center justify-center px-8 py-4 bg-white border-2 border-teal-600 text-teal-600 rounded-full text-lg font-medium hover:bg-teal-50 transition-all duration-300 group"
            >
              Create Account
              <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>

          {/* Device notice */}
          <div className="p-4 bg-amber-50 border border-amber-200 rounded-lg">
            <p className="text-amber-800 text-sm">
              ⚠️ Currently available for iPhone only. Android version in development - {' '}
              <a href="/presign-up" className="text-amber-900 font-medium underline hover:text-amber-700 transition-colors">
                join the waitlist
              </a>.
            </p>
          </div>
        </div>

        <div className="md:w-1/2 relative">
          <AppPreview />
        </div>
      </div>
    </div>
  </section>
);

export default HeroSection;

import React from 'react';
import { ChevronDown } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store'; // Adjust the import path as needed
import { selectVehicle } from '../../../store/vehicleDataSlice'; // Adjust the import path as needed

const VehicleSelector: React.FC = () => {
    const dispatch = useDispatch();
    const { vehicles, selectedVehicleId } = useSelector((state: RootState) => state.vehicleData);

    const handleSelectVehicle = (id: string) => {
        dispatch(selectVehicle(id));
    };

    return (
        <div className="mb-6 relative">
            <select
                value={selectedVehicleId || ''}
                onChange={(e) => handleSelectVehicle(e.target.value)}
                className="appearance-none bg-white border border-gray-300 rounded-lg py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-teal-500 text-gray-700 w-full"
            >
                <option value="">All Vehicles</option>
                {vehicles.map((vehicle) => (
                    <option key={vehicle.id} value={vehicle.id}>
                        {vehicle.name}
                    </option>
                ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <ChevronDown size={20} />
            </div>
        </div>
    );
};

export default VehicleSelector;
import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from "./userSlice";
import { BillingFrequency } from './subscriptionSlice';

// Types
export interface RegistrationState {
    sessionId: string | null;
    step: StepType | null;
    localStep: LocalStepType | null;
    email: string | null;
    planId: string | null;
    billingFrequency: 'monthly' | 'yearly' | null;
    clientSecret: string | null;
    paymentIntentId: string | null;
    totalAmount: number | null;
    totalAmountDecimal: string | null;
    currency: string | null;
    createdAt: number | null;
    updatedAt: number | null;
    loading: boolean;
    error: string | null;
}

const initialState: RegistrationState = {
    sessionId: null,
    step: null,
    localStep: null,
    email: null,
    planId: null,
    billingFrequency: null,
    clientSecret: null,
    paymentIntentId: null,
    totalAmount: null,
    totalAmountDecimal: null,
    currency: null,
    createdAt: null,
    updatedAt: null,
    loading: false,
    error: null,
};

export interface UpdateRegistrationSessionPayload {
    sessionId: string;
    email?: string;
    password?: string;
    planId?: string;
    billingFrequency?: BillingFrequency;
    step?: StepType;
}

export type StepType = 'initiated' | 'personal_info' | 'plan_selection' | 'payment' | 'confirmation' | 'completed';
export type LocalStepType = StepType | 'payment_processing';

// Action Creators
export const createRegistrationSessionRequest = createAction('registration/createSessionRequest');
export const createRegistrationSessionSuccess = createAction<Partial<RegistrationState>>('registration/createSessionSuccess');
export const createRegistrationSessionFailure = createAction<string>('registration/createSessionFailure');

export const updateLocalRegistrationSession = createAction<Partial<RegistrationState>>('registration/updateLocalSession');

export const updateRegistrationSessionRequest = createAction<UpdateRegistrationSessionPayload>('registration/updateSessionRequest');
export const updateRegistrationSessionSuccess = createAction<Partial<RegistrationState>>('registration/updateSessionSuccess');
export const updateRegistrationSessionFailure = createAction<string>('registration/updateSessionFailure');

export const getRegistrationSessionRequest = createAction<string>('registration/getSessionRequest');
export const getRegistrationSessionSuccess = createAction<RegistrationState>('registration/getSessionSuccess');
export const getRegistrationSessionFailure = createAction<string>('registration/getSessionFailure');

export const checkRegistrationStatusRequest = createAction<string>('registration/checkStatusRequest');
export const checkRegistrationStatusSuccess = createAction<{
    status: 'completed' | string;
    user: User;
    accessToken?: string;
    refreshToken?: string;
    expiresIn?: number;
}>('registration/checkStatusSuccess');
export const checkRegistrationStatusFailure = createAction<string>('registration/checkStatusFailure');


// Helper function to handle session updates
const updateRegistrationState = (state: RegistrationState, payload: Partial<RegistrationState>) => {
    Object.keys(payload).forEach((key) => {
        if (payload[key as keyof RegistrationState] !== undefined) {
            (state as any)[key] = payload[key as keyof RegistrationState];
        }
    });
};

// Slice
export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        clearRegistrationError: (state) => {
            state.error = null;
        },
        resetRegistrationState: () => initialState,
        setLocalStep: (state, action: PayloadAction<LocalStepType>) => {
            state.localStep = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createRegistrationSessionSuccess, (state, action) => {
                updateRegistrationState(state, action.payload);
                state.localStep = state.step;
                state.loading = false;
                state.error = null;
            })
            .addCase(updateRegistrationSessionSuccess, (state, action) => {
                updateRegistrationState(state, action.payload);
                state.localStep = state.step;
                state.loading = false;
                state.error = null;
            })
            .addCase(getRegistrationSessionSuccess, (state, action) => {
                const newState = { ...state, ...action.payload, loading: false, error: null };
                newState.localStep = newState.step;
                return newState;
            })
            .addCase(createRegistrationSessionFailure, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateRegistrationSessionRequest, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateRegistrationSessionFailure, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(getRegistrationSessionRequest, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getRegistrationSessionFailure, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(updateLocalRegistrationSession, (state, action) => {
                updateRegistrationState(state, action.payload);
            });
    },
});

// Export actions
export const {
    clearRegistrationError,
    resetRegistrationState,
    setLocalStep,
} = registrationSlice.actions;

export default registrationSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BillingFrequency } from './subscriptionSlice';

export const LIFETIME_PLAN_ID: string = 'lifetime.premium';

export interface Plan {
    id: string;
    name: string;
    description: string;
    monthly_price: number;
    yearly_price: number;
    max_vehicles: number;
    max_devices: number;
    features: string[];
}

export interface LifetimePlanResponse {
    lifetime_enabled: boolean;
    plan: Plan;
}

export interface PlansState {
    plans: Plan[];
    billingCycle: BillingFrequency;
    lifetimePlan: Plan | null;
    lifetimeEnabled: boolean;
    loading: boolean;
    error: string | null;
}

function getPlansFromMetaTag(): Plan[] {
    const plansMeta = document.querySelector('meta[name="plans-data"]');
    if (plansMeta && plansMeta.getAttribute('content')) {
        return JSON.parse(plansMeta.getAttribute('content')!);
    }
    return [];
}

const initialState: PlansState = {
    plans: getPlansFromMetaTag(),
    billingCycle: BillingFrequency.Monthly,
    lifetimePlan: null,
    lifetimeEnabled: false,
    loading: false,
    error: null,
};

const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        setBillingCycle(state, action: PayloadAction<BillingFrequency>) {
            state.billingCycle = action.payload;
        },
        fetchLifetimePlanRequest(state) {
            state.loading = true;
            state.error = null;
        },
        fetchLifetimePlanSuccess(state, action: PayloadAction<LifetimePlanResponse>) {
            state.loading = false;
            state.lifetimePlan = action.payload.plan;
            state.lifetimeEnabled = action.payload.lifetime_enabled;
            state.error = null;
        },
        fetchLifetimePlanFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

// Export actions
export const {
    setBillingCycle,
    fetchLifetimePlanRequest,
    fetchLifetimePlanSuccess,
    fetchLifetimePlanFailure,
} = plansSlice.actions;

export default plansSlice.reducer;

import { PersistGate } from 'redux-persist/integration/react'
import React, { useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import store, { persistor } from '../store/index';
import { initializeApp } from "../store/userSlice";
import AppRoutes from './AppRoutes';
import { useLocation } from 'react-router-dom';

interface AppProps {
    message: string;
    // Add other props as needed
}


const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return null;
};


const AppContent: React.FC<AppProps> = ({ message, ...otherProps }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(initializeApp());
    }, [dispatch]);

    return (
        <AppRoutes message={message} {...otherProps} />
    );
};

const App: React.FC<AppProps> = (props) => {
    return (
        <Provider store={store}>
            <ScrollToTop />
            <PersistGate loading={null} persistor={persistor}>
                <AppContent {...props} />
            </PersistGate>
        </Provider>
    );
};

export default App;

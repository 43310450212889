import React from 'react';
import { Link } from 'react-router-dom';
import {
    Shield,
    Check,
    LinkIcon,
    ArrowRight,
    RefreshCcw,
    Phone,
} from 'lucide-react';
import EVAlarmLayout from "../layout/EVAlarmLayout";
import AppScreenshotShowcase from './Landing/AppScreenshotShowcase'; // Add this import
import CostComparisonSection from "./Landing/CostComparisonSection";
import StatsSection from "./Landing/StatsSection";
import { FAQSection } from "./Landing/FAQSection";
import { motion } from 'framer-motion';
import HeroSection from './Landing/HeroSection';


const ProcessStep = ({ icon, title, description, index }: {
    icon: React.ReactNode;
    title: string;
    description: string;
    index: number;
}) => (
    <motion.div
        className="relative"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: index * 0.1 }}
    >
        <div className="bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 relative z-10">
            <div className="absolute -top-4 -left-4 w-8 h-8 bg-teal-600 text-white rounded-full flex items-center justify-center font-bold">
                {index + 1}
            </div>
            <div className="bg-teal-50 rounded-xl w-16 h-16 flex items-center justify-center mb-6">
                <div className="text-teal-600">
                    {icon}
                </div>
            </div>
            <h3 className="text-xl font-semibold text-gray-900 mb-4">{title}</h3>
            <p className="text-gray-600 leading-relaxed">{description}</p>
        </div>
        {index < 2 && (
            <div className="hidden md:block absolute top-1/2 left-full w-8 h-0.5 bg-teal-200 transform -translate-y-1/2">
                <div className="absolute right-0 -top-1.5 w-3 h-3 bg-teal-200 rounded-full" />
            </div>
        )}
    </motion.div>
);

const HowItWorksSection = () => (
    <section id="how-it-works" className="py-20 md:py-32 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-br from-teal-50 to-cyan-50" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(20,184,166,0.1),transparent)]" />

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
            <div className="text-center mb-16">
                <motion.h2
                    className="text-4xl font-bold text-gray-900 mb-6"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    How to Setup <span className="text-teal-600">EVAlarm</span>
                </motion.h2>
                <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                    Three simple steps to never worry about overstay fees again
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-12 relative">
                <ProcessStep
                    icon={<LinkIcon size={32} />}
                    title="Connect Your Tesla"
                    description="Securely link your Tesla account using official Tesla authentication. We never store your credentials."
                    index={0}
                />
                <ProcessStep
                    icon={<RefreshCcw size={32} />}
                    title="Auto-Sync"
                    description="EVAlarm monitors your charging sessions in real-time, with no manual setup required."
                    index={1}
                />
                <ProcessStep
                    icon={<Phone size={32} />}
                    title="Get Notified"
                    description="Receive persistent alerts before charging completes. We'll keep reminding you until you disconnect."
                    index={2}
                />
            </div>

            <div className="mt-16 text-center">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">
                    Ready to avoid overstay fees?
                </h3>
                <Link
                    to="/signup"
                    className="inline-flex items-center justify-center px-8 py-4 bg-teal-600 text-white rounded-full text-lg font-medium hover:bg-teal-700 transition-all duration-300 group"
                >
                    Sign Up Now
                    <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                </Link>
            </div>
        </div>
    </section>
);



// Revised StayInformedSection with privacy focus
const CTASection = () => (
    <section className="relative overflow-hidden py-20 md:py-32">
        <div className="absolute inset-0 bg-gradient-to-br from-teal-600 to-cyan-600" />
        <div className="absolute inset-0 bg-grid-white/10" />

        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
            {/* Main heading and value proposition */}
            <div className="max-w-2xl mx-auto mb-12">
                <h2 className="text-4xl font-bold text-white mb-4">
                    Stop Paying <span className="text-teal-200">Overstay Fees</span>
                </h2>
                <p className="text-xl text-teal-100">
                    Prevent just two overstay incidents and EVAlarm pays for itself
                </p>
            </div>

            {/* Simplified pricing comparison */}
            <div className="bg-white/10 backdrop-blur-sm rounded-xl p-8 mb-12 max-w-xl mx-auto">
                <div className="flex justify-center items-center gap-12">
                    <div>
                        <h3 className="text-teal-200 font-medium mb-1">Single Overstay Fee</h3>
                        <p className="text-3xl font-bold text-white">Up to $200</p>
                    </div>
                    <div className="text-teal-200 text-4xl font-light">vs</div>
                    <div>
                        <h3 className="text-teal-200 font-medium mb-1">Lifetime Access</h3>
                        <p className="text-3xl font-bold text-white">$299</p>
                    </div>
                </div>
            </div>

            {/* CTA buttons and device notice */}
            <div className="max-w-md mx-auto">
                <div className="space-y-4">
                    <Link
                        to="/download"
                        className="w-full inline-flex items-center justify-center px-8 py-4 bg-white text-teal-600 rounded-full text-lg font-medium hover:bg-teal-50 transition-all duration-300 transform hover:scale-105 shadow-xl hover:shadow-2xl group"
                    >
                        Download for iPhone
                        <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                    </Link>

                    <Link
                        to="/signup"
                        className="w-full inline-flex items-center justify-center px-8 py-4 bg-teal-700/50 text-white rounded-full text-lg font-medium hover:bg-teal-700/70 transition-all duration-300 group backdrop-blur-sm"
                    >
                        Create Account
                        <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                    </Link>
                </div>

                {/* Device notice */}
                <div className="mt-6 text-teal-100 text-sm">
                    Currently available for iPhone only.{' '}
                    <a
                        href="/presign-up"
                        className="text-teal-200 underline hover:text-white transition-colors"
                    >
                        Join Android waitlist →
                    </a>
                </div>

                {/* Trust badges */}
                <div className="mt-8 flex justify-center items-center gap-6 text-sm">
                    <div className="flex items-center text-teal-100">
                        <Shield className="w-4 h-4 mr-2" />
                        <span>60-day guarantee</span>
                    </div>
                    <div className="flex items-center text-teal-100">
                        <Check className="w-4 h-4 mr-2" />
                        <span>Lifetime access</span>
                    </div>
                </div>
            </div>

            {/* Optional: Key benefits in a more subtle way */}
            <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-6 max-w-3xl mx-auto text-sm">
                <div className="bg-white/5 rounded-lg p-4">
                    <h4 className="text-teal-200 font-medium mb-1">All Future Updates</h4>
                    <p className="text-teal-100">Access every new feature we release</p>
                </div>
                <div className="bg-white/5 rounded-lg p-4">
                    <h4 className="text-teal-200 font-medium mb-1">No Monthly Fees</h4>
                    <p className="text-teal-100">One payment for permanent protection</p>
                </div>
                <div className="bg-white/5 rounded-lg p-4">
                    <h4 className="text-teal-200 font-medium mb-1">Risk-Free Trial</h4>
                    <p className="text-teal-100">60 days to test with full refund</p>
                </div>
            </div>
        </div>
    </section>
);

const EVAlarmLanding: React.FC = () => {
    return (
        <EVAlarmLayout>
            {/* Hero section with focused messaging */}
            <HeroSection />

            <AppScreenshotShowcase />

            <StatsSection />

            {/* How It Works Section */}
            <HowItWorksSection />

            <CostComparisonSection />

            {/* FAQ Section */}
            <FAQSection />

            {/* CTA Section */}
            <CTASection />
        </EVAlarmLayout>
    );
};

export default EVAlarmLanding;

import React from 'react';

const LogoIcon = ({ size = 48 }) => {
  // Get the asset path from meta tag
  const logoPath = document.querySelector('meta[name="logo-icon-path"]')?.getAttribute('content') || '/images/logo_transparent_icon.png';

  return (
    <img 
      src={logoPath}
      alt="EVAlarm Logo" 
      width={size} 
      height={size}
      style={{ objectFit: 'contain' }}
    />
  );
};

export default LogoIcon;

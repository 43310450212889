import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum TicketStatus {
    OPEN = 'ticket_open',
    IN_PROGRESS = 'ticket_in_progress',
    CLOSED = 'ticket_closed'
}

export interface Comment {
    id: number;
    body: string;
    author: string;
    created_at: number;
}

export interface Attachment {
    id: number;
    filename: string;
    content_type: string;
    byte_size: number;
    created_at: number;
}

export interface HelpdeskTicket {
    id: string;
    title: string;
    description: string;
    status: TicketStatus;
    requester_email: string;
    created_at: number;
    updated_at: number;
    comments: Comment[];
    attachments: Attachment[];
}

interface HelpdeskState {
    tickets: HelpdeskTicket[];
    currentTicket: HelpdeskTicket | null;
    loading: boolean;
    error: string | null;
}

const initialState: HelpdeskState = {
    tickets: [],
    currentTicket: null,
    loading: false,
    error: null,
};

export function getHumanReadableStatus(status: TicketStatus): string {
    switch (status) {
        case TicketStatus.OPEN:
            return 'Open';
        case TicketStatus.IN_PROGRESS:
            return 'In Progress';
        case TicketStatus.CLOSED:
            return 'Closed';
        default:
            return 'Unknown';
    }
}

export const helpdeskSlice = createSlice({
    name: 'helpdesk',
    initialState,
    reducers: {
        fetchTicketsRequest: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchTicketsSuccess: (state, action: PayloadAction<HelpdeskTicket[]>) => {
            state.tickets = action.payload;
            state.loading = false;
        },
        fetchTicketsFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchTicketRequest: (state, action: PayloadAction<string>) => {
            state.loading = true;
            state.error = null;
        },
        fetchTicketSuccess: (state, action: PayloadAction<HelpdeskTicket>) => {
            state.currentTicket = action.payload;
            state.loading = false;
        },
        fetchTicketFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        createTicketRequest: (state, action: PayloadAction<Omit<HelpdeskTicket, 'id' | 'created_at' | 'updated_at' | 'comments' | 'attachments'>>) => {
            state.loading = true;
            state.error = null;
        },
        createTicketSuccess: (state, action: PayloadAction<HelpdeskTicket>) => {
            state.tickets.unshift(action.payload);
            state.currentTicket = action.payload;
            state.loading = false;
        },
        createTicketFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        clearCurrentTicket: (state) => {
            state.currentTicket = null;
        },
        createCommentRequest: (state, action: PayloadAction<{ ticketId: string; body: string }>) => {
            state.loading = true;
            state.error = null;
        },
        createCommentSuccess: (state, action: PayloadAction<HelpdeskTicket>) => {
            state.loading = false;
            state.error = null;
            // Update currentTicket if it matches the updated ticket
            if (state.currentTicket && state.currentTicket.id === action.payload.id) {
                state.currentTicket = action.payload;
            }
            // Update the ticket in the tickets array
            const ticketIndex = state.tickets.findIndex(ticket => ticket.id === action.payload.id);
            if (ticketIndex !== -1) {
                state.tickets[ticketIndex] = action.payload;
            }
        },
        createCommentFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchTicketsRequest,
    fetchTicketsSuccess,
    fetchTicketsFailure,
    fetchTicketRequest,
    fetchTicketSuccess,
    fetchTicketFailure,
    createTicketRequest,
    createTicketSuccess,
    createTicketFailure,
    clearCurrentTicket,
    createCommentRequest,
    createCommentSuccess,
    createCommentFailure,
} = helpdeskSlice.actions;

export default helpdeskSlice.reducer;
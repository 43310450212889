import React from 'react';
import { ArrowRight, Smartphone, Timer, Shield } from 'lucide-react';
import EVAlarmLayout from '../layout/EVAlarmLayout';

const ClientDownload = () => {
    const isRegistrationEnabled = import.meta.env.VITE_REGISTRATION_ENABLED === 'true';
    const downloadLink = isRegistrationEnabled
        ? "https://apps.apple.com/app/id6670730568"
        : "/presign-up";
    const preSignUpLink = "/presign-up";

    return (
        <EVAlarmLayout>
            <div className="relative min-h-screen">
                <div className="absolute inset-0 bg-gradient-to-br from-teal-50/50 via-cyan-50/30 to-white" />

                <div className="relative max-w-3xl mx-auto px-4 pt-32 pb-16">
                    {/* Main Content */}
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-semibold text-gray-900 mb-3">
                            Download EVAlarm
                        </h1>
                        <p className="text-gray-600">
                            Smart alerts before charging fees start
                        </p>
                    </div>

                    {/* Download Options */}
                    <div className="grid md:grid-cols-2 gap-6 mb-8">
                        {/* iPhone Download */}
                        <div className="bg-white rounded-xl shadow-sm border border-teal-100 p-6">
                            <div className="flex items-center justify-between mb-4">
                                <div>
                                    <h2 className="font-medium text-gray-900">iPhone App</h2>
                                    <p className="text-sm text-gray-500">Available Now</p>
                                </div>
                                <div className="bg-teal-50 w-10 h-10 rounded-full flex items-center justify-center">
                                    <Smartphone className="w-5 h-5 text-teal-600" />
                                </div>
                            </div>
                            <a
                                href={downloadLink}
                                className="flex items-center justify-center w-full bg-teal-600 text-white px-4 py-3 rounded-lg font-medium hover:bg-teal-700 transition-all duration-300 group"
                            >
                                Download
                                <ArrowRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                            </a>
                        </div>

                        {/* Android */}
                        <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
                            <div className="flex items-center justify-between mb-4">
                                <div>
                                    <h2 className="font-medium text-gray-900">Android App</h2>
                                    <p className="text-sm text-gray-500">Coming Soon</p>
                                </div>
                                <div className="bg-gray-50 w-10 h-10 rounded-full flex items-center justify-center">
                                    <Smartphone className="w-5 h-5 text-gray-600" />
                                </div>
                            </div>
                            <a
                                href={preSignUpLink}
                                className="flex items-center justify-center w-full bg-gray-600 text-white px-4 py-3 rounded-lg font-medium hover:bg-gray-700 transition-all duration-300 group"
                            >
                                Get Notified
                                <ArrowRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                            </a>
                        </div>
                    </div>

                    {/* Value Proposition - Subtle */}
                    <div className="bg-white/80 backdrop-blur-sm rounded-lg border border-teal-100 p-4 mb-6">
                        <div className="flex items-center justify-center gap-2 text-sm">
                            <Timer className="w-4 h-4 text-teal-600" />
                            <span>Limited time: <span className="font-medium">$299</span> lifetime access</span>
                            <span className="mx-2 text-gray-300">•</span>
                            <span className="text-gray-600">Prevents $200+ overstay fees</span>
                        </div>
                    </div>

                    {/* Trust Element - Single line */}
                    <div className="text-center text-sm text-gray-600">
                        <div className="flex items-center justify-center">
                            <Shield className="w-4 h-4 text-teal-600 mr-2" />
                            <span>60-day money-back guarantee</span>
                        </div>
                    </div>
                </div>
            </div>
        </EVAlarmLayout>
    );
};

export default ClientDownload;

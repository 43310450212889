// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import {useSelector} from "react-redux";
import {RootState} from "../store";

interface ProtectedRouteProps extends Omit<RouteProps, 'element'> {
    element: React.ReactElement;
    redirectPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
                                                           element,
                                                           redirectPath,
                                                           ...rest
                                                       }) => {
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const loading = useSelector((state: RootState) => state.user.loading);

    if (loading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? element : <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute;
import React from 'react';
import EVAlarmLayout from "../layout/EVAlarmLayout";

const PrivacyPolicy = () => {
    return (
        <EVAlarmLayout>
            <div className="max-w-3xl mx-auto py-12">
                <h1 className="text-3xl font-bold mb-6">EVAlarm Privacy Policy</h1>
                <p className="text-sm text-gray-600 mb-8">Last updated: October 12, 2024</p>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                    <p>At EVAlarm, we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines our practices concerning the collection, use, and protection of your data when you use our EVAlarm application and services.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">2. Data Collection and Use</h2>
                    <p>We adhere to the principle of data minimization, collecting only the data necessary to provide and improve our service. Specifically, we collect:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li>Contact information: Your email address for sending urgent notifications and communications about new features.</li>
                        <li>Vehicle information: Details about your electric vehicle to provide relevant data and functionality.</li>
                        <li>Charging data: Information related to your vehicle's charging status and history.</li>
                        <li>Account information: Data used to identify your vehicle and present relevant information to you.</li>
                        <li>Crash data and analytics: To improve the app experience for all users.</li>
                        <li>IP address: For security and fraud prevention purposes.</li>
                    </ul>
                    <p className="mt-2">All collected data is used solely for app functionality, to enhance user experience, and to prevent abuse of our services. We do not collect or have any interest in collecting location data or any information not directly relevant to providing our service.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">3. Data Sharing and Third Parties</h2>
                    <p>We are committed to maintaining the confidentiality of your information:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li>We do not sell your data to third parties.</li>
                        <li>Your data is not transmitted to third parties except where necessary for payment processing.</li>
                        <li>Any personal information sent to payment partners is discarded by us as soon as it's transmitted to them.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">4. Data Retention</h2>
                    <p>We maintain a strict data retention policy:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li>We retain your data only for as long as necessary to provide you with the EVAlarm service.</li>
                        <li>Personal information used for payment processing is discarded immediately after transmission to our payment partners.</li>
                        <li>Email addresses and IP addresses associated with account cancellations are retained for a reasonable period to prevent abuse of our cancellation policy.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">5. Data Security</h2>
                    <p>We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk, protecting your data against unauthorized or unlawful processing and against accidental loss, destruction, or damage.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">6. Your Rights</h2>
                    <p>You have the right to:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li>Access the personal information we hold about you</li>
                        <li>Request the correction of inaccurate data</li>
                        <li>Request the deletion of your data</li>
                        <li>Object to or restrict the processing of your data</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">7. Global Data Protection Compliance</h2>
                    <p>EVAlarm is committed to complying with data protection regulations worldwide. Specifically:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li>We comply with the European Union's General Data Protection Regulation (GDPR).</li>
                        <li>We adhere to the California Consumer Privacy Act (CCPA) requirements.</li>
                        <li>Our practices are designed to meet or exceed the requirements of most data privacy laws globally.</li>
                    </ul>
                    <p className="mt-2">We continuously monitor changes in data protection laws and update our practices accordingly to ensure ongoing compliance.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">8. Cancellation Policy and Data Retention</h2>
                    <p>We offer a consumer-friendly cancellation policy to ensure your satisfaction with our service. To maintain the integrity of this policy and prevent abuse:</p>
                    <ul className="list-disc pl-6 mt-2">
                        <li>We retain your email address and IP address for a reasonable period after account cancellation.</li>
                        <li>This retention is based on the legitimate interest clause in GDPR, solely to prevent abuse of our cancellation policy.</li>
                        <li>The retained data is used exclusively for this purpose and is not shared with third parties or used for marketing.</li>
                        <li>This practice allows us to continue offering a generous cancellation policy to all users.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">9. Changes to This Policy</h2>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this policy.</p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy or our data practices, please contact us at new-ticket@inbound.evalarm.app.</p>
                </section>
            </div>
        </EVAlarmLayout>
    );
};

export default PrivacyPolicy;
import React from 'react';
import { Link } from 'react-router-dom';
import { Clock, Tag, User, ArrowUpRight } from 'lucide-react';
import { motion } from 'framer-motion';

export interface BlogPostMetadata {
  id: string;
  title: string;
  slug: string;
  description: string;
  author: {
    name: string;
    avatar?: string;
  };
  date: string;
  readingTime: number;
  tags: string[];
}

export interface BlogCardProps {
  post: BlogPostMetadata;
  featured?: boolean;
}

export interface BlogPostProps {
  metadata: BlogPostMetadata;
}

export const BlogCard: React.FC<BlogCardProps> = ({ post, featured = false }) => {
  const formattedDate = new Date(post.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <motion.article
      whileHover={{ y: -4 }}
      className={`group bg-white rounded-2xl shadow-sm hover:shadow-xl transition-all duration-300 overflow-hidden border border-gray-100
        ${featured ? 'md:grid md:grid-cols-2 md:gap-8' : ''}`}
    >
      <Link to={`/blog/${post.slug}`} className="block h-full">
        <div className={`p-6 md:p-8 flex flex-col h-full ${featured ? 'md:pr-0' : ''}`}>
          <div className="flex-1">
            <div className="flex items-center space-x-4 mb-6">
              <div className="flex-shrink-0">
                {post.author.avatar ? (
                  <img
                    src={post.author.avatar}
                    alt={post.author.name}
                    className="w-10 h-10 rounded-full"
                  />
                ) : (
                  <div className="w-10 h-10 rounded-full bg-gradient-to-br from-teal-500 to-cyan-500 flex items-center justify-center">
                    <User className="w-5 h-5 text-white" />
                  </div>
                )}
              </div>
              <div>
                <p className="text-sm font-medium text-gray-900">{post.author.name}</p>
                <p className="text-sm text-gray-500">{formattedDate}</p>
              </div>
            </div>

            <div className="mb-4">
              <h2 className={`font-bold text-gray-900 mb-3 group-hover:text-teal-600 transition-colors
                ${featured ? 'text-2xl md:text-3xl' : 'text-xl'}`}>
                {post.title}
              </h2>
              <p className={`text-gray-600 line-clamp-3 mb-4 ${featured ? 'md:text-lg' : ''}`}>
                {post.description}
              </p>
            </div>

            <div className="flex flex-wrap gap-2 mb-6">
              {post.tags.slice(0, featured ? 4 : 2).map(tag => (
                <span
                  key={tag}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                    bg-gradient-to-r from-teal-50 to-cyan-50 text-teal-700 border border-teal-100"
                >
                  <Tag className="w-3.5 h-3.5 mr-1.5 text-teal-500" />
                  {tag}
                </span>
              ))}
              {post.tags.length > (featured ? 4 : 2) && (
                <span className="text-sm text-gray-500 flex items-center">
                  +{post.tags.length - (featured ? 4 : 2)} more
                </span>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between pt-4 border-t border-gray-100">
            <div className="flex items-center text-sm text-gray-500">
              <Clock className="w-4 h-4 mr-1.5" />
              {post.readingTime} min read
            </div>
            <div className="flex items-center text-sm font-medium text-teal-600 group-hover:text-teal-700">
              Read more
              <ArrowUpRight className="w-4 h-4 ml-1 transition-transform group-hover:translate-x-0.5 group-hover:-translate-y-0.5" />
            </div>
          </div>
        </div>
      </Link>
    </motion.article>
  );
};

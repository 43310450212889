import 'vite/modulepreload-polyfill'
import ahoy from 'ahoy.js';

import * as React from 'react'
import { createRoot } from 'react-dom/client';

import {BrowserRouter} from "react-router-dom";
import App from "../components/App";
import store from "../store";
import {initializeTeslaAccountsFromMetaTag, initializeUserFromMetaTag} from "./data_init";
import { Provider } from "react-redux";


ahoy.configure({
    urlPrefix: "",
    visitsUrl: "/ahoy/visits",
    eventsUrl: "/ahoy/events",
    page: null,
    platform: "Web",
    useBeacon: true,
    startOnReady: true,
    trackVisits: true,
    cookies: true,
    cookieDomain: null,
    headers: {},
    visitParams: {},
    withCredentials: false,
    visitDuration: 4 * 60, // 4 hours
    visitorDuration: 2 * 365 * 24 * 60 // 2 years
});

function hasInitialState(window: Window): window is WindowWithInitialState {
    return '__INITIAL_STATE__' in window;
}

interface WindowWithInitialState extends Window {
    __INITIAL_STATE__: {
        message: string;
        // Add other properties as needed
    }
}

initializeUserFromMetaTag(store.dispatch);
initializeTeslaAccountsFromMetaTag(store.dispatch)


const container = document.getElementById('evalarm-react-app')
if (container) {
    const initialState = hasInitialState(window) ? window.__INITIAL_STATE__ : { message: '' };
    const root = createRoot(container)
    root.render(
        <Provider store={store}>
            <BrowserRouter>
                <App {...initialState} />
            </BrowserRouter>
        </Provider>
    )
} else {
    console.error('Failed to find the react-app element')
}
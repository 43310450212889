import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum SettingTab {
    Account = 'account',
    Device = 'device',
    TeslaAccount = 'tesla_account',
    Plan = 'plan',
}

export enum AccountSettingStep {
    StepOne = 1,
    StepTwo = 2
}

export interface AccountSettingsFormData {
    newEmail: string;
    password: string;
    usePasswordAuth: boolean;
    otpCode: string;
}

interface SettingsState {
    activeTab: SettingTab;
    accountSettingStep: AccountSettingStep;
    accountSettingsForm: AccountSettingsFormData;
    isFormDirty: boolean;
    isSubmitting: boolean;
    submitError: string | null;
}

const initialState: SettingsState = {
    activeTab: SettingTab.Account,
    accountSettingStep: AccountSettingStep.StepOne,
    accountSettingsForm: {
        newEmail: '',
        password: '',
        usePasswordAuth: false,
        otpCode: '',
    },
    isFormDirty: false,
    isSubmitting: false,
    submitError: null,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<SettingTab>) => {
            state.activeTab = action.payload;
        },
        setAccountSettingStep: (state, action: PayloadAction<AccountSettingStep>) => {
            state.accountSettingStep = action.payload;
        },
        updateAccountSettingsForm: (state, action: PayloadAction<Partial<AccountSettingsFormData>>) => {
            state.accountSettingsForm = { ...state.accountSettingsForm, ...action.payload };
            state.isFormDirty = true;
        },
        resetAccountSettingsForm: (state) => {
            state.accountSettingsForm = initialState.accountSettingsForm;
            state.isFormDirty = false;
            state.accountSettingStep = AccountSettingStep.StepOne;
        },
        triggerAccountSettingsUpdate: (state) => {
            state.isSubmitting = true;
            state.submitError = null;
        },
        accountSettingsUpdateSuccess: (state) => {
            state.isSubmitting = false;
            state.isFormDirty = false;
            state.accountSettingStep = AccountSettingStep.StepOne;
        },
        accountSettingsUpdateFailure: (state, action: PayloadAction<string>) => {
            state.isSubmitting = false;
            state.submitError = action.payload;
        },
        clearSubmitError: (state) => {
            state.submitError = null;
        },
        setFormDirty: (state, action: PayloadAction<boolean>) => {
            state.isFormDirty = action.payload;
        },
    }
});

export const {
    setActiveTab,
    setAccountSettingStep,
    updateAccountSettingsForm,
    resetAccountSettingsForm,
    triggerAccountSettingsUpdate,
    accountSettingsUpdateSuccess,
    accountSettingsUpdateFailure,
    clearSubmitError,
    setFormDirty,
} = settingsSlice.actions;

export default settingsSlice.reducer;
import React from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Clock, Calendar, User, Tag } from 'lucide-react';
import { blogPosts } from './Blog/blog-posts';

export const BlogPostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const currentPost = blogPosts.find(p => p.metadata.slug === slug);

  if (!currentPost) {
    return <Navigate to="/blog" replace />;
  }

  const { Component, metadata } = currentPost;

  // Find related posts based on tags
  const relatedPosts = blogPosts
    .filter(post =>
      post.metadata.id !== metadata.id &&
      post.metadata.tags.some(tag => metadata.tags.includes(tag))
    )
    .slice(0, 3);

  const formattedDate = new Date(metadata.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
      <Helmet>
        <title>{`${metadata.title} | EVAlarm Blog`}</title>
        <meta name="description" content={metadata.description} />
        <meta property="og:title" content={metadata.title} />
        <meta property="og:description" content={metadata.description} />
        {metadata.coverImage && <meta property="og:image" content={metadata.coverImage} />}
        <meta name="author" content={metadata.author.name} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={metadata.title} />
        <meta name="twitter:description" content={metadata.description} />
      </Helmet>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <Link
            to="/blog"
            className="inline-flex items-center text-sm text-gray-600 hover:text-teal-600 mb-8 group"
          >
            <ArrowLeft className="w-4 h-4 mr-2 transition-transform group-hover:-translate-x-1" />
            Back to Blog
          </Link>

          <header className="mb-12">
            <div className="flex flex-wrap items-center gap-4 mb-6 text-sm text-gray-600">
              <div className="flex items-center">
                <Calendar className="w-4 h-4 mr-2" />
                {formattedDate}
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 mr-2" />
                {metadata.readingTime} min read
              </div>
            </div>

            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              {metadata.title}
            </h1>

            <div className="flex items-center gap-4 mb-8">
              <div className="flex-shrink-0">
                {metadata.author.avatar ? (
                  <img
                    src={metadata.author.avatar}
                    alt={metadata.author.name}
                    className="w-12 h-12 rounded-full"
                  />
                ) : (
                  <div className="w-12 h-12 rounded-full bg-gradient-to-br from-teal-500 to-cyan-500 flex items-center justify-center">
                    <User className="w-6 h-6 text-white" />
                  </div>
                )}
              </div>
              <div>
                <p className="font-medium text-gray-900">{metadata.author.name}</p>
                <p className="text-sm text-gray-600">Author</p>
              </div>
            </div>

            <div className="flex flex-wrap gap-2">
              {metadata.tags.map(tag => (
                <span
                  key={tag}
                  className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium
                    bg-gradient-to-r from-teal-50 to-cyan-50 text-teal-700 border border-teal-100"
                >
                  <Tag className="w-3.5 h-3.5 mr-1.5 text-teal-500" />
                  {tag}
                </span>
              ))}
            </div>
          </header>

          <article className="prose prose-lg max-w-none">
            <Component metadata={metadata} />
          </article>

          {relatedPosts.length > 0 && (
            <section className="mt-16 pt-8 border-t border-gray-200">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Related Posts</h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {relatedPosts.map(({ metadata: relatedMeta }) => (
                  <Link
                    key={relatedMeta.id}
                    to={`/blog/${relatedMeta.slug}`}
                    className="group block bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow p-6"
                  >
                    <h3 className="font-semibold text-gray-900 group-hover:text-teal-600 mb-2">
                      {relatedMeta.title}
                    </h3>
                    <p className="text-sm text-gray-600 line-clamp-2">
                      {relatedMeta.description}
                    </p>
                  </Link>
                ))}
              </div>
            </section>
          )}
        </div>
      </motion.div>
    </>
  );
};

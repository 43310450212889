import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="bg-gray-100 py-8 mt-auto">
            <div className="container mx-auto px-6 text-center">
                <p className="text-lg text-gray-700 mb-4">&copy; 2024 EVAlarm. Supporting Tesla owners in efficient charging.</p>
                <p className="text-sm text-gray-600 mb-6">Designed for all Tesla models. More EV brands coming soon!</p>
                <div className="flex flex-wrap justify-center space-x-6">
                    <Link to="/privacy" className="text-gray-600 hover:text-teal-600 transition duration-300">Privacy Policy</Link>
                    <Link to="/tos" className="text-gray-600 hover:text-teal-600 transition duration-300">Terms of Service</Link>
                    <Link to="/help" className="text-gray-600 hover:text-teal-600 transition duration-300">Help Center</Link>
                    <Link to="/help/ticket" className="text-gray-600 hover:text-teal-600 transition duration-300">Contact Us</Link>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';
import {AppDispatch, RootState} from "../../../store";
import {fetchTicketsRequest, getHumanReadableStatus} from "../../../store/helpdeskSlice";
import {formatDateInWords} from "../../services/util";


const UserTicketsList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { tickets, loading, error } = useSelector((state: RootState) => state.helpdesk);

    useEffect(() => {
        dispatch(fetchTicketsRequest());
    }, [dispatch]);

    if (loading) return <div className="text-center">Loading tickets...</div>;
    if (error) return <div className="text-center text-red-600">Error: {error}</div>;

    return (
        <div className="space-y-4">
            <h2 className="text-2xl font-bold mb-4">Your Support Tickets</h2>
            {tickets.length === 0 ? (
                <p>You haven't submitted any tickets yet.</p>
            ) : (
                <ul className="divide-y divide-gray-200">
                    {tickets.map((ticket) => (
                        <li key={ticket.id} className="py-4">
                            <Link to={`/help/ticket/${ticket.id}`} className="flex items-center hover:bg-gray-50 p-2 rounded">
                                <div className="flex-grow">
                                    <h3 className="text-lg font-semibold text-gray-900">{ticket.title}</h3>
                                    <p className="text-sm text-gray-600">Status: {getHumanReadableStatus(ticket.status)}</p>
                                    <p className="text-sm text-gray-500">Created: {formatDateInWords(ticket.created_at)}</p>
                                </div>
                                <ChevronRight className="h-5 w-5 text-gray-400" />
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
            <div className="mt-6">
                <Link
                    to="/help/ticket/new"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                >
                    Create New Ticket
                </Link>
            </div>
        </div>
    );
};

export default UserTicketsList;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Mail, Lock, Eye, EyeOff, AlertCircle} from 'lucide-react';
import { otpRequest } from '../../store/userSlice';
import {
    setAccountSettingStep,
    AccountSettingStep,
    updateAccountSettingsForm,
    triggerAccountSettingsUpdate, setFormDirty
} from '../../store/settingSlice';
import { RootState } from '../../store';

const OTPInput: React.FC<{ value: string; onChange: (value: string) => void }> = ({ value, onChange }) => {
    const inputRefs = Array(6).fill(0).map(() => React.createRef<HTMLInputElement>());

    const handleChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        if (newValue.length <= 1 && /^\d*$/.test(newValue)) {
            const newOtp = value.split('');
            newOtp[index] = newValue;
            onChange(newOtp.join(''));
            if (newValue && index < 5) {
                inputRefs[index + 1].current?.focus();
            }
        }
    };

    const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && !value[index] && index > 0) {
            inputRefs[index - 1].current?.focus();
        }
    };

    return (
        <div className="flex justify-between max-w-xs mx-auto">
            {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                    key={index}
                    ref={inputRefs[index]}
                    type="text"
                    maxLength={1}
                    value={value[index] || ''}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    className="w-12 h-12 text-center text-2xl border-2 border-gray-300 rounded-md focus:border-teal-500 focus:ring-teal-500"
                />
            ))}
        </div>
    );
};


const ToggleSwitch: React.FC<{ checked: boolean; onChange: (checked: boolean) => void }> = ({ checked, onChange }) => (
    <div className="flex items-center">
        <div
            className={`w-14 h-7 flex items-center rounded-full p-1 cursor-pointer ${
                checked ? 'bg-teal-500' : 'bg-gray-300'
            }`}
            onClick={() => onChange(!checked)}
        >
            <div
                className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform duration-300 ease-in-out ${
                    checked ? 'translate-x-7' : ''
                }`}
            />
        </div>
    </div>
);

const AccountSettings: React.FC = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user.currentUser);
    const { loading, error } = useSelector((state: RootState) => state.user);
    const { accountSettingStep, accountSettingsForm, isFormDirty } = useSelector((state: RootState) => state.settings);
    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState<{ type: 'success' | 'error', text: string } | null>(null);

    useEffect(() => {
        if (user && accountSettingStep === AccountSettingStep.StepOne) {
            dispatch(updateAccountSettingsForm({
                newEmail: user.email,
                usePasswordAuth: user.usePasswordAuth
            }));
            dispatch(setFormDirty(false));
        }
    }, [user, accountSettingStep, dispatch]);

    useEffect(() => {
        if (error) {
            setMessage({ type: 'error', text: error });
        } else if (!loading && accountSettingStep === AccountSettingStep.StepOne && message?.type === 'success') {
            setMessage(null);
        }
    }, [error, loading, accountSettingStep]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        dispatch(updateAccountSettingsForm({
            [name]: type === 'checkbox' ? checked : value
        }));
        dispatch(setFormDirty(true));
    };

    const handleSave = (e: React.FormEvent) => {
        e.preventDefault();
        if (accountSettingStep === AccountSettingStep.StepOne) {
            dispatch(otpRequest({ email: accountSettingsForm.newEmail }));
            dispatch(setAccountSettingStep(AccountSettingStep.StepTwo));
            setMessage({ type: 'success', text: '6-digit code sent to your email. Please check your inbox.' });
        } else {
            if (accountSettingsForm.otpCode.length !== 6) {
                setMessage({ type: 'error', text: 'Please enter the 6-digit code sent to your email.' });
                return;
            }

            dispatch(triggerAccountSettingsUpdate());
            setMessage({ type: 'success', text: 'Account settings update initiated.' });
        }
    };

    const handleOTPChange = (otpCode: string) => {
        dispatch(updateAccountSettingsForm({ otpCode }));
        dispatch(setFormDirty(true));
    };

    const isSaveDisabled = !isFormDirty || (accountSettingStep === AccountSettingStep.StepTwo && accountSettingsForm.otpCode.length !== 6);

    return (
        <form onSubmit={handleSave} className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Account Settings</h2>

            {message && (
                <div
                    className={`p-4 rounded-md ${message.type === 'success' ? 'bg-teal-100 text-teal-700' : 'bg-red-100 text-red-700'}`}>
                    {message.text}
                </div>
            )}

            {accountSettingStep === AccountSettingStep.StepOne ? (
                <div className="space-y-4">
                    <div>
                        <label htmlFor="newEmail" className="block text-sm font-medium text-gray-700">Email</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <Mail className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                            </div>
                            <input
                                type="email"
                                id="newEmail"
                                name="newEmail"
                                className="focus:ring-teal-500 focus:border-teal-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                                value={accountSettingsForm.newEmail}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="flex items-center justify-between">
                        <span className="text-sm font-medium text-gray-700">Use Password Authentication</span>
                        <ToggleSwitch
                            checked={accountSettingsForm.usePasswordAuth}
                            onChange={(checked) => dispatch(updateAccountSettingsForm({usePasswordAuth: checked}))}
                        />
                    </div>

                    {accountSettingsForm.usePasswordAuth && (
                        <div>
                            <label htmlFor="password"
                                   className="block text-sm font-medium text-gray-700">Password</label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <Lock className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </div>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    name="password"
                                    className="focus:ring-teal-500 focus:border-teal-500 block w-full pl-10 pr-10 sm:text-sm border-gray-300 rounded-md"
                                    value={accountSettingsForm.password}
                                    onChange={handleInputChange}
                                />
                                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                    <button
                                        type="button"
                                        onClick={() => setShowPassword(!showPassword)}
                                        className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                                    >
                                        {showPassword ? (
                                            <EyeOff className="h-5 w-5" aria-hidden="true"/>
                                        ) : (
                                            <Eye className="h-5 w-5" aria-hidden="true"/>
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div className="space-y-6">
                    <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <AlertCircle className="h-5 w-5 text-blue-400" aria-hidden="true"/>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-blue-700">
                                    Please check your email for a 6-digit verification code.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="otpCode" className="block text-sm font-medium text-gray-700 mb-2">Enter 6-digit
                            Code</label>
                        <OTPInput
                            value={accountSettingsForm.otpCode}
                            onChange={handleOTPChange}
                        />
                    </div>
                </div>
            )}

            <div className="flex justify-end">
                <button
                    type="submit"
                    disabled={isSaveDisabled}
                    className={`px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                        isSaveDisabled
                            ? 'bg-gray-300 cursor-not-allowed'
                            : 'bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
                    }`}
                >
                    {accountSettingStep === AccountSettingStep.StepOne ? 'Save Changes' : 'Confirm Changes'}
                </button>
            </div>
        </form>
    );
};

export default AccountSettings;
import React from 'react';
import { motion } from 'framer-motion';
import { Bell, Settings, WifiOff, PlusCircle, ArrowRight, Shield, Zap, DollarSign, LucideIcon } from 'lucide-react';

interface Screenshot {
  title: string;
  subtitle: string;
  icon: LucideIcon;
  imageUrl: string;
  highlight: string;
  ctaText: string;
  features: string[];
  savingsAmount: number;
}

interface PhoneFrameProps {
  imageUrl: string;
  title: string;
}

interface ScreenshotProps extends Screenshot {
  index: number;
}

interface ROICalculatorProps {
  savingsAmount: number;
}

interface FeatureHighlightProps {
  text: string;
}

const IPHONE_ASPECT_RATIO = 1320 / 2868;

const MoneyBackGuarantee: React.FC = () => (
  <div className="flex items-center gap-2 mt-4 text-sm text-gray-600">
    <Shield className="w-4 h-4 text-teal-600" />
    <span className="font-medium">Try risk-free: 60-day money-back guarantee</span>
  </div>
);

const ROICalculator: React.FC<ROICalculatorProps> = ({ savingsAmount }) => (
  <div className="mt-6 bg-gradient-to-br from-amber-50 to-orange-50 rounded-xl p-4 border border-amber-100">
    <div className="flex items-center gap-3">
      <DollarSign className="w-6 h-6 text-amber-600" />
      <div>
        <p className="text-amber-900 font-medium">Prevent ${savingsAmount} in overstay fees</p>
        <p className="text-sm text-amber-700">EVAlarm pays for itself after just 2 avoided penalties</p>
      </div>
    </div>
  </div>
);

const FeatureHighlight: React.FC<FeatureHighlightProps> = ({ text }) => (
  <div className="flex items-center gap-2 text-gray-700 mt-3">
    <Zap className="w-4 h-4 text-teal-600" />
    <span className="text-sm">{text}</span>
  </div>
);

const SCREENSHOTS = [
  {
    title: "Stop Overstay Fees Before They Start",
    subtitle: "Get persistent alerts until you unplug your Tesla at any charging station",
    icon: Bell,
    imageUrl: "/screenshots/notification.webp",
    highlight: "Save up to $200 per charging session",
    ctaText: "Protect Your Tesla",
    features: [
      "Persistent alerts until you unplug",
      "Multiple notification types",
      "Works at all charger types"
    ],
    savingsAmount: 200
  },
  {
    title: "Your Charging, Your Alerts",
    subtitle: "Customize notifications for different charging situations",
    icon: Settings,
    imageUrl: "/screenshots/preferences.png",
    highlight: "Perfect alerts for every charging session",
    ctaText: "Start Saving Today",
    features: [
      "Customizable alert timing",
      "Adjustable notification frequency",
      "Easy alert preferences"
    ],
    savingsAmount: 150
  },
  {
    title: "Works Without Cell Service",
    subtitle: "Never miss an alert, even in areas with poor reception",
    icon: WifiOff,
    imageUrl: "/screenshots/offline.webp",
    highlight: "Reliable protection anywhere",
    ctaText: "Get Protected",
    features: [
      "Works without internet",
      "Backup alert system",
      "Local notification processing"
    ],
    savingsAmount: 175
  },
  {
    title: "Smart Timer Estimates",
    subtitle: "Quick manual entry with pre-filled charging estimates",
    icon: PlusCircle,
    imageUrl: "/screenshots/manual-entry.png",
    highlight: "Fast backup when your car is offline",
    ctaText: "Start Protection",
    features: [
      "Pre-filled time estimates",
      "One-tap charge logging",
      "Offline support (auto-detection coming soon)"
    ],
    savingsAmount: 180
  }
];

const PhoneFrame: React.FC<PhoneFrameProps> = ({ imageUrl, title }) => (
  <div className="relative w-full max-w-sm mx-auto">
    <div
      className="relative bg-[#1B1B1B] rounded-[55px] p-4 shadow-2xl"
      style={{ aspectRatio: `${IPHONE_ASPECT_RATIO}` }}
    >
      <div className="relative w-full h-full overflow-hidden rounded-[48px]">
        <img
          src={imageUrl}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
    </div>
  </div>
);

const Screenshot: React.FC<ScreenshotProps> = ({
  index,
  title,
  subtitle,
  icon: Icon,
  highlight,
  imageUrl,
  ctaText,
  features,
  savingsAmount
}) => {
  const isEven = index % 2 === 0;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.2 }}
      className={`flex flex-col lg:flex-row items-center gap-12 ${isEven ? 'lg:flex-row' : 'lg:flex-row-reverse'}`}
    >
      <div className="w-full lg:w-1/2">
        <div className="max-w-md mx-auto">
          <div className="bg-white/90 backdrop-blur-sm rounded-2xl shadow-xl p-8 border border-gray-100">
            <div className="flex items-start gap-4">
              <div className="p-3 bg-gradient-to-br from-teal-50 to-cyan-50 rounded-xl border border-teal-100/50">
                <Icon className="w-6 h-6 text-teal-600" />
              </div>
              <div className="flex-1">
                <div className="inline-block px-4 py-1 bg-gradient-to-r from-teal-100/50 to-cyan-100/50 rounded-full mb-4">
                  <p className="text-sm font-medium bg-gradient-to-r from-teal-600 to-cyan-600 bg-clip-text text-transparent">
                    {highlight}
                  </p>
                </div>

                <h3 className="text-2xl font-bold text-gray-900 mb-2">{title}</h3>
                <p className="text-lg text-gray-600">{subtitle}</p>

                <ROICalculator savingsAmount={savingsAmount} />

                {features.map((feature, idx) => (
                  <FeatureHighlight key={idx} text={feature} />
                ))}

                <button
                  onClick={() => window.location.href = '/signup'}
                  className="mt-6 w-full inline-flex items-center justify-center px-6 py-3 bg-gradient-to-r from-teal-600 to-cyan-600 text-white rounded-full font-medium hover:from-teal-500 hover:to-cyan-500 transition-all duration-300 group"
                >
                  {ctaText}
                  <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                </button>

                <MoneyBackGuarantee />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-1/2">
        <PhoneFrame imageUrl={imageUrl} title={title} />
      </div>
    </motion.div>
  );
};

const AppScreenshotShowcase: React.FC = () => {
  return (
    <section className="py-24 overflow-hidden">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
            Protect Your
            <span className="relative inline-block mx-3">
              <span className="relative z-10 bg-gradient-to-r from-teal-600 to-cyan-600 bg-clip-text text-transparent">
                Tesla
              </span>
              <div className="absolute -bottom-2 left-0 w-full h-1 bg-gradient-to-r from-teal-600/30 to-cyan-600/30 rounded-full" />
            </span>
            from Fees
          </h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Stop worrying about workplace charging deadlines and Supercharger idle fees
          </p>
        </div>

        <div className="space-y-32">
          {SCREENSHOTS.map((screenshot, index) => (
            <Screenshot
              key={index}
              index={index}
              {...screenshot}
            />
          ))}
        </div>

        <div className="mt-24 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="bg-gradient-to-br from-teal-50 to-cyan-50 rounded-2xl p-8 md:p-12 max-w-3xl mx-auto border border-teal-100/50"
          >
            <h3 className="text-2xl md:text-3xl font-bold text-gray-900 mb-4">
              Stop Overpaying for Charging
            </h3>
            <p className="text-lg text-gray-600 mb-8">
              One simple app prevents thousands in annual overstay fees
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <button
                onClick={() => window.location.href = '/signup'}
                className="inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-teal-600 to-cyan-600 text-white rounded-full text-lg font-medium hover:from-teal-500 hover:to-cyan-500 transition-all duration-300 shadow-lg hover:shadow-xl group"
              >
                Protect Your Tesla Now
                <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
            <div className="mt-6 flex items-center justify-center gap-2 text-gray-600">
              <Shield className="w-5 h-5 text-teal-600" />
              <span className="font-medium">Try risk-free with our 60-day money-back guarantee</span>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default AppScreenshotShowcase;

import React from 'react';
import { Zap, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

interface AppPromotionProps {
  className?: string;
}

const AppPromotion: React.FC<AppPromotionProps> = ({ className = '' }) => {
  return (
    <motion.section
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      className={`bg-gradient-to-br from-teal-600 via-teal-500 to-cyan-500 rounded-2xl p-8 md:p-12 text-white shadow-xl ${className}`}
    >
      <div className="max-w-3xl">
        <h2 className="text-3xl md:text-4xl font-extrabold mb-4 bg-gradient-to-r from-white to-gray-100 bg-clip-text text-transparent">
          Avoid Overstay Fees at Public Chargers
        </h2>
        <p className="text-lg md:text-xl text-teal-50 mb-8 leading-relaxed">
          Use public chargers often? Download our app to get notified before overstay fees kick in.
          Works with all Tesla models, with more EVs coming soon!
        </p>
        <Link
          to="/download"
          className="group inline-flex items-center px-8 py-4 bg-white text-teal-600 rounded-full font-medium hover:bg-gray-50 transition-colors shadow-md hover:shadow-lg"
        >
          <Zap className="w-5 h-5 mr-2 transition-transform group-hover:scale-110" />
          Download Now
          <ChevronRight className="ml-2 w-5 h-5 transition-transform group-hover:translate-x-1" />
        </Link>
      </div>
    </motion.section>
  );
};

export default AppPromotion;

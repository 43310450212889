import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { CreditCard, Shield } from 'lucide-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { SubscriptionState } from '../../../store/subscriptionSlice';
import Button from '../../common/Button';
import Input from '../../common/Input';

interface PaymentMethodSectionProps {
    hasSubscription: boolean;
    showPaymentForm: boolean;
    setShowPaymentForm: (show: boolean) => void;
    paymentName: string;
    onPaymentNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
    loading: boolean;
    selectedPlanId: string;
    isAppleIAP: boolean;
}

export const PaymentMethodSection: React.FC<PaymentMethodSectionProps> = ({
                                                                              hasSubscription,
                                                                              showPaymentForm,
                                                                              setShowPaymentForm,
                                                                              paymentName,
                                                                              onPaymentNameChange,
                                                                              onSubmit,
                                                                              loading,
                                                                              selectedPlanId,
                                                                              isAppleIAP
                                                                          }) => {
    const { paymentMethod } = useSelector<RootState, SubscriptionState>((state) => state.subscription);

    if (isAppleIAP) {
        return (
            <div className="bg-gray-50 p-4 rounded-lg">
                <h3 className="text-lg font-semibold text-gray-800 mb-2">Payment Method</h3>
                <p className="text-gray-600">Your subscription is managed through Apple. To update your payment method, please use the App Store on your iOS device.</p>
            </div>
        );
    }

    return (
        <div className="space-y-6">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Payment Information</h3>

            {hasSubscription && (
                <div className="bg-white p-4 rounded-lg shadow-sm">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center">
                            <CreditCard className="w-6 h-6 text-teal-600 mr-3" />
                            <div>
                                <p className="text-sm text-gray-600">Current payment method</p>
                                <p className="text-base font-semibold text-gray-800">
                                    {paymentMethod || 'No payment method'}
                                </p>
                            </div>
                        </div>
                        <Button
                            variant="outline"
                            onClick={() => setShowPaymentForm(!showPaymentForm)}
                        >
                            {showPaymentForm ? 'Cancel' : 'Update Payment Method'}
                        </Button>
                    </div>
                </div>
            )}

            {(showPaymentForm || !hasSubscription) && (
                <div className="bg-white p-4 rounded-lg shadow-sm">
                    <h4 className="text-lg font-semibold text-gray-800 mb-4">
                        {hasSubscription ? 'Update Payment Method' : 'Enter Payment Details'}
                    </h4>
                    <form onSubmit={onSubmit} className="space-y-4">
                        <Input
                            label="Name on Card"
                            id="paymentName"
                            value={paymentName}
                            onChange={onPaymentNameChange}
                            required
                        />
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Card Details</label>
                            <div className="border border-gray-300 rounded-lg p-3">
                                <CardElement />
                            </div>
                        </div>
                        <div className="flex items-start text-sm text-gray-500">
                            <Shield className="w-4 h-4 mr-2 mt-0.5 flex-shrink-0" />
                            <span>Your payment information is securely processed and protected.</span>
                        </div>
                        <Button
                            type="submit"
                            disabled={loading || (!hasSubscription && !selectedPlanId)}
                            fullWidth
                        >
                            {loading ? 'Processing...' : hasSubscription ? 'Update Payment Method' : 'Start Subscription'}
                        </Button>
                    </form>
                </div>
            )}
        </div>
    );
};
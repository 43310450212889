import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {Device} from "../components/services/api";

interface DevicesState {
    list: Device[];
    loading: boolean;
    error: string | null;
}

const initialState: DevicesState = {
    list: [],
    loading: false,
    error: null,
};

export interface ToggleNotificationPayload {
    device_identifier: string;
    notification_enabled: boolean;
}

const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {
        fetchDevicesStart: (state) => {
            state.loading = true;
            state.error = null;
        },
        fetchDevicesSuccess: (state, action: PayloadAction<Device[]>) => {
            state.list = action.payload;
            state.loading = false;
        },
        fetchDevicesFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        toggleNotificationsStart: (state, action: PayloadAction<ToggleNotificationPayload>) => {
            state.loading = true;
            state.error = null;
        },
        toggleNotificationsSuccess: (state, action: PayloadAction<Device>) => {
            const index = state.list.findIndex(d => d.id === action.payload.id);
            if (index !== -1) {
                state.list[index] = action.payload;
            }
            state.loading = false;
        },
        toggleNotificationsFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        unlinkDeviceStart: (state, action: PayloadAction<string>) => {
            state.loading = true;
            state.error = null;
        },
        unlinkDeviceSuccess: (state, action: PayloadAction<Device>) => {
            state.list = state.list.filter(device => device.id !== action.payload.id);
            state.loading = false;
        },
        unlinkDeviceFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
        sendTestMessageStart: (state, action: PayloadAction<number>) => {
            state.loading = true;
            state.error = null;
        },
        sendTestMessageSuccess: (state) => {
            state.loading = false;
        },
        sendTestMessageFailure: (state, action: PayloadAction<string>) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
});

export const {
    fetchDevicesStart,
    fetchDevicesSuccess,
    fetchDevicesFailure,
    toggleNotificationsStart,
    toggleNotificationsSuccess,
    toggleNotificationsFailure,
    unlinkDeviceStart,
    unlinkDeviceSuccess,
    unlinkDeviceFailure,
    sendTestMessageStart,
    sendTestMessageSuccess,
    sendTestMessageFailure,
} = devicesSlice.actions;

export default devicesSlice.reducer;
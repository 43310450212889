import React, { useState } from 'react';
import { Battery, Zap, HelpCircle } from 'lucide-react';
import DashboardCard from '../../common/DashboardCard';

interface Vehicle {
    id: string;
    name: string;
    batteryPercentage: number | null;
    range: number | null;
    chargingStatus: 'charging' | 'not charging' | 'unknown';
    estimatedTimeToFull: number | null;
}

interface VehicleSummaryProps {
    vehicle: Vehicle;
    onSelect: () => void;
}

interface TooltipProps {
    content: string;
    children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ content, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div
            className="relative inline-block"
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            {children}
            {isVisible && (
                <div className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2">
                    {content}
                </div>
            )}
        </div>
    );
};

const VehicleSummary: React.FC<VehicleSummaryProps> = ({ vehicle, onSelect }) => {
    const getBatteryColor = (percentage: number | null) => {
        if (percentage === null) return 'text-gray-400';
        if (percentage <= 20) return 'text-red-500';
        if (percentage <= 50) return 'text-yellow-500';
        return 'text-green-500';
    };

    const getChargingStatusIcon = (status: string) => {
        switch (status) {
            case 'charging':
                return <Zap className="mr-2 text-green-500" size={18} />;
            case 'not charging':
                return <Zap className="mr-2 text-gray-400" size={18} />;
            default:
                return <HelpCircle className="mr-2 text-yellow-500" size={18} />;
        }
    };

    const getChargingStatusText = (status: string) => {
        switch (status) {
            case 'charging':
                return 'Charging';
            case 'not charging':
                return 'Not charging';
            default:
                return 'Status unknown';
        }
    };

    return (
        <DashboardCard title={vehicle.name}>
            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center">
                    <Battery className={`${getBatteryColor(vehicle.batteryPercentage)} mr-2`} size={24} />
                    <Tooltip content={vehicle.batteryPercentage !== null ? `${vehicle.batteryPercentage}%` : 'Battery level unknown'}>
                        <span className={`text-2xl font-bold ${getBatteryColor(vehicle.batteryPercentage)} min-w-[3ch] text-center`}>
                            {vehicle.batteryPercentage !== null ? `${vehicle.batteryPercentage}%` : '-'}
                        </span>
                    </Tooltip>
                </div>
                <Tooltip content={vehicle.range !== null ? `${vehicle.range} miles` : 'Range unknown'}>
                    <span className="text-lg text-gray-600 min-w-[6ch] text-center">
                        {vehicle.range !== null ? `${vehicle.range}` : '-'}
                    </span>
                </Tooltip>
            </div>
            <div className="flex items-center text-gray-700">
                {getChargingStatusIcon(vehicle.chargingStatus)}
                <span>{getChargingStatusText(vehicle.chargingStatus)}</span>
            </div>
            {vehicle.chargingStatus === 'charging' && vehicle.estimatedTimeToFull !== null && (
                <div className="mt-2 text-sm text-gray-600">
                    Estimated time to full: {vehicle.estimatedTimeToFull} minutes
                </div>
            )}
            <button
                onClick={onSelect}
                className="mt-4 bg-teal-600 text-white px-4 py-2 rounded-lg font-medium hover:bg-teal-700 transition duration-300 shadow-md w-full"
            >
                View Details
            </button>
        </DashboardCard>
    );
};

export default VehicleSummary;
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ArrowRight, Clock, Trophy, Zap, Check, Shield } from "lucide-react";
import { RootState } from "../../../store";
import { fetchLifetimePlanRequest, LIFETIME_PLAN_ID, Plan } from "@/store/plansSlice";
import { BillingFrequency } from "@/store/subscriptionSlice";
import { updateRegistrationSessionRequest } from "@/store/registrationSlice";

interface FormButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'secondary';
    children: React.ReactNode;
}

interface PricingCardProps {
    plan: Plan;
    isSelected: boolean;
    onSelect: (planId: string) => void;
    billingFrequency: BillingFrequency;
}

interface LifetimeOfferProps {
    isSelected: boolean;
    onSelect: () => void;
    plan: Plan;
    enabled: boolean;
}

const FormButton: React.FC<FormButtonProps> = ({ children, variant = 'primary', ...props }) => (
    <button
        {...props}
        className={`w-full flex justify-center items-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50 transition-all duration-200 ${variant === 'primary'
            ? 'text-white bg-teal-600 hover:bg-teal-700 hover:scale-[1.02] active:scale-[0.98]'
            : 'text-teal-700 bg-teal-100 hover:bg-teal-200'
            }`}
    >
        {children}
    </button>
);

const PricingCard: React.FC<PricingCardProps> = ({
    plan,
    isSelected,
    onSelect,
    billingFrequency
}) => {
    const price = billingFrequency === 'monthly' ? plan.monthly_price : plan.yearly_price;
    const period = billingFrequency === 'monthly' ? '/mo' : '/yr';
    const yearlySavings = billingFrequency === 'yearly'
        ? ((plan.monthly_price * 12) - plan.yearly_price).toFixed(2)
        : 0;

    return (
        <div
            className={`relative overflow-hidden rounded-lg border ${isSelected
                ? 'border-gray-300 bg-white shadow-md'
                : 'border-gray-200 bg-white opacity-75 hover:opacity-100'
                } p-4 transition-all duration-200`}
        >
            {plan.name === "Premium" && (
                <div className="absolute top-2 right-2">
                    <span className="inline-flex items-center rounded-full bg-teal-100 px-2 py-0.5 text-xs font-medium text-teal-800">
                        Popular
                    </span>
                </div>
            )}

            <div className="flex justify-between items-start mb-2">
                <h3 className="text-lg font-semibold text-gray-900">
                    {plan.name}
                </h3>
                <div className="text-right">
                    <span className="text-xl font-bold text-gray-900">${price}</span>
                    <span className="text-sm text-gray-600">{period}</span>
                </div>
            </div>

            {billingFrequency === 'yearly' && Number(yearlySavings) > 0 && (
                <div className="text-sm text-green-600 font-medium mb-2">
                    Save ${yearlySavings}/year
                </div>
            )}

            <div className="space-y-2">
                <div className="flex items-center text-sm">
                    <Check className="w-4 h-4 text-teal-600 mr-2" />
                    <span className="text-gray-700">Up to {plan.max_vehicles} vehicles</span>
                </div>
                {plan.features.slice(0, 2).map((feature, index) => (
                    <div key={index} className="flex items-center text-sm">
                        <Check className="w-4 h-4 text-teal-600 mr-2" />
                        <span className="text-gray-700">{feature}</span>
                    </div>
                ))}
            </div>

            <div className="mt-4">
                <FormButton
                    onClick={() => onSelect(plan.id)}
                    variant={isSelected ? "primary" : "secondary"}
                >
                    {isSelected ? "Selected" : "Select Plan"}
                </FormButton>
            </div>
        </div>
    );
};

const LifetimeOffer: React.FC<LifetimeOfferProps> = ({ isSelected, onSelect, plan, enabled }) => {
    if (!enabled || !plan) return null;

    const originalPrice = plan.monthly_price * 12 * 2; // Example: 2 years worth as original price
    const lifetimeCost = plan.monthly_price;
    const savings = (originalPrice - lifetimeCost).toFixed(2);
    const savingsPercentage = Math.round((parseFloat(savings) / originalPrice) * 100);

    return (
        <div
            className={`relative overflow-hidden rounded-xl border-2 ${isSelected ? 'border-teal-400 shadow-lg shadow-teal-100' : 'border-teal-200'
                } bg-gradient-to-br from-teal-50 to-white p-6`}
        >
            <div className="absolute -right-12 top-8 rotate-45 bg-gradient-to-r from-orange-500 to-red-500 px-14 py-1.5 text-sm font-medium text-white shadow-sm">
                Limited Time
            </div>

            <div className="flex justify-between items-start mb-4">
                <div>
                    <h3 className="text-xl font-bold text-gray-900 flex items-center gap-2">
                        Lifetime Access
                        <Trophy className="w-5 h-5 text-yellow-500" />
                    </h3>
                    <p className="text-sm text-gray-600 mt-1">Join our founding members</p>
                </div>
                <div className="text-right">
                    <div className="text-xs text-gray-500 line-through">${originalPrice}</div>
                    <div className="text-2xl font-bold text-gray-900">${lifetimeCost}</div>
                    <div className="text-sm text-teal-600 font-medium">
                        Save ${savings} ({savingsPercentage}% off)
                    </div>
                </div>
            </div>

            <div className="space-y-3 mb-6">
                <div className="flex items-start gap-3 bg-white p-3 rounded-lg shadow-sm">
                    <Zap className="w-5 h-5 text-yellow-500 flex-shrink-0 mt-0.5" />
                    <div>
                        <div className="font-medium">Early Adopter Advantages</div>
                        <div className="text-sm text-gray-600">All future premium features included forever</div>
                    </div>
                </div>
                <div className="flex items-start gap-3 bg-white p-3 rounded-lg shadow-sm">
                    <Trophy className="w-5 h-5 text-purple-500 flex-shrink-0 mt-0.5" />
                    <div>
                        <div className="font-medium">Exclusive Benefits</div>
                        <div className="text-sm text-gray-600">Founder's badge & priority support</div>
                    </div>
                </div>
                <div className="flex items-start gap-3 bg-white p-3 rounded-lg shadow-sm">
                    <Clock className="w-5 h-5 text-teal-500 flex-shrink-0 mt-0.5" />
                    <div>
                        <div className="font-medium">Limited Time Offer</div>
                        <div className="text-sm text-gray-600">Price increases as we grow</div>
                    </div>
                </div>
            </div>

            <FormButton
                onClick={onSelect}
                variant={isSelected ? "primary" : "primary"}
                className={`group ${isSelected ? 'bg-teal-700' : 'bg-teal-600'}`}
            >
                {isSelected ? "Selected - Continue to Payment →" : "Get Lifetime Access"}
            </FormButton>

            <div className="mt-4 flex items-center justify-center text-sm text-gray-600 gap-2">
                <Shield className="w-4 h-4 text-teal-600" />
                <span>60-day money-back guarantee</span>
            </div>
        </div>
    );
};

export const PlanSelectionStep: React.FC<{
    sessionId: string;
    selectedPlanId: string;
    selectedBillingFrequency: BillingFrequency;
    onNext: () => void;
    onBack: () => void;
}> = ({
    sessionId,
    selectedPlanId,
    selectedBillingFrequency,
    onNext,
    onBack,
}) => {
        const [localPlanId, setLocalPlanId] = useState(selectedPlanId);
        const [localBillingFrequency, setLocalBillingFrequency] = useState(selectedBillingFrequency);

        const dispatch = useDispatch();
        const { plans } = useSelector((state: RootState) => state.plans);
        const { lifetimePlan, lifetimeEnabled, loading } = useSelector((state: RootState) => state.plans);

        useEffect(() => {
            dispatch(fetchLifetimePlanRequest());
        }, [dispatch]);

        const handlePlanSelect = (planId: string) => {
            setLocalPlanId(planId);

            dispatch(updateRegistrationSessionRequest({
                sessionId: sessionId,
                planId: planId,
                billingFrequency: localBillingFrequency,
                step: 'plan_selection',
            }));
        };

        const handleLifetimeSelect = () => {
            dispatch(updateRegistrationSessionRequest({
                sessionId: sessionId,
                planId: LIFETIME_PLAN_ID,
                billingFrequency: localBillingFrequency,
                step: 'plan_selection',
            }));
        };

        if (loading) {
            return <div className="text-center">Loading plans...</div>;
        }

        return (
            <div className="space-y-6 max-w-3xl mx-auto">
                {lifetimeEnabled && lifetimePlan && (
                    <>
                        <div className="text-center mb-8">
                            <h2 className="text-2xl font-bold text-gray-900 mb-2">
                                🚀 Exclusive Launch Offer
                            </h2>
                            <p className="text-gray-600">
                                Join our founding members and lock in the best price forever
                            </p>
                        </div>
                        <LifetimeOffer
                            isSelected={localPlanId === LIFETIME_PLAN_ID}
                            onSelect={handleLifetimeSelect}
                            plan={lifetimePlan}
                            enabled={lifetimeEnabled}
                        />
                    </>
                )}

                <div className="relative">
                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-200" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500">Or choose a subscription</span>
                    </div>
                </div>

                <div className="flex justify-center mb-4">
                    <div className="inline-flex rounded-lg border border-gray-200 p-1 bg-white">
                        <button
                            onClick={() => setLocalBillingFrequency(BillingFrequency.Monthly)}
                            className={`px-4 py-1.5 text-sm font-medium rounded-md transition-all duration-200 ${localBillingFrequency === 'monthly'
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-500 hover:text-gray-700'
                                }`}
                        >
                            Monthly
                        </button>
                        <button
                            onClick={() => setLocalBillingFrequency(BillingFrequency.Yearly)}
                            className={`px-4 py-1.5 text-sm font-medium rounded-md transition-all duration-200 ${localBillingFrequency === 'yearly'
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-500 hover:text-gray-700'
                                }`}
                        >
                            Yearly
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-1 gap-4">
                    {plans?.map((plan) => (
                        <PricingCard
                            key={plan.id}
                            plan={plan}
                            isSelected={localPlanId === plan.id}
                            onSelect={handlePlanSelect}
                            billingFrequency={localBillingFrequency}
                        />
                    ))}
                </div>

                <div className="flex space-x-4 mt-8">
                    <FormButton onClick={onBack} variant="secondary">
                        Back
                    </FormButton>
                    <FormButton
                        onClick={onNext}
                        disabled={!localPlanId}
                        className="group"
                    >
                        Continue to Payment
                        <ArrowRight className="ml-2 w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                    </FormButton>
                </div>
            </div>
        );
    };

export default PlanSelectionStep;

import React from 'react';
import EVAlarmLayout from "../layout/EVAlarmLayout";
import { Link } from 'react-router-dom';

interface SectionProps {
    title: string;
    children: React.ReactNode;
    number: number;
    id: string;
}

interface SectionContent {
    title: string;
    content: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, children, number, id }) => (
    <div id={id} className="scroll-mt-24">
        <section className="mb-12 bg-white rounded-2xl p-8 shadow-lg hover:shadow-xl transition-all duration-300">
            <h2 className="text-2xl font-semibold mb-6 text-gray-900 flex items-center">
                <span className="flex items-center justify-center w-8 h-8 bg-teal-600 text-white rounded-full text-sm mr-4">
                    {number}
                </span>
                {title}
            </h2>
            <div className="prose prose-teal max-w-none">
                {children}
            </div>
        </section>
    </div>
);
const TermsOfService = () => {
    const sections: SectionContent[] = [
        {
            title: "Definitions",
            content: (
                <>
                    <p className="mb-4">Throughout these Terms of Service, the following terms shall have the meanings defined below:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>"Application" or "EVAlarm" refers to our mobile application and related services</li>
                        <li>"Service" means any features, functions, tools, or content provided through the Application</li>
                        <li>"User," "you," or "your" refers to any person who accesses or uses the Application</li>
                        <li>"We," "us," or "our" refers to EVAlarm and its parent company, subsidiaries, or affiliates</li>
                        <li>"App Store" refers to digital distribution platforms such as the Apple App Store or Google Play Store</li>
                        <li>"Device" means any compatible mobile device that you own or control</li>
                    </ul>
                </>
            )
        },
        {
            title: "Acceptance of Terms",
            content: (
                <>
                    <p className="mb-4">By accessing or using the EVAlarm application and services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.</p>
                    <p className="mb-4">The EVAlarm application is licensed, not sold, to you. Your license to use the application is subject to your prior acceptance of these Terms of Service and your compliance with all applicable laws and regulations.</p>
                    <p>You represent and warrant that you are of legal age to form a binding contract with EVAlarm and are not prohibited from receiving services under the laws of any applicable jurisdiction.</p>
                </>
            )
        },
        {
            title: "Description of Service",
            content: (
                <>
                    <p className="mb-4">EVAlarm provides a mobile application designed to alert Tesla owners about their charging status and help prevent overstays at charging stations.</p>
                    <h3 className="font-semibold mb-2">Technical Requirements:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Minimum iOS version: iOS 16.0 or later</li>
                        <li>Device compatibility: iPhone.</li>
                        <li>Network requirements: Active internet connection required</li>
                        <li>Storage space: Minimum 100MB free space required</li>
                    </ul>
                    <p>Your license grants you the right to use the EVAlarm application on any compatible devices that you own or control, subject to the applicable platform's usage rules.</p>
                </>
            )
        },
        {
            title: "User Responsibilities and Restrictions",
            content: (
                <>
                    <p className="mb-4">Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account. Users agree to use the service in compliance with all applicable laws and regulations.</p>

                    <p className="font-semibold mb-2">Usage Restrictions:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>You may not modify, reverse engineer, decompile, or attempt to derive the source code of the application</li>
                        <li>You may not transfer, redistribute, or sublicense the application</li>
                        <li>If you sell your device, you must remove the application before doing so</li>
                        <li>You may not use the service for any illegal or unauthorized purpose</li>
                        <li>You may not attempt to circumvent any technological measures designed to control access to the service</li>
                        <li>You may not use automated systems or software to extract data from the service</li>
                    </ul>
                </>
            )
        },
        {
            title: "Subscription and Payment Terms",
            content: (
                <>
                    <h3 className="text-xl font-semibold mb-3">App Store Purchases and Subscriptions</h3>
                    <p className="mb-4">For purchases and subscriptions made through the Apple App Store:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Payment will be charged to your Apple ID account at confirmation of purchase</li>
                        <li>Subscriptions automatically renew unless auto-renew is turned off at least 24-hours before the end of the current period</li>
                        <li>Your account will be charged for renewal within 24-hours prior to the end of the current period</li>
                        <li>You can manage and cancel your subscriptions by going to your App Store account settings after purchase</li>
                        <li>Any unused portion of a free trial period, if offered, will be forfeited when you purchase a subscription</li>
                        <li>Subscription prices may change. You will be notified of any price changes and must agree to new prices before they take effect</li>
                        <li>Family Sharing, if enabled through your App Store account, applies to in-app purchases and subscriptions</li>
                    </ul>

                    <h3 className="text-xl font-semibold mb-3">In-App Purchases</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>All in-app purchases are final and non-refundable, except as required by law</li>
                        <li>Purchases can be restored across devices using the "Restore Purchases" feature</li>
                        <li>In-app purchases are subject to the terms and conditions of your App Store provider</li>
                    </ul>

                    <h3 className="text-xl font-semibold mb-3">Lifetime Deals</h3>
                    <p className="mb-2">EVAlarm offers lifetime deals at different price tiers, providing unlimited access to our services for the lifetime of the product:</p>

                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Lifetime access is granted for the lifetime of the EVAlarm product/service</li>
                        <li>Pricing tiers are offered at $299, $399, $599, and $999, with features specific to each tier</li>
                        <li>Lifetime deals are non-transferable and cannot be resold</li>
                        <li>Each lifetime deal is limited to use on one account</li>
                        <li>Lifetime access applies to current and future features within the purchased tier</li>
                        <li>EVAlarm reserves the right to modify or discontinue specific features while maintaining core functionality</li>
                    </ul>
                </>
            )
        },
        {
            title: "Privacy and Data Collection",
            content: (
                <>
                    <p className="mb-4">Your use of EVAlarm is governed by our Privacy Policy, which can be found <Link to="/privacy" className="text-teal-600 hover:underline">here</Link>.</p>

                    <h3 className="font-semibold mb-2">Data Collection and Usage:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>We collect technical data about your device, system, and application software</li>
                        <li>We request your permission before tracking your activity across other companies' apps and websites (App Tracking Transparency)</li>
                        <li>Location data is collected only when necessary for core app functionality</li>
                        <li>Usage data is collected to improve our services and user experience</li>
                    </ul>

                    <h3 className="font-semibold mb-2">Your Privacy Rights:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>You have the right to request deletion of your personal data</li>
                        <li>You can opt-out of data collection for analytics purposes</li>
                        <li>You can request a copy of your personal data</li>
                        <li>You can modify your privacy settings within the app</li>
                    </ul>

                    <p className="mb-4">For detailed information about our data collection practices, please refer to our Privacy Policy and Apple's Privacy Nutrition Label in the App Store.</p>
                </>
            )
        },
        {
            title: "Account Management",
            content: (
                <>
                    <h3 className="font-semibold mb-2">Account Creation and Security:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>You must provide accurate and complete information when creating an account</li>
                        <li>You are responsible for maintaining the security of your account credentials</li>
                        <li>You must notify us immediately of any unauthorized access to your account</li>
                    </ul>

                    <h3 className="font-semibold mb-2">Account Deletion:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>You can request account deletion through the app settings or by contacting support</li>
                        <li>Account deletion will remove all your personal data from our systems</li>
                        <li>Deletion requests will be processed within 30 days</li>
                        <li>Some information may be retained for legal compliance purposes</li>
                    </ul>

                    <h3 className="font-semibold mb-2">Data Portability:</h3>
                    <p className="mb-4">You can request a copy of your data in a structured, commonly used format by contacting our support team.</p>
                </>
            )
        },
        {
            title: "Maintenance and Support",
            content: (
                <>
                    <p className="mb-4">Maintenance and support for this application is provided only by EVAlarm, not by Apple or any other app store provider.</p>

                    <h3 className="font-semibold mb-2">Support Services:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Technical support is available through our ticketing system available on our website</li>
                        <li>Support hours: Monday to Friday, 9 AM to 5 PM EST excluding statutory holidays in the State of New Jersey</li>
                        <li>Response time: Within 24 hours for standard issues</li>
                    </ul>

                    <h3 className="font-semibold mb-2">Updates:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Regular updates will be provided through the App Store</li>
                        <li>Users are responsible for maintaining the latest version</li>
                        <li>Some features may require the latest version of the app</li>
                    </ul>
                </>
            )
        },
        {
            title: "External Services and Third-Party Integration",
            content: (
                <>
                    <p className="mb-4">The EVAlarm application may enable access to third-party services and websites. You agree to use the External Services at your sole risk.</p>

                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Data displayed by External Services is for informational purposes only</li>
                        <li>External Services may not be available in all locations</li>
                        <li>We may modify or disable access to External Services at any time</li>
                        <li>Third-party terms and privacy policies may apply</li>
                    </ul>
                </>
            )
        },
        {
            title: "Disclaimer of Warranties",
            content: (
                <>
                    <p className="mb-4">The application is provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to:</p>

                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Warranties of merchantability</li>
                        <li>Fitness for a particular purpose</li>
                        <li>Non-infringement of third party rights</li>
                    </ul>

                    <p className="mb-4">In the event of any failure of the application to conform to any applicable warranty, you may notify Apple, and Apple will refund the purchase price for the application to you; and, to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to the application.</p>
                </>
            )
        },
        {
            title: "Limitations of Liability",
            content: (
                <>
                    <p className="mb-4">To the fullest extent permitted by law, EVAlarm and its affiliates shall not be liable for:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Any indirect, incidental, or consequential damages</li>
                        <li>Loss of profits, data, or use</li>
                        <li>Business interruption</li>
                        <li>Personal injury or property damage</li>
                    </ul>
                    <p>Total liability shall not exceed the amount you paid for the application in the last six months, or $100, whichever is greater.</p>
                </>
            )
        },
        {
            title: "Third-Party Beneficiary",
            content: (
                <>
                    <p className="mb-4">For users who access our application through the Apple App Store:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>These terms are between you and EVAlarm, not with Apple</li>
                        <li>Apple has no responsibility for the application and its content</li>
                        <li>Apple, and Apple's subsidiaries, are third-party beneficiaries of these terms</li>
                        <li>Upon acceptance, Apple has the right to enforce these terms against you as a third-party beneficiary</li>
                        <li>You represent that you are not in a country under U.S. embargo or designated as "terrorist supporting"</li>
                    </ul>
                </>
            )
        },
        {
            title: "Changes to Terms",
            content: (
                <>
                    <p className="mb-4">We reserve the right to modify these Terms of Service at any time. We will notify users of any material changes via:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Email notification to the address associated with your account</li>
                        <li>In-app notification</li>
                        <li>Notice on our website</li>
                    </ul>
                    <p>Your continued use of the service after such modifications constitutes acceptance of the updated terms.</p>
                </>
            )
        },
        {
            title: "Termination",
            content: (
                <>
                    <p className="mb-4">We may terminate or suspend your access to our service:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Immediately upon notice for violation of these terms</li>
                        <li>Without notice for illegal activities</li>
                        <li>Upon reasonable notice for service discontinuation</li>
                    </ul><p>Upon termination:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>All rights and licenses granted to you will terminate</li>
                        <li>You must cease all use of the application</li>
                        <li>No refunds will be provided unless required by law</li>
                    </ul>
                </>
            )
        },
        {
            title: "Export Control",
            content: (
                <>
                    <p className="mb-4">You may not use or export the application except as authorized by United States law and the laws of the jurisdiction in which the application was obtained.</p>

                    <p className="mb-4">You represent and warrant that:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>You are not located in a country subject to a U.S. Government embargo</li>
                        <li>You are not listed on any U.S. Government list of prohibited or restricted parties</li>
                        <li>You will not export or re-export the application to prohibited countries or individuals</li>
                        <li>You will comply with all applicable export control laws</li>
                    </ul>
                </>
            )
        },
        {
            title: "Governing Law and Jurisdiction",
            content: (
                <>
                    <p className="mb-4">These Terms shall be governed by and construed in accordance with the laws of the United States and the State of New Jersey, without regard to its conflict of law provisions.</p>

                    <h3 className="font-semibold mb-2">Dispute Resolution:</h3>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Any dispute arising from these terms will be resolved through binding arbitration</li>
                        <li>Arbitration will be conducted in New Jersey</li>
                        <li>Each party will bear its own costs of arbitration</li>
                        <li>Small claims court actions are exempt from mandatory arbitration</li>
                    </ul>
                </>
            )
        },
        {
            title: "Severability and Waiver",
            content: (
                <>
                    <p className="mb-4">If any provision of these Terms is found to be unenforceable or invalid:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>That provision will be limited or eliminated to the minimum extent necessary</li>
                        <li>The remaining provisions will remain in full force and effect</li>
                        <li>No waiver of any term shall be deemed a further or continuing waiver</li>
                    </ul>
                </>
            )
        },
        {
            title: "Force Majeure",
            content: (
                <>
                    <p className="mb-4">EVAlarm shall not be liable for any failure or delay in performance due to circumstances beyond our reasonable control, including but not limited to:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>Acts of God</li>
                        <li>War or terrorist actions</li>
                        <li>Pandemic or epidemic</li>
                        <li>Government actions</li>
                        <li>Network or power failures</li>
                        <li>Third-party service provider failures</li>
                    </ul>
                </>
            )
        },
        {
            title: "Entire Agreement",
            content: (
                <>
                    <p className="mb-4">These Terms constitute the entire agreement between you and EVAlarm regarding the use of the application, superseding any prior agreements between you and EVAlarm.</p>

                    <p className="mb-4">This agreement includes:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>These Terms of Service</li>
                        <li>Our Privacy Policy</li>
                        <li>Any additional terms specific to particular services or features</li>
                        <li>Any amendments or modifications agreed to in writing</li>
                    </ul>
                </>
            )
        },
        {
            title: "Contact Information",
            content: (
                <>
                    <p className="mb-4">For questions about these Terms of Service or any other legal matters:</p>
                    <ul className="list-disc pl-8 mt-2 mb-4">
                        <li>General questions: new-ticket@inbound.evalarm.com</li>
                    </ul>

                    <p className="mb-4">Mailing address:</p>
                    <p>EVAlarm Inc.<br />
                        100 Barron Circle<br />
                        Unit 4171<br />
                        Somerset, NJ 08873<br />
                        United States</p>
                </>
            )
        }
    ];

    const scrollToSection = (sectionId: string): void => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <EVAlarmLayout>
            <div className="min-h-screen bg-gradient-to-br from-teal-50/50 via-cyan-50/30 to-white">
                <div className="max-w-4xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
                    {/* Header */}
                    <div className="text-center mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            EVAlarm Terms of Service
                        </h1>
                        <p className="text-gray-600 italic">Last updated: October 28, 2024</p>
                    </div>

                    {/* Table of Contents */}
                    <div className="bg-white rounded-2xl p-8 shadow-lg mb-12">
                        <h2 className="text-2xl font-semibold mb-6 text-gray-900">Table of Contents</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {sections.map((section, index) => (
                                <button
                                    key={index}
                                    onClick={() => scrollToSection(`section-${index + 1}`)}
                                    className="text-left flex items-center group text-gray-700 hover:text-teal-600 transition-colors"
                                >
                                    <span className="flex items-center justify-center w-6 h-6 bg-teal-100 text-teal-600 rounded-full text-sm mr-3 group-hover:bg-teal-600 group-hover:text-white transition-colors">
                                        {index + 1}
                                    </span>
                                    {section.title}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Sections */}
                    {sections.map((section, index) => (
                        <Section
                            key={index}
                            title={section.title}
                            number={index + 1}
                            id={`section-${index + 1}`}
                        >
                            {section.content}
                        </Section>
                    ))}

                    {/* Version History */}
                    <div className="mt-12 p-6 bg-gray-50 rounded-xl">
                        <p className="text-sm text-gray-600 italic">
                            Version History:<br />
                            - October 28, 2024: Major revision to ensure app store compliance<br />
                            - Previous versions available upon request
                        </p>
                    </div>
                </div>
            </div>
        </EVAlarmLayout>
    );
};

export default TermsOfService;

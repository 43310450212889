import {format, formatDistanceToNow, isValid} from "date-fns";

export const formatDate = (timeInSeconds: number | null): string => {
    if (!timeInSeconds) return 'N/A';
    const parsedDate = new Date(timeInSeconds * 1000);  // Convert seconds to milliseconds
    if (!isValid(parsedDate)) {
        console.error('Invalid date:', timeInSeconds);
        return 'Invalid Date';
    }
    try {
        return format(parsedDate, 'MMMM dd, yyyy');
    } catch (error) {
        console.error('Error formatting date:', error);
        return 'Error';
    }
}


export const formatDateInWords = (timestamp: number): string => {
    const date = new Date(timestamp * 1000);

    const distance = formatDistanceToNow(date, {addSuffix: true});
    const fullDate = format(date, 'PPpp');

    return `${distance} (${fullDate})`
}

export function randomStr(length: number): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
}


// src/knowledge_base/categories/account.tsx
import { Link } from 'react-router-dom';
import { ArticleCategory } from "../knowledge_base_types";

export const accountArticles = [
  {
    id: "create-account",
    title: 'How to Create an Account',
    content: (
      <>
        <p>You can create your EVAlarm account either through our app or on our web platform:</p>
        <ol>
          <li>Choose your preferred platform (app or web)</li>
          <li>Enter your email address</li>
          <li>Choose between creating a password or verifying with a 6-digit code</li>
          <li>Select a subscription plan</li>
          <li>Complete the payment for your initial subscription</li>
        </ol>
        <p><strong>Note:</strong> We offer a 90-day money-back guarantee with no questions asked if you're not satisfied with our service.</p>
        <p>For more details on our pricing plans, please visit our <Link to="/plans">Plans Page</Link>.</p>
      </>
    ),
    category: ArticleCategory.AccountAndSignUp,
  },
  {
    id: "account-deletion",
    title: 'How to Delete Your Account',
    content: (
      <>
        <h2>How to Delete Your Account</h2>
        <p>To delete your account, please follow these steps:</p>
        <ol>
          <li>First, cancel your subscription (if active) using the instructions in our <Link to="/help/article/change-cancel-subscription">Changing or Cancelling Your Subscription</Link> guide.</li>
          <li>Contact our support team by either:
            <ul>
              <li>Creating a ticket in our ticket creation system, or</li>
              <li>Emailing us at <a href="mailto:new-ticket@inbound.evalarm.app">new-ticket@inbound.evalarm.app</a></li>
            </ul>
          </li>
          <li>Request account deletion in your message</li>
        </ol>
        <p><strong>Note:</strong> Due to restrictions by Apple, we cannot cancel subscriptions on behalf of iOS users. Please ensure you've cancelled your subscription before requesting account deletion.</p>
      </>
    ),
    category: ArticleCategory.AccountAndSignUp,
  },
];

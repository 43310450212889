import { accountArticles } from './categories/account';
import { appArticles } from './categories/app';
import { billingArticles } from './categories/billing';
import { privacyArticles } from './categories/privacy';
import { contactArticles } from './categories/contact';
import { KnowledgeBase } from './knowledge_base_types';

const allArticles = [
    ...accountArticles,
    ...appArticles,
    ...billingArticles,
    ...privacyArticles,
    ...contactArticles,
];

// Export an instance of the KnowledgeBase
export const knowledgeBase = new KnowledgeBase(allArticles);

import { AppEpic, getAuthHeaders, parseHttpError } from "./index";
import { combineEpics, ofType } from "redux-observable";
import {removeAccount, RemoveAccountPayload, setError, setLoading} from "../teslaAccountsSlice";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import {PayloadAction} from "@reduxjs/toolkit";

const removeTeslaAccountEpic: AppEpic = (action$) => action$.pipe(
    ofType(removeAccount.type),
    switchMap((action: PayloadAction<RemoveAccountPayload>) => {
        const accountId = action.payload.id;
        const url = `/api/tesla_accounts/${accountId}`;

        return from(fetch(url, {
            method: 'DELETE',
            headers: getAuthHeaders(),
        })).pipe(
            parseHttpError(),
            mergeMap(() => [
                setLoading(false),
            ]),
            catchError((error) => of(
                setError(error.message),
                setLoading(false)
            ))
        );
    })
);

const teslaAccountEpics = combineEpics(
    removeTeslaAccountEpic,
    // Add other Tesla account related epics here
);

export default teslaAccountEpics;
import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import HelpLayout from '../layout/HelpLayout';
import UserTicketsList from "./Ticketing/UserTicketList";
import CreateTicket from "./Ticketing/CreateTicket";
import TicketDetails from "./Ticketing/TicketDetails";
import TicketConfirmation from "./Ticketing/TicketConfirmation";

const TicketingPage: React.FC = () => {
    const isAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);
    const location = useLocation();

    return (
        <HelpLayout title="Support Tickets" subtitle="Get help with your issues">
            <Routes>
                <Route index element={
                    isAuthenticated ? <UserTicketsList /> : <Navigate to="new" replace state={{ from: location }} />
                } />
                <Route path="new" element={<CreateTicket />} />
                <Route path="confirmation/:id" element={<TicketConfirmation />} />
                <Route path=":id" element={
                    isAuthenticated ? <TicketDetails /> : <Navigate to="/signin" replace state={{ from: location }} />
                } />
            </Routes>
        </HelpLayout>
    );
};

export default TicketingPage;
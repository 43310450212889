import { combineReducers, configureStore, PayloadAction } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistConfig } from 'redux-persist/es/types';
import userReducer from './userSlice';
import teslaAccountsReducer from './teslaAccountsSlice';
import vehicleDataReducer from './vehicleDataSlice';
import devicesReducer from './devicesSlice';
import subscriptionReducer from './subscriptionSlice';
import plansReducer from './plansSlice';
import settingsReducer from './settingSlice';
import registrationReducer from './registrationSlice'
import helpdeskReducer from "./helpdeskSlice";
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';

// Combine the reducers
const rootReducer = combineReducers({
    user: userReducer,
    registration: registrationReducer,
    teslaAccounts: teslaAccountsReducer,
    vehicleData: vehicleDataReducer,
    devices: devicesReducer,
    subscription: subscriptionReducer,
    plans: plansReducer,
    settings: settingsReducer,
    helpdesk: helpdeskReducer,
    // Add other reducers here as needed
});

// Define the RootState type based on the combined reducer
export type RootState = ReturnType<typeof rootReducer>;

// Persistence configuration
const persistConfig: PersistConfig<RootState> = {
    key: 'root',
    storage,
    whitelist: ['registration'], // Only persist the registration state
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Epic middleware
const epicMiddleware = createEpicMiddleware<
    PayloadAction<unknown>, // Input action type
    PayloadAction<unknown>, // Output action type
    RootState // State type
>();

// Create a function to create the store
export const createStore = () => {
    const store = configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                },
            }).concat(epicMiddleware),
    });

    // Run the root epic after the store is created
    epicMiddleware.run(rootEpic);

    return store;
};

// Infer the `AppStore` and `AppDispatch` types from the store itself
export type AppStore = ReturnType<typeof createStore>;
export type AppDispatch = AppStore['dispatch'];

// Define the store and export it
const store = createStore();
export const persistor = persistStore(store);
export default store;
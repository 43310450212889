import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Bell, Clock } from 'lucide-react';
import DashboardCard from '../../common/DashboardCard';
import {setAlertTimeStart} from '../../../store/vehicleDataSlice';
import { RootState } from "../../../store"; // Adjust the import path as needed

interface QuickAlertSettingProps {
    vehicleId: string;
}

const QuickAlertSetting: React.FC<QuickAlertSettingProps> = ({ vehicleId }) => {
    const dispatch = useDispatch();
    const alertTime = useSelector((state: RootState) =>
        state.vehicleData.vehicles.find(v => v.id === vehicleId)?.alertTime ?? 10
    );

    const [sliderValue, setSliderValue] = useState(alertTime);

    useEffect(() => {
        setSliderValue(alertTime);
    }, [alertTime]);

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value);
        setSliderValue(newValue);
    };

    const handleSliderAfterChange = () => {
        dispatch(setAlertTimeStart({ vehicleId, alertTime: sliderValue }));
    };

    return (
        <DashboardCard title="Charge Completion Alert">
            <div className="flex items-center mb-4">
                <Clock className="text-teal-500 mr-2" size={24} />
                <span className="text-lg">Alert me before completion:</span>
            </div>
            <div className="mb-4">
                <input
                    type="range"
                    min="0"
                    max="30"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    onMouseUp={handleSliderAfterChange}
                    onTouchEnd={handleSliderAfterChange}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                />
                <div className="flex justify-between text-xs text-gray-600 mt-1">
                    <span>0 min</span>
                    <span>15 min</span>
                    <span>30 min</span>
                </div>
            </div>
            <div className="text-center text-lg font-semibold mb-4">
                {sliderValue} {sliderValue === 1 ? 'minute' : 'minutes'}
            </div>
            <div className={`mt-4 ${sliderValue === 0 ? 'bg-yellow-100 text-yellow-700' : 'bg-blue-100 text-blue-700'} p-3 rounded-lg flex items-center`}>
                <Bell className="mr-2" size={18} />
                {sliderValue === 0 ? (
                    <span>Warning: Alert may go off after charging is completed.</span>
                ) : (
                    <span>You'll be alerted {sliderValue} {sliderValue === 1 ? 'minute' : 'minutes'} before charging completes</span>
                )}
            </div>
        </DashboardCard>
    );
};

export default QuickAlertSetting;
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { cancelSubscriptionStart, SubscriptionState, resetCancellationStatus } from '../../../store/subscriptionSlice';
import { X, Info } from 'lucide-react';

interface CancellationComponentProps {
    isOpen: boolean;
    onClose: () => void;
}

export const CancellationComponent: React.FC<CancellationComponentProps> = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();
    const [feedback, setFeedback] = useState('');
    const [requestRefund, setRequestRefund] = useState(false);
    const { loading, error, canRefund, cancellationStatus } = useSelector<RootState, SubscriptionState>((state) => state.subscription);

    useEffect(() => {
        if (cancellationStatus === 'success') {
            onClose();
            dispatch(resetCancellationStatus());
        }
    }, [cancellationStatus, onClose, dispatch]);

    const handleCancel = () => {
        dispatch(cancelSubscriptionStart({
            refund: canRefund && requestRefund,
            feedback: feedback,
        }));
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg max-w-md w-full">
                <h2 className="text-xl font-bold mb-4">Subscription Changes</h2>
                <div className="mb-4 p-3 bg-blue-100 text-blue-800 rounded-md flex items-start">
                    <Info className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5" />
                    <p className="text-sm">
                        {canRefund
                            ? "You can choose to request a refund, which will immediately cancel your subscription, or simply turn off auto-renewal to keep access until the end of the billing period."
                            : "Turning off auto-renewal will maintain your access to premium features until the end of the current billing period."}
                    </p>
                </div>
                <div className="mb-4">
                    <label htmlFor="feedback" className="block text-sm font-medium text-gray-700 mb-1">
                        Help us improve (Optional)
                    </label>
                    <textarea
                        id="feedback"
                        placeholder="We value your feedback. What made you decide to make this change?"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md"
                        rows={3}
                    />
                </div>
                {canRefund && (
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={requestRefund}
                                onChange={(e) => setRequestRefund(e.target.checked)}
                                className="mr-2"
                            />
                            <span className="text-sm text-gray-700">Request a refund and cancel immediately</span>
                        </label>
                    </div>
                )}
                {error && (
                    <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md flex items-start">
                        <X className="w-5 h-5 mr-2 flex-shrink-0 mt-0.5" />
                        <p className="text-sm">{error}</p>
                    </div>
                )}
                <div className="flex justify-end space-x-2">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300"
                    >
                        Keep My Subscription
                    </button>
                    <button
                        onClick={handleCancel}
                        disabled={loading}
                        className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 disabled:opacity-50"
                    >
                        {loading ? 'Processing...' : (canRefund && requestRefund) ? 'Cancel and Refund' : 'Turn Off Auto-Renewal'}
                    </button>
                </div>
            </div>
        </div>
    );
};
import React from 'react';
import { DollarSign, Clock, Battery, TrendingUp, Building, Car, Zap, Check } from 'lucide-react';
import { motion } from 'framer-motion';

const StatsCard = ({
  icon,
  value,
  label,
  description,
  gradient,
  source
}: {
  icon: React.ReactNode;
  value: string;
  label: string;
  description: string;
  gradient: string;
  source?: string;
}) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className="group relative"
  >
    <div className="bg-white/80 backdrop-blur-lg p-8 rounded-2xl shadow-sm hover:shadow-lg transition-all duration-300 border border-teal-100/20">
      <div className="flex items-start justify-between mb-4">
        <div className={`w-12 h-12 rounded-lg bg-gradient-to-br ${gradient} flex items-center justify-center`}>
          {React.cloneElement(icon as React.ReactElement, {
            className: 'w-6 h-6 text-white'
          })}
        </div>
        <div className={`text-3xl font-bold bg-gradient-to-r ${gradient} bg-clip-text text-transparent group-hover:scale-105 transform transition-transform duration-300`}>
          {value}
        </div>
      </div>
      <h3 className="text-lg font-semibold text-gray-900 mb-2">{label}</h3>
      <p className="text-gray-600 text-sm leading-relaxed">{description}</p>
      {source && (
        <div className="mt-3 text-xs text-gray-500">
          Source: {source}
        </div>
      )}
    </div>
  </motion.div>
);

const CommonScenarios = () => (
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-16">
    <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-6 border border-teal-100/20">
      <h3 className="flex items-center text-lg font-semibold text-gray-900 mb-4">
        <Building className="w-5 h-5 mr-2 text-teal-600" />
        Apartment Charging
      </h3>
      <ul className="space-y-3">
        {[
          "Typical overstay fees of $15-20/hour",
          "Limited chargers means high demand",
          "Often requires overnight charging",
          "Building management enforces strict policies"
        ].map((item, index) => (
          <li key={index} className="flex items-start">
            <div className="flex-shrink-0 w-5 h-5 rounded-full bg-teal-100 flex items-center justify-center mt-0.5 mr-3">
              <Check className="w-3 h-3 text-teal-600" />
            </div>
            <span className="text-gray-600">{item}</span>
          </li>
        ))}
      </ul>
    </div>

    <div className="bg-white/80 backdrop-blur-lg rounded-2xl p-6 border border-teal-100/20">
      <h3 className="flex items-center text-lg font-semibold text-gray-900 mb-4">
        <Car className="w-5 h-5 mr-2 text-teal-600" />
        Public Level 2 Charging
      </h3>
      <ul className="space-y-3">
        {[
          "Shopping centers charge $20+/hour after grace period",
          "Workplace charging often has 4-hour limits",
          "Popular locations have waiting lists",
          "Fees can start immediately after charging completes"
        ].map((item, index) => (
          <li key={index} className="flex items-start">
            <div className="flex-shrink-0 w-5 h-5 rounded-full bg-teal-100 flex items-center justify-center mt-0.5 mr-3">
              <Check className="w-3 h-3 text-teal-600" />
            </div>
            <span className="text-gray-600">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const StatsSection = () => (
  <section className="relative py-20">
    <div className="absolute inset-0 bg-gradient-to-br from-teal-50 via-white to-cyan-50" />
    <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_50%,rgba(20,184,166,0.1),transparent)]" />

    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
      <div className="text-center mb-16">
        <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
          The Cost of <span className="text-teal-600">Charging Overstay</span>
        </h2>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          Level 2 charging stations and apartment chargers can have significant overstay penalties
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <StatsCard
          icon={<DollarSign />}
          value="$15-20"
          label="Hourly Overstay Fee"
          description="Typical penalty rate at apartment and public Level 2 charging stations."
          gradient="from-red-500 to-orange-500"
        />
        <StatsCard
          icon={<Clock />}
          value="6-8 hrs"
          label="Average Charge Time"
          description="Typical duration for a full charge on Level 2 charging. Easy to forget during work or overnight."
          gradient="from-teal-500 to-cyan-500"
        />
        <StatsCard
          icon={<Zap />}
          value="~30 min"
          label="Grace Period"
          description="Common window to move your car after charging completes before fees begin."
          gradient="from-amber-500 to-orange-500"
        />
        <StatsCard
          icon={<TrendingUp />}
          value="$1,200+"
          label="Potential Annual Cost"
          description="What just two 2-hour overstays per month could cost you annually."
          gradient="from-purple-500 to-pink-500"
        />
      </div>

      <CommonScenarios />

      <div className="mt-16 max-w-3xl mx-auto bg-white/80 backdrop-blur-lg rounded-2xl p-6 border border-teal-100/20">
        <div className="flex items-start space-x-4">
          <div className="flex-shrink-0 w-12 h-12 rounded-lg bg-yellow-100 flex items-center justify-center">
            <Battery className="w-6 h-6 text-yellow-700" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              Why EVAlarm?
            </h3>
            <p className="text-gray-600">
              Level 2 charging sessions are long, and it's easy to forget about them during work, shopping, or overnight charging.
              Many charging apps only send a single notification that's easy to miss. EVAlarm ensures you never forget with persistent
              notifications until you disconnect, potentially saving you hundreds in overstay fees.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default StatsSection;

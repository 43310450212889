import React from 'react';
import { AlertCircle } from 'lucide-react';

interface ErrorAlertProps {
    message: string;
}

export const ErrorAlert: React.FC<ErrorAlertProps> = ({ message }) => {
    if (!message) return null;

    return (
        <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-8 rounded-lg" role="alert">
            <div className="flex items-center">
                <AlertCircle className="w-6 h-6 mr-2 flex-shrink-0" />
                <p className="font-medium">{message}</p>
            </div>
        </div>
    );
};
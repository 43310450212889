import React, { useEffect } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { User, Car, CreditCard, ArrowLeft, Smartphone } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import {setActiveTab, SettingTab} from "../../store/settingSlice";
import {RootState} from "../../store";

const Settings: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activeTab = useSelector((state: RootState) => state.settings.activeTab);

    const sections = [
        { id: SettingTab.Account, title: 'Account Settings', icon: User, path: '/settings/account' },
        { id: SettingTab.Device, title: 'Device Management', icon: Smartphone, path: '/settings/devices' },
        { id: SettingTab.TeslaAccount, title: 'Tesla Accounts', icon: Car, path: '/settings/tesla' },
        { id: SettingTab.Plan, title: 'Plans & Payments', icon: CreditCard, path: '/settings/plans' },
    ];

    useEffect(() => {
        // Set the active tab based on the current path when the component mounts
        const currentSection = sections.find(section => section.path === location.pathname);
        if (currentSection) {
            dispatch(setActiveTab(currentSection.id));
        } else {
            // If no matching path, default to Account tab
            dispatch(setActiveTab(SettingTab.Account));
            navigate('/settings/account');
        }
    }, [location.pathname, dispatch, navigate]);

    const handleTabClick = (tab: SettingTab, path: string) => {
        dispatch(setActiveTab(tab));
        navigate(path);
    };

    return (
        <div className="bg-gray-100 min-h-screen">
            <div className="container mx-auto px-4 py-8">
                <div className="bg-white rounded-lg shadow-md">
                    <div className="flex items-center justify-between p-6 border-b">
                        <h1 className="text-2xl font-bold text-gray-800">Settings</h1>
                        <Link to="/dashboard" className="flex items-center text-teal-600 hover:text-teal-800">
                            <ArrowLeft className="mr-2" size={20} />
                            Back to Dashboard
                        </Link>
                    </div>
                    <div className="flex flex-col md:flex-row">
                        <nav className="w-full md:w-1/4 p-6 border-r">
                            {sections.map((section) => (
                                <button
                                    key={section.id}
                                    onClick={() => handleTabClick(section.id, section.path)}
                                    className={`flex items-center w-full p-3 mb-2 rounded-lg transition-colors ${
                                        activeTab === section.id
                                            ? 'bg-teal-100 text-teal-800'
                                            : 'text-gray-600 hover:bg-gray-100'
                                    }`}
                                >
                                    <section.icon className="mr-3" size={20} />
                                    <span>{section.title}</span>
                                </button>
                            ))}
                        </nav>
                        <div className="w-full md:w-3/4 p-6">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
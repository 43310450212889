
import { combineEpics, Epic } from 'redux-observable';
import { setAlertTimeEpic } from './setAlertTimeEpic';
import userEpics from './userEpics';
import settingsEpic from './settingsEpic';
import deviceEpics from './deviceEpics';
import subscriptionEpics from './subscriptionEpics';
import helpdeskEpic from "./helpdeskEpic";
import { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { registrationEpics } from "./registrationEpics";
import { Observable, OperatorFunction } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { TokenData } from "../userSlice";
import teslaAccountEpics from "./teslaAccountEpic";
import plansEpics from './planEpics';
// Import other epic modules as needed

export type AppEpic = Epic<PayloadAction<any>, PayloadAction<any>, RootState>;

export const camelToSnakeCase = (obj: Record<string, any>): Record<string, any> => {
    return Object.keys(obj).reduce((acc, key) => {
        const newKey = key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
        acc[newKey] = typeof obj[key] === 'object' && obj[key] !== null
            ? camelToSnakeCase(obj[key])
            : obj[key];
        return acc;
    }, {} as Record<string, unknown>);
};


// Function to get CSRF token from meta tag
export const getCSRFToken = (): string | null => {
    const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    return token || null;
};

// Function to get tokens from localStorage
export const getTokens = (): TokenData | null => {
    const tokensString = localStorage.getItem('tokens');
    return tokensString ? JSON.parse(tokensString) : null;
};


export const getAuthHeaders = (): HeadersInit => {
    const tokens = getTokens();
    const csrfToken = getCSRFToken()!;
    return {
        ...(tokens ? { 'Authorization': `Bearer ${tokens.access_token}` } : {}),
        'X-CSRF-Token': csrfToken,
    };
};


export function parseHttpError<T>(): OperatorFunction<Response, T> {
    return (source: Observable<Response>): Observable<T> =>
        source.pipe(
            mergeMap(async (response: Response) => {
                if (!response.ok) {
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.includes("application/json")) {
                        const errorData = await response.json();
                        throw new Error(errorData.error_message || 'An unknown error occurred');
                    }
                    throw new Error('An unknown error occurred');
                }
                return response.json() as Promise<T>;
            })
        );
}

export const rootEpic = combineEpics(
    setAlertTimeEpic,
    deviceEpics,
    userEpics,
    settingsEpic,
    subscriptionEpics,
    helpdeskEpic,
    registrationEpics,
    teslaAccountEpics,
    plansEpics
);

import { Link } from 'react-router-dom';
import { ArticleCategory } from "../knowledge_base_types";

export const billingArticles = [
  {
    id: "pricing-plans",
    title: 'Understanding our Pricing Plans',
    content: (
      <>
        <p>For detailed information about our pricing plans and features, please visit our <Link to="/plans">Plans Page</Link>.</p>
      </>
    ),
    category: ArticleCategory.BillingAndPlans,
  },
  {
    id: "change-cancel-subscription",
    title: 'Changing or Cancelling Your Subscription',
    content: (
      <>
        <h2>Changing or Cancelling Your Subscription</h2>
        <h3>Web Platform:</h3>
        <ol>
          <li>Go to the Settings section</li>
          <li>Navigate to Plans & Payments</li>
          <li>Choose to upgrade, downgrade, or end your plan</li>
        </ol>
        <p><strong>Note:</strong> If you downgrade, you'll remain on your current tier until it ends. If you cancel, you'll have access to our service until the end of the billing period.</p>

        <h3>iOS App:</h3>
        <ol>
          <li>Open the EVAlarm iOS app</li>
          <li>Go to the Settings tab</li>
          <li>Select Manage Subscription</li>
        </ol>
        <p>You can also manage your subscription through the App Store app.</p>
        <p><strong>Important:</strong> Due to Apple's restrictions, subscription changes for iOS users must be made through our iOS app or the App Store.</p>
      </>
    ),
    category: ArticleCategory.BillingAndPlans,
  },
  {
    id: "refund-policy",
    title: 'Our Refund Policy',
    content: (
      <>
        <h2>EVAlarm Refund Policy</h2>
        <p>We want you to be completely satisfied with our service. Our refund policy varies depending on where you made your purchase:</p>

        <h3>Subscriptions Purchased on Our Website</h3>
        <ul>
          <li>We offer a 60-day, no-questions-asked refund policy for subscriptions purchased directly through our website.</li>
          <li>To request a refund, simply send a refund request to our support team within 60 days of your purchase.</li>
          <li>You can initiate this process by <Link to="/help/ticket">creating a support ticket</Link> or emailing us at <a href="mailto:new-ticket@inbound.evalarm.app">new-ticket@inbound.evalarm.app</a>.</li>
        </ul>

        <h3>Subscriptions Purchased through the App Store</h3>
        <ul>
          <li>For purchases made through the Apple App Store, refunds are handled directly by Apple.</li>
          <li>Due to App Store policies, we are unable to process these refunds ourselves.</li>
          <li>To request a refund for an App Store purchase, please follow Apple's refund process:</li>
          <ol>
            <li>Visit <a href="https://reportaproblem.apple.com/">reportaproblem.apple.com</a></li>
            <li>Sign in with your Apple ID</li>
            <li>Select the subscription you wish to refund</li>
            <li>Follow the prompts to request your refund</li>
          </ol>
          <li>Please note that Apple's refund policy may differ from our website policy, and we have no control over their decisions regarding refunds.</li>
        </ul>

        <p>If you have any questions about our refund policy, please don't hesitate to <Link to="/help/ticket">contact our support team</Link>.</p>
      </>
    ),
    category: ArticleCategory.BillingAndPlans,
  },
];

import React, { useState, useEffect } from 'react';
import { Battery, Bell, Clock, Home, Settings, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

interface Notification {
    id: number;
    message: string;
    urgent: boolean;
}

const AppPreview: React.FC = () => {
    const [batteryLevel, setBatteryLevel] = useState<number>(65);
    const [timeToFull, setTimeToFull] = useState<number>(17);
    const [alertTime, setAlertTime] = useState<number>(10);
    const [isCharging, setIsCharging] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<Notification[]>([]);

    const targetCharge = 80;

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
        if (isCharging && batteryLevel < targetCharge) {
            timer = setInterval(() => {
                setBatteryLevel(prev => {
                    const newLevel = Math.min(prev + 1, targetCharge);
                    if (newLevel === targetCharge) {
                        setIsCharging(false);
                        addNotification(`Your Tesla is fully charged at ${newLevel}%. Please move your car to avoid overstay fees.`, true);
                    } else if (targetCharge - newLevel === alertTime) {
                        addNotification(`Your Tesla is at ${newLevel}%. It will reach ${targetCharge}% in about ${alertTime} minutes.`);
                    }
                    return newLevel;
                });
                setTimeToFull(prev => Math.max(prev - 1, 0));
            }, 1000);
        }
        return () => {
            if (timer) clearInterval(timer);
        };
    }, [isCharging, batteryLevel, alertTime]);

    const addNotification = (message: string, urgent: boolean = false) => {
        setNotifications(prev => [...prev, { id: Date.now(), message, urgent }]);
    };

    const removeNotification = (id: number) => {
        setNotifications(prev => prev.filter(notif => notif.id !== id));
    };

    return (
        <div className="max-w-sm mx-auto bg-white rounded-3xl shadow-xl overflow-hidden relative">
            <div className="px-4 py-5 sm:p-6">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-3xl font-bold text-gray-900">EVAlarm</h2>
                    <div className="text-blue-500">
                        <Bell size={24} />
                    </div>
                </div>

                <div className="bg-gray-100 rounded-xl p-4 mb-6">
                    <h3 className="text-xl font-semibold mb-2">Tesla Model S</h3>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <Battery className={isCharging ? "text-green-500 mr-2" : "text-gray-500 mr-2"} size={24} />
                            <span className="text-2xl font-bold">{batteryLevel}%</span>
                        </div>
                        <span className="text-gray-600">{(batteryLevel * 4).toFixed(1)} miles</span>
                    </div>
                </div>

                <div className="bg-gray-100 rounded-xl p-4 mb-6">
                    <h3 className="text-lg font-semibold mb-2">Charging Status</h3>
                    <div className="flex items-center text-green-500 mb-2">
                        <Battery className="mr-2" size={20} />
                        <span>{isCharging ? "Charging" : "Charge Complete"}</span>
                    </div>
                    {isCharging && (
                        <div className="flex items-center text-gray-600">
                            <Clock className="mr-2" size={20} />
                            <span>Time to {targetCharge}%: {timeToFull} minutes</span>
                        </div>
                    )}
                </div>

                <div className="bg-gray-100 rounded-xl p-4 mb-6">
                    <h3 className="text-lg font-semibold mb-2">Charge Completion Alert</h3>
                    <div className="flex items-center text-gray-600 mb-2">
                        <Bell className="mr-2" size={20} />
                        <span>Alert me before completion:</span>
                    </div>
                    <input
                        type="range"
                        min={0}
                        max={30}
                        value={alertTime}
                        onChange={(e) => setAlertTime(Number(e.target.value))}
                        className="w-full"
                    />
                    <div className="flex justify-between text-sm text-gray-500">
                        <span>0 min</span>
                        <span>15 min</span>
                        <span>30 min</span>
                    </div>
                    <p className="text-center text-lg font-semibold mt-2">{alertTime} minutes</p>
                    <p className="text-center text-sm text-blue-500">
                        You'll be alerted {alertTime} minutes before charging completes
                    </p>
                </div>

                <div className="flex justify-around text-gray-500">
                    <div className="flex flex-col items-center">
                        <Home size={24} className="text-blue-500" />
                        <span className="text-xs mt-1">Home</span>
                    </div>
                    <div className="flex flex-col items-center">
                        <Bell size={24} />
                        <span className="text-xs mt-1">Notifications</span>
                    </div>
                    <div className="flex flex-col items-center">
                        <Settings size={24} />
                        <span className="text-xs mt-1">Settings</span>
                    </div>
                </div>
            </div>

            <div className="absolute top-4 right-4 z-50 flex flex-col items-end space-y-2 max-w-[90%]">
                <AnimatePresence>
                    {notifications.map((notif) => (
                        <motion.div
                            key={notif.id}
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: 50 }}
                            className={`${
                                notif.urgent ? 'bg-red-600' : 'bg-blue-600'
                            } text-white px-4 py-2 rounded-lg shadow-lg flex items-center justify-between w-full`}
                        >
                            <div className="flex items-center">
                                <Bell className="mr-2" size={18} />
                                <p className="text-sm">{notif.message}</p>
                            </div>
                            <button
                                onClick={() => removeNotification(notif.id)}
                                className="text-white hover:text-gray-300 ml-2"
                            >
                                <X size={14} />
                            </button>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default AppPreview;
import { combineEpics, ofType } from "redux-observable";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { from, of } from "rxjs";
import { AppEpic, getAuthHeaders } from "./index";
import { fetchLifetimePlanFailure, fetchLifetimePlanRequest, fetchLifetimePlanSuccess, LifetimePlanResponse } from "../plansSlice";

/**
 * Epic to handle fetching lifetime plan information
 * @param action$ - Stream of actions dispatched to Redux
 * @returns Observable - Streams of actions or results
 */
export const fetchLifetimePlanEpic: AppEpic = (action$) => action$.pipe(
  ofType(fetchLifetimePlanRequest.type),
  switchMap(() =>
    from(fetch('/api/plans/lifetime_plan', {
      method: 'GET',
      headers: getAuthHeaders(),
    })).pipe(
      mergeMap(response =>
        response.ok
          ? from(response.json())
          : Promise.reject(new Error(`HTTP error! status: ${response.status}`))
      ),
      map((data: LifetimePlanResponse) => fetchLifetimePlanSuccess(data)),
      catchError(error => of(fetchLifetimePlanFailure(`Failed to fetch lifetime plan: ${error.message}`)))
    )
  )
);

/**
 * Combines all plans-related epics into one exportable epic
 */
const plansEpics = combineEpics(
  fetchLifetimePlanEpic
);

export default plansEpics;

import { combineEpics, ofType } from 'redux-observable';
import { catchError, mergeMap, switchMap, filter, map } from 'rxjs/operators';
import { from, of, timer } from 'rxjs';
import {
    signInRequest,
    signInSuccess,
    signInFailure,
    signOutRequest,
    signOutSuccess,
    refreshTokenRequest,
    refreshTokenSuccess,
    refreshTokenFailure,
    User,
    TokenData,
    initializeApp,
    initializeAppSuccess,
    initializeAppFailure,
    setTokens,
    otpRequest,
    otpRequestSuccess,
    otpRequestFailure, clearUserData,
} from "../userSlice";
import {setVehicles, VehicleData} from "../vehicleDataSlice";
import {setAccounts, TeslaAccount} from "../teslaAccountsSlice";
import {AppEpic, parseHttpError} from "./index";
import { PayloadAction } from "@reduxjs/toolkit";

export const getTokenData = (): TokenData | null => {
    const tokensString = localStorage.getItem('tokens');
    if (typeof tokensString === "string") {
        const parsedTokens: TokenData = JSON.parse(tokensString);
        return parsedTokens;
    } else {
        return null;
    }
}

const getAuthHeaders = (tokens?: TokenData): HeadersInit => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
    return {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
        ...(tokens ? { 'Authorization': `Bearer ${tokens.access_token}` } : {})
    };
};

const initializeAppEpic: AppEpic = (action$) => action$.pipe(
    ofType(initializeApp.type),
    mergeMap(() => {
        const tokensString = localStorage.getItem('tokens');
        if (tokensString && tokensString !== 'undefined') {
            let parsedTokens: TokenData;
            try {
                parsedTokens = JSON.parse(tokensString);
            } catch (error) {
                console.error('Error parsing tokens from localStorage:', error);
                localStorage.removeItem('tokens');
                return of(initializeAppFailure('Invalid tokens'), clearUserData());
            }

            const actionsToDispatch: PayloadAction<TokenData|null|undefined>[] = [setTokens(parsedTokens)];

            if (isTokenExpired(parsedTokens)) {
                actionsToDispatch.push(refreshTokenRequest());
                return of(...actionsToDispatch);
            } else {
                return from(fetch('/api/users', { headers: getAuthHeaders(parsedTokens) })).pipe(
                    mergeMap((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response.json();
                    }),
                    mergeMap((response) => [
                        ...actionsToDispatch,
                        signInSuccess({ ...response.user, tokens: parsedTokens }),
                        setVehicles(response.summary_vehicles),
                        setAccounts(response.summary_tesla_accounts),
                        initializeAppSuccess()
                    ]),
                    catchError((error) => {
                        console.error('App initialization failed:', error);
                        localStorage.removeItem('tokens');
                        return of(
                            initializeAppFailure(error.message),
                            signInFailure(error.message),
                            clearUserData(),
                            signOutSuccess()
                        );
                    })
                );
            }
        }
        return of(initializeAppSuccess());
    })
);




const signInEpic: AppEpic = (action$) =>
    action$.pipe(
        ofType(signInRequest.type),
        switchMap((action: PayloadAction<{ email: string; password: string; authType: 'otp' | 'password' } | undefined>) => {
            if (!action.payload) {
                return of(signInFailure('Invalid sign-in data'));
            }

            const { email, password, authType } = action.payload;

            const body = authType === 'otp'
                ? JSON.stringify({ email, otp: password })
                : JSON.stringify({ email, password });

            return from(
                fetch('/api/sessions', {
                    method: 'POST',
                    headers: getAuthHeaders(),
                    body: body,
                })
            ).pipe(
                parseHttpError<TokenData>(),
                mergeMap((tokenData: TokenData) => {
                    // Store tokens in localStorage
                    localStorage.setItem('tokens', JSON.stringify(tokenData));

                    // Fetch user data with the new token
                    return from(fetch('/api/users', { headers: getAuthHeaders(tokenData) })).pipe(
                        parseHttpError<{ user: User; summary_vehicles: VehicleData[]; summary_tesla_accounts: TeslaAccount[] }>(),
                        map((userData) => ({
                            user: { ...userData.user, tokens: tokenData },
                            vehicles: userData.summary_vehicles,
                            accounts: userData.summary_tesla_accounts
                        }))
                    );
                }),
                mergeMap(({ user, vehicles, accounts }) => [
                    signInSuccess(user),
                    setVehicles(vehicles),
                    setAccounts(accounts),
                    setTokens(user.tokens)
                ]),
                catchError((error) => {
                    console.error('Sign-in error:', error);
                    return of(signInFailure(error.message || 'An error occurred during sign-in'));
                })
            );
        })
    );

const refreshTokenEpic: AppEpic = (action$, state$) => action$.pipe(
    ofType(refreshTokenRequest.type),
    filter(() => !!state$.value.user.tokens),
    switchMap(() => {
        const refreshToken = state$.value.user.tokens!.refresh_token;
        return from(fetch(`/api/sessions/${refreshToken}/refresh`, {
            method: 'POST',
            headers: getAuthHeaders()
        })).pipe(
            mergeMap((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            }),
            switchMap((tokens: TokenData) => {
                localStorage.setItem('tokens', JSON.stringify(tokens));

                return from(fetch('/api/users', { headers: getAuthHeaders(tokens) })).pipe(
                    mergeMap((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response.json();
                    }),
                    mergeMap((userData) => [
                        refreshTokenSuccess(tokens),
                        setVehicles(userData.summary_vehicles),
                        setAccounts(userData.summary_tesla_accounts)
                    ])
                );
            }),
            catchError((error) => {
                console.error('Token refresh or user data fetch failed:', error);
                localStorage.removeItem('tokens');
                return of(
                    refreshTokenFailure(error.message),
                    signInFailure(error.message),
                    clearUserData(),
                    signOutSuccess()
                );
            })
        );
    })
);


const otpRequestEpic: AppEpic = (action$) => action$.pipe(
    ofType(otpRequest.type),
    switchMap((action: PayloadAction<{ email: string }>) => {
        const { email } = action.payload;

        return from(fetch('/api/sessions/request_otp', {
            method: 'POST',
            headers: getAuthHeaders(),
            body: JSON.stringify({ email })
        })).pipe(
            mergeMap((response) => response.json()),
            map(() => otpRequestSuccess()),
            catchError((error) => of(otpRequestFailure(error.message || 'Failed to request OTP')))
        );
    })
);

const signOutEpic: AppEpic = (action$) => action$.pipe(
    ofType(signOutRequest.type),
    switchMap(() =>
        from(fetch('/api/sessions/nuid', {
            method: 'DELETE',
            headers: getAuthHeaders(),
            credentials: 'include' // This ensures cookies are included with the request
        })).pipe(
            map(() => {
                localStorage.removeItem('tokens');
                clearCookies();
                return signOutSuccess();
            }),
            catchError((error) => {
                console.error('Sign out error:', error);
                localStorage.removeItem('tokens');
                clearCookies();
                return of(signOutSuccess());
            })
        )
    )
);

const clearCookies = () => {
    console.log("clearCookies() called...")
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
};

const tokenExpirationCheckEpic: AppEpic = (action$, state$) =>
    timer(0, 60000).pipe(
        filter(() => !!state$.value.user.tokens && state$.value.user.isAuthenticated),
        mergeMap(() => {
            const tokens = state$.value.user.tokens!;
            if (isTokenExpired(tokens)) {
                return of(refreshTokenRequest());
            }
            return of();
        })
    );

// Helper function to check if the token is expired
const isTokenExpired = (tokens: TokenData): boolean => {
    const now = Math.floor(Date.now() / 1000);
    return now >= tokens.created_at + tokens.expires_in - 300; // Refresh 5 minutes before expiration
};

const userEpics = combineEpics(
    initializeAppEpic,
    signInEpic,
    signOutEpic,
    refreshTokenEpic,
    otpRequestEpic,
    tokenExpirationCheckEpic,
);

export default userEpics
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { PlansState, setBillingCycle } from '../../store/plansSlice';
import {
    updatePlanStart,
    fetchSubscriptionStart,
    estimatePriceStart,
    SubscriptionState,
    PaymentProcessor,
    reactivateSubscriptionStart,
    PriceEstimate,
    BillingFrequency,
    UpdateableBillingFrequency
} from '../../store/subscriptionSlice';
import { useSubscriptionForm } from "../../hooks/useSubscriptionForm";
import { CurrentSubscription } from "./Subscriptions/CurrentSubscription";
import { PlanSelection } from "./Subscriptions/PlanSelection";
import { PaymentMethodSection } from "./Subscriptions/PaymentMethodSection";
import LoadingScreen from "../pages/LoadingScreen";
import { CancellationComponent } from './Subscriptions/CancellationComponent';
import { BatteryCharging, Bell, Clock } from 'lucide-react';
import Button from '../common/Button';
import { ErrorAlert } from "../common/ErrorAlert";
import { SuccessAlert } from "./Subscriptions/SuccessAlert";

const Feature = ({ icon, title, description }) => (
    <div className="flex flex-col items-center text-center p-6 bg-white bg-opacity-10 rounded-xl transition-all duration-300 hover:bg-opacity-20 hover:transform hover:scale-105">
        <div className="mb-4">{icon}</div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-blue-100">{description}</p>
    </div>
);

const PlansAndPayments = () => {
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const paymentMethodRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();

    const { plans, billingCycle } = useSelector<RootState, PlansState>((state) => state.plans);
    const {
        currentPlan,
        hasSubscription,
        loading,
        error,
        priceEstimate,
        paymentProcessor,
        billingFrequency,
        autoRenewal
    } = useSelector<RootState, SubscriptionState>((state) => state.subscription);

    const {
        selectedPlanId,
        paymentName,
        showPaymentForm,
        handlePlanChange,
        handlePaymentNameChange,
        handleSubmit,
        handleUpdatePaymentMethod,
        setShowPaymentForm
    } = useSubscriptionForm({
        dispatch,
        hasSubscription,
        currentPlan,
        plans,
        billingCycle
    });

    useEffect(() => {
        dispatch(fetchSubscriptionStart());
    }, [dispatch]);

    useEffect(() => {
        if (selectedPlanId && selectedPlanId !== plans.find(plan => plan.name === currentPlan)?.id) {
            dispatch(estimatePriceStart({ planId: selectedPlanId, billingFrequency: billingCycle }));
        }
    }, [selectedPlanId, billingCycle, currentPlan, plans, dispatch]);

    const handleBillingCycleChange = () => {
        const newCycle: UpdateableBillingFrequency =
            billingCycle === BillingFrequency.Monthly
                ? BillingFrequency.Yearly
                : BillingFrequency.Monthly;

        dispatch(setBillingCycle(newCycle));
    };

    const handleSubscriptionAction = () => {
        if (selectedPlanId) {
            dispatch(updatePlanStart({ planId: selectedPlanId, billingFrequency: billingCycle }));
            setSuccessMessage(hasSubscription ? 'Your plan has been updated successfully!' : 'Your subscription has been created successfully!');
            setTimeout(() => setSuccessMessage(''), 5000);
        }
    };

    const handleShowPaymentForm = () => {
        setShowPaymentForm(true);
        setTimeout(() => {
            paymentMethodRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    const handleReactivateSubscription = () => {
        dispatch(reactivateSubscriptionStart());
        setSuccessMessage('Your subscription has been reactivated successfully!');
        setTimeout(() => setSuccessMessage(''), 5000);
    };

    if (loading) {
        return <LoadingScreen />;
    }

    const isAppleIAP = paymentProcessor === PaymentProcessor.AppleIAP;
    const canChangeSubscription = !isAppleIAP && autoRenewal && selectedPlanId !== currentPlan?.toLowerCase();

    const renderPriceEstimate = (priceEstimate: PriceEstimate) => (
        <div className="mt-8 p-6 bg-blue-50 rounded-xl border border-blue-200">
            <h4 className="text-xl font-semibold text-blue-800 mb-3">Price Estimate</h4>
            <p className="text-blue-700 mb-2">
                Immediate charge: ${priceEstimate.immediate_charge.toFixed(2)}
            </p>
            {priceEstimate.proration_breakdown && (
                <div className="text-sm text-blue-600 mb-2">
                    <p>Prorated new plan cost: ${priceEstimate.proration_breakdown.prorated_new_plan_cost.toFixed(2)}</p>
                    <p>Unused current plan credit: ${priceEstimate.proration_breakdown.unused_current_plan_credit.toFixed(2)}</p>
                </div>
            )}
            <p className="text-blue-700 mb-2">
                Next billing date: {new Date(priceEstimate.next_billing_date).toLocaleDateString()}
            </p>
            <p className="text-blue-700 mb-2">
                Next bill amount: ${priceEstimate.next_bill_amount.toFixed(2)}
            </p>
            <p className="text-blue-700">
                New plan: {priceEstimate.new_plan} ({priceEstimate.billing_frequency})
            </p>
        </div>
    );

    if (billingFrequency === BillingFrequency.Lifetime) {
        return (
            <div className="max-w-5xl mx-auto p-6 space-y-8">
                <header className="text-center">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">Lifetime Member</h1>
                    <p className="text-xl text-gray-600">
                        Thank you for being an early adopter of EVAlarm!
                    </p>
                </header>

                <section className="bg-white p-8 rounded-xl shadow-lg">
                    <div className="text-center">
                        <h2 className="text-2xl font-bold text-blue-800 mb-4">Your Lifetime Subscription</h2>
                        <p className="text-lg text-gray-700 mb-6">
                            You have unlimited access to all EVAlarm features forever.
                        </p>
                        <CurrentSubscription />
                    </div>
                </section>

                <section className="bg-gradient-to-r from-blue-600 to-teal-500 text-white p-8 rounded-xl shadow-lg">
                    <h2 className="text-3xl font-bold mb-6">Premium Features</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <Feature
                            icon={<BatteryCharging className="w-12 h-12" />}
                            title="Smart Charge Detection"
                            description="Automatically detects when your car is charging"
                        />
                        <Feature
                            icon={<Bell className="w-12 h-12" />}
                            title="Persistent Reminders"
                            description="Continuous alerts until acknowledged"
                        />
                        <Feature
                            icon={<Clock className="w-12 h-12" />}
                            title="Time & Cost Optimization"
                            description="Avoid overstay fees with timely reminders"
                        />
                    </div>
                </section>

                <ErrorAlert message={error || ''} />
            </div>
        );
    }

    return (
        <div className="max-w-5xl mx-auto p-6 space-y-8">
            <header className="text-center mb-12">
                <h1 className="text-4xl font-bold text-gray-900 mb-4">
                    {hasSubscription ? 'Manage Your Subscription' : 'Choose Your Plan'}
                </h1>
                <p className="text-xl text-gray-600">
                    Enhance your EV experience with EVAlarm's smart features
                </p>
            </header>

            <section className="bg-gradient-to-r from-blue-600 to-teal-500 text-white p-8 rounded-xl shadow-lg">
                <h2 className="text-3xl font-bold mb-6">Premium Features</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    <Feature
                        icon={<BatteryCharging className="w-12 h-12" />}
                        title="Smart Charge Detection"
                        description="Automatically detects when your car is charging"
                    />
                    <Feature
                        icon={<Bell className="w-12 h-12" />}
                        title="Persistent Reminders"
                        description="Continuous alerts until acknowledged"
                    />
                    <Feature
                        icon={<Clock className="w-12 h-12" />}
                        title="Time & Cost Optimization"
                        description="Avoid overstay fees with timely reminders"
                    />
                </div>
            </section>

            {hasSubscription && (
                <section className="bg-white p-8 rounded-xl shadow-lg mb-12">
                    <h2 className="text-2xl font-bold mb-6">Current Subscription</h2>
                    <CurrentSubscription />
                    <div className="mt-8 flex justify-between items-center">
                        {autoRenewal ? (
                            <Button
                                variant="outline"
                                onClick={() => setShowCancellationModal(true)}
                                disabled={isAppleIAP}
                            >
                                Cancel Subscription
                            </Button>
                        ) : (
                            <Button
                                onClick={handleReactivateSubscription}
                                disabled={isAppleIAP}
                            >
                                Reactivate Subscription
                            </Button>
                        )}
                        {canChangeSubscription && (
                            <Button onClick={handleShowPaymentForm}>
                                Update Payment Method
                            </Button>
                        )}
                    </div>
                </section>
            )}

            <section className="bg-white p-8 rounded-xl shadow-lg mb-12">
                <h2 className="text-2xl font-bold mb-6">{hasSubscription ? 'Change Plan' : 'Select a Plan'}</h2>
                <PlanSelection
                    plans={plans}
                    billingCycle={billingCycle}
                    selectedPlanId={selectedPlanId}
                    currentPlan={currentPlan}
                    onPlanChange={handlePlanChange}
                    onBillingCycleChange={handleBillingCycleChange}
                    paymentProcessor={paymentProcessor}
                    autoRenewal={autoRenewal}
                />
                {priceEstimate && canChangeSubscription && renderPriceEstimate(priceEstimate)}
            </section>

            <section ref={paymentMethodRef} className="bg-white p-8 rounded-xl shadow-lg mb-12">
                <h2 className="text-2xl font-bold mb-6">Payment Method</h2>
                <PaymentMethodSection
                    hasSubscription={hasSubscription}
                    showPaymentForm={showPaymentForm}
                    setShowPaymentForm={setShowPaymentForm}
                    paymentName={paymentName}
                    onPaymentNameChange={handlePaymentNameChange}
                    onSubmit={hasSubscription ? handleUpdatePaymentMethod : handleSubmit}
                    loading={loading}
                    selectedPlanId={selectedPlanId}
                    isAppleIAP={isAppleIAP}
                />
            </section>

            {canChangeSubscription && selectedPlanId && (
                <div className="text-center mt-12">
                    <Button
                        onClick={handleSubscriptionAction}
                        disabled={loading || (hasSubscription && selectedPlanId === plans.find(plan => plan.name === currentPlan)?.id)}
                        className="px-8 py-3 text-lg"
                    >
                        {loading ? 'Processing...' : hasSubscription ? 'Update Plan' : 'Start Subscription'}
                    </Button>
                </div>
            )}

            <CancellationComponent
                isOpen={showCancellationModal}
                onClose={() => setShowCancellationModal(false)}
            />

            <ErrorAlert message={error || ''} />
            <SuccessAlert message={successMessage} />
        </div>
    );
};

export default PlansAndPayments;

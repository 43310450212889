import React, { useState, useCallback, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import { RootState } from "../../../store";
import {setLocalStep} from "../../../store/registrationSlice";

const InputField: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = React.memo((props) => (
    <input
        {...props}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
    />
));

const FormButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { variant?: 'primary' | 'secondary' }> = React.memo(({ children, variant = 'primary', ...props }) => (
    <button
        {...props}
        className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 disabled:opacity-50 ${
            variant === 'primary'
                ? 'text-white bg-teal-600 hover:bg-teal-700'
                : 'text-teal-700 bg-teal-100 hover:bg-teal-200'
        }`}
    >
        {children}
    </button>
));

export type StepType = 'initiated' | 'personal_info' | 'plan_selection' | 'payment' | 'confirmation' | 'completed';


export const EmailPasswordStep: React.FC<{
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (password: string) => void;
    onNext: (email: string, password: string) => void;
    initialEmail: string;
    remoteStep: StepType | null;
}> = React.memo(({ email, setEmail, password, setPassword, onNext, initialEmail, remoteStep }) => {
    const dispatch = useDispatch();
    const { loading, error } = useSelector((state: RootState) => state.registration);

    const [localEmailError, setLocalEmailError] = useState<string | null>(null);
    const [localPasswordError, setLocalPasswordError] = useState<string | null>(null);

    const isUpdateMode = !(remoteStep === 'initiated' || remoteStep === 'personal_info');
    const isEmailChanged = email !== initialEmail;

    const validateEmail = useCallback(() => {
        if (!email) {
            setLocalEmailError('Email is required');
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setLocalEmailError('Please enter a valid email');
            return false;
        }
        setLocalEmailError(null);
        return true;
    }, [email]);

    const validatePassword = useCallback(() => {
        if (isUpdateMode && !isEmailChanged) {
            // Password is optional in this case
            setLocalPasswordError(null);
            return true;
        }
        if (password.length < 8) {
            setLocalPasswordError('Password must be at least 8 characters long');
            return false;
        }
        setLocalPasswordError(null);
        return true;
    }, [password, isUpdateMode, isEmailChanged]);

    const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        validateEmail();
    }, [setEmail, validateEmail]);

    const handlePasswordChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
        validatePassword();
    }, [setPassword, validatePassword]);

    const handleSubmit = useCallback((e: React.FormEvent) => {
        e.preventDefault();
        const isEmailValid = validateEmail();
        const isPasswordValid = validatePassword();

        if (isEmailValid && isPasswordValid) {
            if (isUpdateMode && !isEmailChanged && !password) {
                // Skip API request and move to the next step
                console.log("Skipping API request: Update mode, email unchanged, and password empty");
                dispatch(setLocalStep('plan_selection')); // Assuming 'plan_selection' is the next step
            } else {
                onNext(email, password);
            }
        }
    }, [validateEmail, validatePassword, onNext, email, password, isUpdateMode, isEmailChanged, dispatch]);

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                </label>
                <div className="mt-1">
                    <InputField
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </div>
                {localEmailError && <p className="mt-2 text-sm text-red-600">{localEmailError}</p>}
            </div>

            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    {isUpdateMode && !isEmailChanged ? "Password (optional)" : "Password"}
                </label>
                <div className="mt-1">
                    <InputField
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                {localPasswordError && <p className="mt-2 text-sm text-red-600">{localPasswordError}</p>}
            </div>

            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}

            <FormButton type="submit" disabled={loading}>
                Next
            </FormButton>
        </form>
    );
});

import { Link } from 'react-router-dom';
import { ArticleCategory } from "../knowledge_base_types";

export const appArticles = [
  {
    id: "first-alarm",
    title: 'Setting up Your First Alarm',
    content: (
      <>
        <p>To set up your first alarm with EVAlarm, follow these steps:</p>
        <ol>
          <li>Open the EVAlarm app on your device</li>
          <li>You'll be prompted to associate your Tesla account with our platform</li>
          <li>Click on the link to be redirected to the Tesla login page</li>
          <li>Log in to your Tesla account</li>
          <li>Grant permission for EVAlarm to access your charging data</li>
        </ol>
        <p><strong>Important:</strong> The permissions we request only allow us to collect charging data and adjust charging voltages to your preference. We cannot control any other aspects of your vehicle.</p>
        <p>For more information on supported vehicles, please check our <Link to="/help/article/supported-vehicles">Supported Vehicles</Link> guide.</p>
      </>
    ),
    category: ArticleCategory.App,
  },
  {
    id: "supported-devices",
    title: 'Supported Devices',
    content: (
      <>
        <h2>Supported Devices</h2>
        <p>EVAlarm is currently available on the following platforms:</p>
        <ul>
          <li><strong>iOS:</strong> Our app is fully supported on iOS devices running iOS 13.0 or later. This includes iPhone, iPad, and iPod touch.</li>
        </ul>
        <h3>Future Support</h3>
        <p>We're committed to expanding our platform support to reach more users. Here's our roadmap for future device support:</p>
        <ul>
          <li><strong>Android:</strong> Android support is in development and will be our next platform release. We're working hard to bring EVAlarm to Android users as soon as possible.</li>
          <li><strong>Desktop:</strong> Desktop support for Windows, macOS, and Linux is planned for a future release. This is a longer-term project and will come after our Android release.</li>
        </ul>
        <p><strong>Note:</strong> While we don't have specific release dates for Android and desktop versions, we're actively working on expanding our platform support. Please check back on this article periodically for the latest updates on new platform releases.</p>
        <p>If you have any questions about device support or would like to request support for a specific platform, please don't hesitate to <Link to="/help/ticket">contact our support team</Link>.</p>
      </>
    ),
    category: ArticleCategory.App,
  },
  {
    id: "supported-vehicles",
    title: 'Supported Vehicles',
    content: (
      <>
        <h2>Supported Vehicles</h2>
        <p>EVAlarm currently supports almost all Tesla models, with some exceptions:</p>
        <ul>
          <li>We support all current Tesla models (yes, even that fancy Cybertruck you're still waiting for)</li>
          <li>The early Tesla Roadster is not supported (if you have one of these, we'd be more interested in how you time-traveled from 2008)</li>
          <li>Some limited real-time functionality is only available for vehicles produced in 2021 and later (because they speak a fancier machine language)</li>
        </ul>
        <p>We're continuously working to expand our support for more vehicles and enhance our features. Check back regularly for updates!</p>
        <p><strong>Note:</strong> If you actually own an original Tesla Roadster, we'd be surprised (and a little jealous). Feel free to <Link to="/help/article/contact-support">contact us</Link> and show it off. We can't promise to support it, but we'd love to see pictures!</p>
      </>
    ),
    category: ArticleCategory.App,
  },
  {
    id: "how-evalarm-works",
    title: 'How EVAlarm Works - Our Unique Approach',
    content: (
      <>
        <h2>How EVAlarm Works</h2>
        <p>EVAlarm takes a unique approach to ensure you never get hit with overstay fees:</p>

        <h3>Core Features</h3>
        <ul>
          <li><strong>Persistent Notifications:</strong> Unlike Tesla's app or generic charger apps that send one-time notifications, EVAlarm keeps reminding you until you physically disconnect the charging cable.</li>
          <li><strong>Real-time Monitoring:</strong> We continuously monitor your charging status and adjust predictions based on current charging rates.</li>
          <li><strong>Native iOS Integration:</strong> Built specifically for iOS to provide reliable background operations and notifications that won't be missed.</li>
        </ul>

        <h3>Current Limitations</h3>
        <ul>
          <li><strong>Vehicle Offline Status:</strong> If your vehicle goes offline during charging, we can't monitor real-time status. In these cases:
            <ul>
              <li>You'll receive an immediate notification about limited monitoring</li>
              <li>We'll provide estimated completion times based on last known charging rate</li>
              <li>You can set manual backup reminders</li>
            </ul>
          </li>
          <li><strong>Network Dependency:</strong> Reliable internet connection is required for real-time monitoring</li>
        </ul>

        <h3>Upcoming Features</h3>
        <ul>
          <li><strong>Offline Detection:</strong> We're developing Bluetooth connectivity for monitoring even when your vehicle is offline</li>
          <li><strong>Smart Charge Rate Control:</strong> Automatically adjust charging speeds to avoid overstay fees while optimizing battery health</li>
          <li><strong>Enhanced Analytics:</strong> Detailed insights into your charging patterns and potential cost savings</li>
        </ul>
      </>
    ),
    category: ArticleCategory.App,
  },
]

import React from 'react';
import {ArrowRight, Check, Clock, Shield, Users} from "lucide-react";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import {Link} from "react-router-dom";

const LifetimeOffer: React.FC = () => (
    <div className="relative overflow-hidden rounded-3xl border-2 border-teal-200 bg-gradient-to-br from-teal-50 to-white p-8">
        {/* Add subtle animated gradient overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-teal-500/5 to-cyan-500/5 animate-gradient-x"></div>

        {/* Enhanced badge */}
        <div className="absolute -right-12 top-8 rotate-45 bg-teal-600 px-14 py-1.5 text-sm text-white shadow-lg">
            Launch Special
        </div>

        <h3 className="text-2xl font-bold text-gray-900 mb-4 flex items-center">
            Lifetime Premium Access
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800">
                Best Value
            </span>
        </h3>

        <div className="flex items-baseline mb-2">
            <span className="text-5xl font-bold text-gray-900">$299</span>
            <span className="ml-2 text-gray-600">one-time</span>
        </div>

        {/* Add value comparison */}
        <div className="mb-6">
            <span className="inline-block text-sm text-gray-500">
                <span className="line-through">$749.95</span>
                {" "}value (5-year premium plan)
            </span>
        </div>

        <div className="space-y-4 mb-8">
            {[
                "All Premium features forever",
                "Exclusive Founder's Badge",
                "Priority Support",
                "Early access to new features",
                "No price increases ever"
            ].map((feature, index) => (
                <div key={index} className="flex items-center">
                    <span className="inline-flex items-center justify-center w-5 h-5 bg-teal-100 rounded-full mr-3">
                        <Check className="w-3 h-3 text-teal-600" />
                    </span>
                    <span className="text-gray-700">{feature}</span>
                </div>
            ))}
        </div>

        {/* Enhanced guarantee box */}
        <div className="bg-white rounded-xl p-4 mb-8 border border-teal-100 shadow-sm">
            <div className="flex items-center text-gray-600 mb-2">
                <Shield className="w-5 h-5 mr-2 text-teal-600" />
                <span className="font-medium">60-Day Money-Back Guarantee</span>
            </div>
            <p className="text-sm text-gray-600">
                Try EVAlarm risk-free. Not happy? Get a full refund, no questions asked.
            </p>
        </div>

        <Link
            to="/signup"
            className="w-full bg-teal-600 text-white rounded-full py-4 px-8 font-medium hover:bg-teal-700 transition-all duration-300 flex items-center justify-center group shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
        >
            Get Lifetime Access
            <ArrowRight className="ml-2 w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
        </Link>

        <div className="mt-4 text-center">
            <div className="flex items-center justify-center space-x-2 text-sm text-gray-500">
                <span className="flex items-center">
                    <Clock className="w-4 h-4 mr-1" />
                    Limited time
                </span>
                <span>•</span>
                <span className="flex items-center">
                    <Users className="w-4 h-4 mr-1" />
                    Only 200 seats
                </span>
            </div>
        </div>
    </div>
);


interface PricingCardProps {
    name: string;
    features: string[];
    highlighted?: boolean;
    maxVehicles: number;
    maxDevices: number;
}

const PricingCard: React.FC<PricingCardProps> = ({
                                                     name,
                                                     features,
                                                     highlighted = false,
                                                     maxVehicles,
                                                     maxDevices
                                                 }) => {
    // Get billing cycle from Redux state
    const billingCycle = useSelector((state: RootState) => state.plans.billingCycle);
    const plans = useSelector((state: RootState) => state.plans.plans);

    // Find the corresponding plan data
    const planData = plans.find(plan => plan.name === name);

    if (!planData) return null;

    // Calculate price based on billing cycle
    const price = billingCycle === 'monthly' ? planData.monthly_price : planData.yearly_price;
    const period = billingCycle === 'monthly' ? 'per month' : 'per year';

    // Calculate yearly savings if on yearly plan
    const yearlySavings = billingCycle === 'yearly'
        ? ((planData.monthly_price * 12) - planData.yearly_price).toFixed(2)
        : 0;

    return (
        <div className={`relative overflow-hidden rounded-3xl border-2 ${
            highlighted
                ? 'border-teal-200 bg-gradient-to-br from-teal-50 to-white'
                : 'border-gray-200 bg-white'
        } p-8`}>
            {highlighted && (
                <div className="absolute top-4 right-4">
          <span className="inline-flex items-center rounded-full bg-teal-100 px-3 py-1 text-sm font-medium text-teal-800">
            Most Popular
          </span>
                </div>
            )}

            <h3 className="text-2xl font-bold text-gray-900 mb-4">
                {name}
            </h3>

            <div className="flex items-baseline mb-6">
        <span className="text-5xl font-bold text-gray-900">
          ${price}
        </span>
                <span className="ml-2 text-gray-600">
          {period}
        </span>
            </div>

            {billingCycle === 'yearly' && Number(yearlySavings) > 0 && (
                <div className="mb-6 inline-block rounded-full bg-green-100 px-3 py-1">
          <span className="text-sm font-medium text-green-800">
            Save ${yearlySavings} per year
          </span>
                </div>
            )}

            <div className="space-y-4 mb-8">
                <div className="flex items-center">
          <span className="inline-flex items-center justify-center w-5 h-5 bg-teal-100 rounded-full mr-3">
            <Check className="w-3 h-3 text-teal-600" />
          </span>
                    <span className="text-gray-700">Up to {maxVehicles} vehicles</span>
                </div>
                <div className="flex items-center">
          <span className="inline-flex items-center justify-center w-5 h-5 bg-teal-100 rounded-full mr-3">
            <Check className="w-3 h-3 text-teal-600" />
          </span>
                    <span className="text-gray-700">Support for {maxDevices} devices</span>
                </div>
                {features.map((feature, index) => (
                    <div key={index} className="flex items-center">
            <span className="inline-flex items-center justify-center w-5 h-5 bg-teal-100 rounded-full mr-3">
              <Check className="w-3 h-3 text-teal-600" />
            </span>
                        <span className="text-gray-700">{feature}</span>
                    </div>
                ))}
            </div>

            <Link
                to="/signup"
                className={`w-full rounded-full py-4 px-8 font-medium transition duration-300 flex items-center justify-center ${
                    highlighted
                        ? 'bg-teal-600 text-white hover:bg-teal-700'
                        : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                }`}
            >
                Get Started
            </Link>
        </div>
    );
};

// Enhanced CostComparisonSection with better marketing focus
const CostComparisonSection: React.FC = () => {
    const plans = useSelector((state: RootState) => state.plans.plans);

    return (
        <section className="py-20 md:py-32 bg-gradient-to-br from-white via-teal-50/30 to-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-16">
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6">
                        Choose Your <span className="text-teal-600">Protection Plan</span>
                    </h2>
                    <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                        Start saving on overstay fees today with our special launch offer
                    </p>
                </div>

                {/* Layout container with two rows */}
                <div className="space-y-8">
                    {/* Lifetime offer in its own row */}
                    <div className="max-w-2xl mx-auto">
                        <LifetimeOffer />
                    </div>

                    {/* Regular plans in a grid */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {plans.map((plan) => (
                            <PricingCard
                                key={plan.id}
                                name={plan.name}
                                features={plan.features}
                                maxVehicles={plan.max_vehicles}
                                maxDevices={plan.max_devices}
                                highlighted={plan.name === "Premium"}
                            />
                        ))}
                    </div>
                </div>

                <div className="mt-16 text-center">
                    <p className="text-gray-600 mb-8">
                        All plans include a 60-day money-back guarantee
                    </p>
                    <a href="/plans" className="text-teal-600 hover:text-teal-700 font-medium">
                        View full comparison →
                    </a>
                </div>
            </div>
        </section>
    );
};

export default CostComparisonSection;

import React from 'react';
import { Zap } from 'lucide-react';

const LoadingScreen: React.FC = () => {
    return (
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-teal-50 flex flex-col items-center justify-center">
            <div className="animate-pulse">
                <Zap className="w-24 h-24 text-teal-600 mb-8" />
            </div>
            <h1 className="text-4xl font-bold text-gray-800 mb-4">EVAlarm</h1>
            <p className="text-xl text-teal-600 mb-8">Loading your smart charging experience...</p>
            <div className="w-64 h-2 bg-teal-200 rounded-full">
                <div className="w-full h-full bg-teal-600 rounded-full animate-loading"></div>
            </div>
        </div>
    );
};

export default LoadingScreen;